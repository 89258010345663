import { create } from "apisauce";
import cache from "../utility/cache"; 
//import Constants from "expo-constants";


//const { manifest } = Constants;

//const uri = `http://${manifest.debuggerHost.split(':').shift()}:8084/api/v1`;

const apiClient = create({
    baseURL: "https://pixster-server-olsn46r5ua-uc.a.run.app/api/v1",
    responseType: "arraybuffer",
});


const get = apiClient.get;
apiClient.get = async (url, params, axiosConfig) => {
    const response = await get(url, params, axiosConfig);

    if (response.ok) {
        cache.store(url, response.data);
        return response;
    }

    const data = cache.get(url);
    return data ? { ok: true, data } : response;
}

export default apiClient;