import React from 'react';
import { Modal, Pressable, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import AppButton from '../AppButton';


const ConfirmationModal = ({ modalVisible, buttonHandler, outsidePressHandler, type, headerText, message, buttonText, loading }) => {
  return (
    <Modal animationType="slide" visible={modalVisible} transparent={false}>
      <Pressable style={styles.container}>
        <View style={styles.modal}>
          <MaterialCommunityIcons
            name={type === 'refundConfirmation' ? 'cash-refund' : 'account-clock'}
            size={100}
            color={colors.white}
          />
          <AppText style={{ fontSize: 25, color: colors.white, marginVertical: 10, textAlign: 'center' }}>{headerText}</AppText>
          <AppText style={{fontSize: 15, color: colors.white, marginBottom: 20, textAlign: 'justify' }}>{message}</AppText>
          <View style={styles.buttonsContainer}>
            <View>
                <AppButton onPress={outsidePressHandler} title={"Cancelar"} color="secondary" disabled={loading}/>
            </View>
            <View>
                <AppButton onPress={buttonHandler} title={buttonText} color="danger" disabled={loading} loading={loading}/>
            </View>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};

export default ConfirmationModal;