import React from 'react';
import { useFormikContext } from 'formik';

import AppTextInput from '../../AppTextInput';
import ErrorMessage from '../ErrorMessage';

function AppFormField({ name, setState, value, selectionFromStart = false, editable = true, button=null, half=false, dropdown=null, ...otherProps }) {
    const { setFieldTouched, setFieldValue, errors, touched } = useFormikContext();
    return (
        <>
            <AppTextInput
                onBlur={() => setFieldTouched(name)}
                onChangeText={(text) => {setFieldValue(name, text); setState && setState(text)}}
                editable={editable}
                selectionFromStart={selectionFromStart}
                value={value}
                button={button}
                half={half}
                dropdown={dropdown}
                {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormField;