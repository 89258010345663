import styles from './styles';
import LottieView  from 'react-native-web-lottie';
//import LottieView from 'lottie-react-native';


function CanceledChargeIndicator() {
    return <LottieView
        autoPlay={true}
        loop={false}
        source={require('../../../assets/animations/transaction-canceled.json')}
        style={styles.container}
    />
}

export default CanceledChargeIndicator;