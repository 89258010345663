import React, { useEffect, useState } from 'react';
import { Modal, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import AppTextInput from '../AppTextInput';
import AppButton from '../AppButton';
import interApi from '../../api/inter';
import MessageModal from '../MessageModal';
import routes from '../../navigation/routes';
import { useNavigation } from '@react-navigation/native';
import ConfirmationModal from '../ConfirmationModal';
import Screen from '../Screen';
import { CPF_MASK, CNPJ_MASK, validateCpf, validateCnpj } from '../../utility/utils';



const RefundModal = ({ txid, referenceId, modalVisible, payoutMethod, outsidePressHandler }) => {

  const [mask, setMask] = useState(CPF_MASK);
  const [payerId, setPayerId] = useState(null);
  const [refundReason, setRefundReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [refundModalVisible, setRefundModalVisible] = useState(false);

  const navigation = useNavigation();

  useEffect(() => {
    setRefundModalVisible(modalVisible);
  }, [modalVisible]);


  const [refreshPayments, setRefreshPayments] = useState(false);


  // Confirmation Modal
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [confirmationModalMessageType, setConfirmationModalMessageType] = useState('');
  const [confirmationModalHeaderText, setConfirmationModalHeaderText] = useState('');
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [confirmationModalButtonText, setConfirmationModalButtonText] = useState('');

  const showConfirmationModal = (type, headerText, message, buttonText) => {
    setConfirmationModalMessageType(type);
    setConfirmationModalHeaderText(headerText);
    setConfirmationModalMessage(message);
    setConfirmationModalButtonText(buttonText);
    setConfirmationModalVisible(true);
  };

  const handleConfirmationShow = async () => {
    outsidePressHandler();
    showConfirmationModal('refundConfirmation', `¿Confirmas la devolución del pago de ID ${referenceId}?`, 'Esta acción es irreversible. Descontaremos el valor en pesos de las siguientes acreditaciones.', 'Confirmar')
  }

  const confirmationModalButtonHandler = async () => {
    setLoading(true);
    const result = await interApi.createRefund(txid, payerId.replace(/\D/g,''), refundReason);

    if (!result.ok) {
        setLoading(false);
        setRefundModalVisible(false);
        setPayerId(null);
        setRefundReason('');
        setConfirmationModalVisible(false);
        return showMessageModal('failed', '¡Error!', result.data ? "CPF/CNPJ inválido." : "Ocurrió un error inesperado.", 'Cerrar');
    }
    setLoading(false);
    setRefundModalVisible(false);
    setPayerId(null);
    setRefundReason('');
    setConfirmationModalVisible(false);
    return showMessageModal('success', '¡Pago devuelto!', 'El pago ha sido devuelto al cliente en BRL. Descontaremos el valor de las siguientes acreditaciones.', 'Ok');
  }


  // Message modal
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [messageModalMessageType, setMessageModalMessageType] = useState('');
  const [messageModalHeaderText, setMessageModalHeaderText] = useState('');
  const [messageModalMessage, setMessageModalMessage] = useState('');
  const [messageModalButtonText, setMessageModalButtonText] = useState('');


  const showMessageModal = (type, headerText, message, buttonText) => {
    setMessageModalMessageType(type);
    setMessageModalHeaderText(headerText);
    setMessageModalMessage(message);
    setMessageModalButtonText(buttonText);
    setMessageModalVisible(true);
  };

  const messageModalButtonHandler = async () => {
    setRefreshPayments(!refreshPayments);
    navigation.navigate(routes.PAYMENTS_TAB, {isToggleInitiallyEnabled: (payoutMethod === "crypto" ? true : false), refresh: !refreshPayments});
    setMessageModalVisible(false);
  }



  return (
    <>
    <Modal animationType="slide" visible={refundModalVisible} transparent={false}>
      <Screen style={styles.container}>
        <View style={styles.modal}>
          <MaterialCommunityIcons
            name={'cash-refund'}
            size={100}
            color={colors.white}
          />
          <AppText style={{ fontSize: 25, color: colors.white, marginVertical: 10, textAlign: 'center' }}>Devolución Instantánea</AppText>
          <AppText style={{fontSize: 15, color: colors.white, marginBottom: 20, textAlign: 'justify' }}>Para efectuar la devolución, por favor solicite el CPF/CNPJ del consumidor:</AppText>
          <AppTextInput
            masked={true}
            mask={mask}
            maxLength={18}
            autoCorrect={false}
            icon="identifier"
            name="cpf_cnpj"
            placeholder="CPF/CNPJ"
            placeholderTextColor={colors.darkGrey}
            keyboardType="numeric"
            editable={true}
            onChangeText={(text) => {
                if (text.length > 14) {
                    setMask(CNPJ_MASK);
                } else {
                    setMask(CPF_MASK);
                }
                setPayerId(text);
            }}
            value={payerId}
          />
          <AppTextInput
            maxLength={280}
            autoCorrect={false}
            icon="comment-question"
            editable={true}
            name="refund_reason"
            placeholder="Motivo del Reembolso"
            placeholderTextColor={colors.darkGrey}
            onChangeText={(text) => {
                setRefundReason(text);
            }}
            value={refundReason}
          />
          <View style={styles.buttonsContainer}>
            <View>
              <AppButton onPress={() => {
                outsidePressHandler();
                setPayerId(null);
                setRefundReason('');
               }} title={"Cancelar"} color="secondary" />
            </View>
            <View>
              <AppButton title="Devolver" color="danger" disabled={!payerId || loading} onPress={handleConfirmationShow}/>
            </View>
          </View>
        </View>
      </Screen>
    </Modal>
    <MessageModal
        modalVisible={messageModalVisible}
        buttonHandler={messageModalButtonHandler}
        outsidePressHandler={messageModalButtonHandler}
        type={messageModalMessageType}
        headerText={messageModalHeaderText}
        message={messageModalMessage}
        buttonText={messageModalButtonText}
    />
    <ConfirmationModal
        modalVisible={confirmationModalVisible}
        buttonHandler={confirmationModalButtonHandler}
        outsidePressHandler={() => {
            setPayerId(null);
            setRefundReason('');
            setConfirmationModalVisible(false);
        }}
        type={confirmationModalMessageType}
        headerText={confirmationModalHeaderText}
        message={confirmationModalMessage}
        buttonText={confirmationModalButtonText}
        loading={loading}
    />
    </>
  );
};

export default RefundModal;