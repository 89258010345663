import { StyleSheet } from 'react-native';

import colors from '../../../config/colors';

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        flexDirection: "row",
        padding: 15,
        backgroundColor: colors.white,
    },
    detailsContainer: {
        marginLeft: 10,
        flex: 0.7,
        justifyContent: "center",
        backgroundColor: colors.white
    },
    image: {
        width: 70,
        height: 70,
        borderRadius: 35,
    },
    statusContainer: {
        borderRadius: 5,
        marginLeft: 10,
        flex: 0.3,
        justifyContent: "center",
    },
    status: {
        fontSize: 10,
        fontWeight: "900",
        textAlign: "center",
        padding: 5,
        color: colors.white,
    },
    title: {
        fontWeight: "500",
    },
    description: {
        fontSize: 12.5,
        color: colors.medium,
    }
})

export default styles;