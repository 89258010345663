import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../config/colors';

const screenHeight = Dimensions.get('screen').height;
const screenWidth = Dimensions.get('screen').width;

const styles = StyleSheet.create({
    container: {
        padding: 10,
    },
    codeFieldContainer: {
        marginBottom: 30
    },
    title: {
        fontSize: 30,
        fontWeight: "bold",
        paddingHorizontal: 20,
        textAlign: "center",
        alignSelf:"center",
    },
    subtitle: {
        fontSize: 20,
        textAlign: "center",
        alignSelf:"center",
        marginBottom: screenHeight / 25,
    },
    request: {
        fontSize: 14,
        textAlign: "center",
        alignSelf: "center",
    },
    statusContainer: {
        marginVertical: 20,
        paddingHorizontal: 30,
        borderRadius: 5,
        marginLeft: 10,
        justifyContent: "center",
        alignSelf: "center"
    },
    status: {
        fontSize: 13,
        fontWeight: "900",
        textAlign: "center",
        padding: 5,
        color: colors.white,
    },
    cell: {
        width: 40,
        height: 40,
        lineHeight: 38,
        borderWidth: 2,
        borderColor: '#00000030',
        textAlign: 'center',
        margin: screenWidth * 0.05,
    },
    codeFieldRoot: {
        marginHorizontal: 20,
    },
    focusCell: {
        borderColor: '#000',
    },
    forgotPasswordText:{
        fontSize: 16,
        alignSelf:"flex-end",
        textAlign: "right",
        paddingHorizontal: 15,
        marginBottom: 10,
    }
})

export default styles;