import React from 'react';
import { View } from 'react-native';
import AppText from '../../AppText';

import styles from './styles';

const ContactUsIndicator = ({bottom=true}) => {
    return (
        <View style={[styles.container, bottom && {marginTop: "auto"}]}>
            <AppText style={styles.text} numberOfLines={1}>¿Preguntas? Contáctanos en <AppText style={styles.emailText}>hola@pixglobal.io</AppText></AppText>
        </View>
    );
};

export default ContactUsIndicator;