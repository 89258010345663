import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import SelectDropdown from 'react-native-select-dropdown';

import Screen from '../../components/Screen';
import styles from './styles';
import {
    AppForm,
    AppFormField,
    SubmitButton,
  } from "../../components/forms";
import useAuth from '../../auth/useAuth';
import usersApi from '../../api/users';
import MessageModal from '../../components/MessageModal';
import AppText from '../../components/AppText';
import colors from '../../config/colors';
import bitsoApi from '../../api/bitso';
import { ScrollView, View } from 'react-native';
import OTPModal from '../../components/OTPModal';
import Icon from '../../components/Icon';


const beneficiaryTypeOptions = [
    {
        "description": "Persona Física",
        "value": "NATURAL"
    },
    {
        "description": "Persona Jurídica",
        "value": "LEGAL"
    }
]

const validationSchema = Yup.object().shape({
    usdc_address: Yup.string()
        .test("validate-length", "Dirección inválida.", (value) => !value || (value.length  >= 32 && value.length <= 44))
        .label("Dirección USDC (Red SOLANA)"),
    crypto_beneficiary_name: Yup.string()
        .label("Nombre Completo / Razón Social del Beneficiario"),
    vasp_did: Yup.string()
        .test("validate-lentgh", "VASP inválido.", (value) => !value || value.length === 51)
        .matches("did:ethr:0x", "VASP inválido.")
        .label("Provedor de la Billetera (VASP)"),
    crypto_beneficiary_type: Yup.string()
        .label("Tipo de Beneficiario")
  });

function CryptoInformationEditScreen() {
    const { user } = useAuth();

    const [loading, setLoading] = useState(false);
    const [usdcAddress, setUsdcAddress] = useState();
    const [initialUsdcAddress, setInitialUsdcAddress] = useState();
    const [vaspDid, setVaspDid] = useState();
    const [vasp, setVasp] = useState();
    const [initialVaspDid, setInitialVaspDid] = useState();
    const [cryptoBeneficiaryName, setCryptoBeneficiaryName] = useState();
    const [initialCryptoBeneficiaryName, setInitialCryptoBeneficiaryName] = useState();
    const [cryptoBeneficiaryType, setCryptoBeneficiaryType] = useState();
    const [cryptoBeneficiaryTypeValue, setCryptoBeneficiaryTypeValue] = useState();
    const [initialCryptoBeneficiaryTypeValue, setInitialCryptoBeneficiaryTypeValue] = useState();
    const [vaspOptions, setVaspOptions] = useState();
    const [withdrawalFrequency, setWithdrawalFrequency] = useState();
    const [preferredWithdrawalCurrency, setPreferredWithdrawalCurrency] = useState();
    const [accountantsEmail, setAccountantsEmail] = useState();
    const [bankAccount, setBankAccount] = useState();
    const [preferredQrType, setPreferredQrType] = useState(); 

    // code input
    const MAX_CODE_LENGTH = 4;
    const [code, setCode] = useState('');
    const [pinReady, setPinReady] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    
    // Confirmation modal
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [confirmationModalMessageType, setConfirmationModalMessageType] = useState('');
    const [confirmationModalHeaderText, setConfirmationModalHeaderText] = useState('');
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [confirmationModalButtonText, setConfirmationModalButtonText] = useState('');


    // OTP modal
    const [otpModalVisible, setOtpModalVisible] = useState(false);

    // Message modal
    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [messageModalMessageType, setMessageModalMessageType] = useState('');
    const [messageModalHeaderText, setMessageModalHeaderText] = useState('');
    const [messageModalMessage, setMessageModalMessage] = useState('');
    const [messageModalButtonText, setMessageModalButtonText] = useState('');

    const messageModalButtonHandler = () => {
        setMessageModalVisible(false);
    }

    const confirmationModalButtonHandler = async () => {
        setConfirmationModalVisible(false);
        await handleOtpModalShow();
    }

    const confirmationModalOutsidePressHandler = () => {
        setUsdcAddress(initialUsdcAddress);
        setVaspDid(initialVaspDid);
        setCryptoBeneficiaryName(initialCryptoBeneficiaryName);
        setCryptoBeneficiaryTypeValue(initialCryptoBeneficiaryTypeValue);
        setConfirmationModalVisible(false);
    }

    const showMessageModal = (type, headerText, message, buttonText) => {
        setMessageModalMessageType(type);
        setMessageModalHeaderText(headerText);
        setMessageModalMessage(message);
        setMessageModalButtonText(buttonText);
        setMessageModalVisible(true);
    };

    const showConfirmationModal = (type, headerText, message, buttonText) => {
        setConfirmationModalMessageType(type);
        setConfirmationModalHeaderText(headerText);
        setConfirmationModalMessage(message);
        setConfirmationModalButtonText(buttonText);
        setConfirmationModalVisible(true);
    };

    const handleConfirmationShow = async () => {
        if (usdcAddress && usdcAddress !== initialUsdcAddress){
            showConfirmationModal('updateConfirmation', '¿Seguro que esta dirección USDC es de la red SOLANA?', 'Direcciones de otra red resultarán en la pérdida irreversible de fondos.', 'Sí, Actualizá Mi Dirección')
        } else {
            await handleOtpModalShow();
        }
    }

    const handleOtpModalShow = async () => {
        setLoading(true);
        let sendUpdateConfirmOTPResult;
        // make request to backend
        sendUpdateConfirmOTPResult = await usersApi.sendUpdateConfirmOTPEmail(user.id);
        if (!sendUpdateConfirmOTPResult.ok){
            setTimeout(async () => {
                sendUpdateConfirmOTPResult = await usersApi.sendUpdateConfirmOTPEmail(user.id);
            }, 1500)
        }

        if (sendUpdateConfirmOTPResult.ok) {
            setOtpModalVisible(true);
            setLoading(false);
            return
        }
        alert("Error", ' Inténtelo de nuevo en breve.');
        setLoading(false);
    }

    const handleSubmit = async () => {
        setLoading(true);
        const result = await usersApi.update(user.id, {
            "vasp_did": vaspDid,
            "crypto_beneficiary_name": cryptoBeneficiaryName,
            "crypto_beneficiary_type": cryptoBeneficiaryTypeValue,
            "usdc_address": usdcAddress,
            "accountants_email": accountantsEmail,
            "preferred_withdrawal_currency": preferredWithdrawalCurrency,
            "preferred_qr_code_type": preferredQrType,
            "withdrawal_frequency": withdrawalFrequency,
            "bank_account": bankAccount,
            "otp": code,
        });

        if (!result.ok) {
            setLoading(false);
            onCancelPress();
            setOtpModalVisible(false);
            if(result.status == 406){
                return showMessageModal('failed', '¡Error!', "No se puede borrar la dirección cripto con retiros en USDC activados.", 'Cerrar');
            }
            return showMessageModal('failed', '¡Error!', result.data ? "PIN inválido." : "Ocurrió un error inesperado.", 'Cerrar');
        }
        setLoading(false);
        setInitialUsdcAddress(usdcAddress);
        setInitialVaspDid(vaspDid);
        setInitialCryptoBeneficiaryName(cryptoBeneficiaryName);
        setInitialCryptoBeneficiaryTypeValue(cryptoBeneficiaryTypeValue);
        setOtpModalVisible(false);
        setCode('');
        return showMessageModal('success', '¡Todo está bien!', 'Su información ha sido actualizada.', 'Ok');
    }

    const onCancelPress = () => {
        setUsdcAddress(initialUsdcAddress);
        setVaspDid(initialVaspDid);
        setCryptoBeneficiaryName(initialCryptoBeneficiaryName);
        setCryptoBeneficiaryTypeValue(initialCryptoBeneficiaryTypeValue);
        setOtpModalVisible(false);
        setCode('');
    }

    useEffect(() => {
        if (vaspOptions && vaspDid){
            setVasp(vaspOptions.find((element) => element.id==vaspDid));
        }
    }, [vaspOptions, vaspDid]);

    useEffect(() => {
        if (cryptoBeneficiaryTypeValue){
            setCryptoBeneficiaryType(beneficiaryTypeOptions.find((element) => element.value==cryptoBeneficiaryTypeValue));
        }
    }, [cryptoBeneficiaryTypeValue]);

    useEffect(() => {
        if (vaspOptions && initialVaspDid){
            setVasp(vaspOptions.find((element) => element.id==initialVaspDid));
        }
    }, [vaspOptions, initialVaspDid]);

    useEffect(() => {
        // toggle pinReady
        if (code.length < MAX_CODE_LENGTH) {
            setInvalidPin(false);
        }
        setPinReady(code.length === MAX_CODE_LENGTH);
        return () => setPinReady(false);
    }, [code]);


    useEffect(() => {
        setLoading(true);
        usersApi.get(user.id)
            .then((res) => {
                setUsdcAddress(res.data.usdc_address);
                setInitialUsdcAddress(res.data.usdc_address);
                setVaspDid(res.data.vasp_did);
                setInitialVaspDid(res.data.vasp_did);
                setCryptoBeneficiaryName(res.data.crypto_beneficiary_name);
                setInitialCryptoBeneficiaryName(res.data.crypto_beneficiary_name);
                setCryptoBeneficiaryTypeValue(res.data.crypto_beneficiary_type);
                setInitialCryptoBeneficiaryTypeValue(res.data.crypto_beneficiary_type);
                setWithdrawalFrequency(res.data.withdrawal_frequency);
                setPreferredWithdrawalCurrency(res.data.preferred_withdrawal_currency);
                setAccountantsEmail(res.data.accountants_email);
                setBankAccount(res.data.bank_account);
                setPreferredQrType(res.data.preferred_qr_code_type);
            })
            .catch((error) => console.log(error));
        bitsoApi.getVaspOptions()
            .then((res) => {
                setVaspOptions(res.data);
            })
            .catch((error) => console.log(error));
      }, []);


      useEffect(() => {
        setLoading(false)
      }, [usdcAddress, vaspDid, cryptoBeneficiaryName, cryptoBeneficiaryTypeValue]);

    return (
        <Screen style={styles.container}>
            <ScrollView>
                <AppForm
                    initialValues={{ 
                        usdc_address: "",
                        vasp_did: "",
                        crypto_beneficiary_name: "",
                        crypto_beneficiary_type: "",
                    }}
                    onSubmit={handleConfirmationShow}
                    validationSchema={validationSchema}
                >
                    <AppText style={{fontWeight: 'bold'}}>Campos Requeridos</AppText>
                    <AppFormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        icon="pound"
                        name="usdc_address"
                        placeholder="Dirección USDC (SOLANA)"
                        selectionFromStart={true}
                        setState={setUsdcAddress}
                        maxLength={44}
                        value={usdcAddress || ""}
                    />
                    <View style={styles.divider} />
                    <AppText style={{fontWeight: 'bold'}}>Campos Opcionales*</AppText>
                    <AppFormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        icon="account"
                        name="crypto_beneficiary_name"
                        placeholder="Nombre Completo / Razón Social"
                        setState={setCryptoBeneficiaryName}
                        maxLength={42}
                        value={cryptoBeneficiaryName || ""}
                    />
                    <SelectDropdown
                        data={vaspOptions}
                        defaultValue={vasp}
                        onSelect={(selectedItem) => {
                            setVaspDid(selectedItem.id)
                        }}
                        defaultButtonText={<AppText style={{'color': colors.medium}}>Provedor de la Billetera (VASP)</AppText>}
                        buttonTextAfterSelection={(selectedItem) => {
                            return <AppText>{selectedItem.name}</AppText>
                        }}
                        rowTextForSelection={(item) => {
                            return <AppText>{item.name}</AppText>
                        }}
                        buttonStyle={styles.dropdown1BtnStyle}
                        buttonTextStyle={styles.dropdown1BtnTxtStyle}
                        renderDropdownIcon={isOpened => {
                            return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} iconColor={colors.dark} backgroundColor='transparent' size={48} />;
                        }}
                        dropdownIconPosition={'right'}
                        dropdownStyle={styles.dropdown1DropdownStyle}
                        rowStyle={styles.dropdown1RowStyle}
                        rowTextStyle={styles.dropdown1RowTxtStyle}
                        search={true}
                        searchInputStyle={styles.dropdown1searchInputStyleStyle}
                        searchPlaceHolder={'Busca aquí'}
                        searchPlaceHolderColor={'darkgrey'}
                        renderSearchInputLeftIcon={() => {
                            return <Icon name={'search'} color={'#444'} backgroundColor='transparent' size={48} />;
                        }}
                    />
                    <View style={styles.divider} />
                    <SelectDropdown
                        data={beneficiaryTypeOptions}
                        defaultValue={cryptoBeneficiaryType}
                        onSelect={(selectedItem) => {
                            setCryptoBeneficiaryTypeValue(selectedItem.value)
                        }}
                        defaultButtonText={<AppText style={{'color': colors.medium}}>Tipo de Beneficiario</AppText>}
                        buttonTextAfterSelection={(selectedItem) => {
                            return <AppText>{selectedItem.description}</AppText>
                        }}
                        rowTextForSelection={(item) => {
                            return <AppText>{item.description}</AppText>
                        }}
                        buttonStyle={styles.dropdown1BtnStyle}
                        buttonTextStyle={styles.dropdown1BtnTxtStyle}
                        renderDropdownIcon={isOpened => {
                            return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} color={colors.dark} backgroundColor='transparent' size={48} />;
                        }}
                        dropdownIconPosition={'right'}
                        dropdownStyle={styles.dropdown1DropdownStyle}
                        rowStyle={styles.dropdown1RowStyle}
                        rowTextStyle={styles.dropdown1RowTxtStyle}
                    />
                    <View style={styles.divider} />
                    <AppText style={{fontSize: 13}}>* Necesarios para transacciones superiores a 1,000 USDC</AppText>
                    <View style={styles.divider} />
                    <SubmitButton disabled={loading || (usdcAddress && usdcAddress.length > 0 && usdcAddress.length < 32)} color="pending" title="Actualizá Información Cripto" loading={loading}/>
                </AppForm>
            </ScrollView>
            <OTPModal
                modalVisible={otpModalVisible}
                onCancelPress={onCancelPress}
                onConfirmPress={handleSubmit}
                color={"pending"}
                user={user}
                code={code}
                setCode={setCode}
                pinReady={pinReady}
                invalidPin={invalidPin}
                MAX_CODE_LENGTH={MAX_CODE_LENGTH} 
                loading={loading}
            />
            <MessageModal
                modalVisible={messageModalVisible}
                buttonHandler={messageModalButtonHandler}
                outsidePressHandler={messageModalButtonHandler}
                type={messageModalMessageType}
                headerText={messageModalHeaderText}
                message={messageModalMessage}
                buttonText={messageModalButtonText}
            />
            <MessageModal
                modalVisible={confirmationModalVisible}
                buttonHandler={confirmationModalButtonHandler}
                outsidePressHandler={confirmationModalOutsidePressHandler}
                type={confirmationModalMessageType}
                headerText={confirmationModalHeaderText}
                message={confirmationModalMessage}
                buttonText={confirmationModalButtonText}
            />
        </Screen>
    );
}

export default CryptoInformationEditScreen;