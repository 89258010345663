import React from 'react';
import { useFormikContext } from 'formik';

import AppButton from './../../AppButton'

function SubmitButton( {title, color = "primary", disabled=false, loading=false, icon=null} ) {
    const { handleSubmit } = useFormikContext();
    
    return (
        <AppButton title={title} onPress={handleSubmit} color={color} disabled={disabled} loading={loading} icon={icon}/>
    );
}

export default SubmitButton;