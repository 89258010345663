import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";

import LoginScreen from '../screens/LoginScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import PasswordScreen from '../screens/PasswordScreen';


const Stack = createStackNavigator();


const LoginNavigator = () => (
    <Stack.Navigator>
        <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }}/>
        <Stack.Screen name="Password" component={PasswordScreen} options={{ headerShown: false}}/>
        <Stack.Screen name="Reset Password" component={ResetPasswordScreen} options={{ headerShown: false }}/>
    </Stack.Navigator>
);

export default LoginNavigator;