import React from 'react';
import { View } from 'react-native';

import styles from './styles';


function ListItemSeparator() {
    return (
        <View style={styles.separator}/>
    );
}

export default ListItemSeparator;