import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        paddingTop: 5,
    },
    text: {
        fontSize: 12,
    },
    emailText: {
        fontSize: 12,
        fontWeight: "bold",
    }
})


export default styles;