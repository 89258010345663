import React, { useState } from 'react';
import { Modal, Pressable, View } from 'react-native';
import * as Clipboard from 'expo-clipboard';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import AppButton from '../AppButton';
import { AppForm, AppFormField } from '../forms';


const CryptoWithdrawalModal = ({ modalVisible, outsidePressHandler }) => {

  const [usdcAddress, setUsdcAddress] = useState(null);

  const fetchCopiedText = async () => {
    const text = await Clipboard.getStringAsync();
    setCBU(text);
  };
  
  const getPasteButton = () => {
    return (
        <Pressable onPress={() => fetchCopiedText()}>
            <AppText>  Pegar</AppText>
        </Pressable>
    )
  }

  return (
    <Modal animationType="slide" visible={modalVisible} transparent={false}>
      <Pressable style={styles.container}>
        <View style={styles.modal}>
          <AppText style={{fontSize: 20, color: colors.white, marginBottom: 10, textAlign: 'center', fontWeight: 'bold', color: "white" }}>Retiro de Dólares Digitales (USDC)</AppText>
          <View style={styles.divider}/>
          <AppForm initialValues={{ withdrawalAmount: ""}}>
            <AppText style={{color: "white", textAlign: "left", fontSize: 12, marginLeft: 5}}>Monto (USDC)</AppText>
            <AppFormField
              masked={true}
              type="currency"
              options={{
                  decimalSeparator: '.',
                  groupSeparator: ',',
                  precision: 2
              }}
              keyboardType="numeric"
              maxLength={16}
              name="withdrawalAmount"
              width={120}
            />
            <View style={{alignItems: "flex-end"}}>
              <AppText style={{color: "white", fontSize:12, textAlign: "right", marginRight: 5}}>Saldo: 0.00 USDC</AppText>
            </View>
            <View style={styles.divider}/>
            <AppText style={{color: "white", textAlign: "left", fontSize: 12, marginLeft: 5}}>Dirección USDC (Red Solana)</AppText>
            <AppFormField
                autoCapitalize="none"
                autoCorrect={false}
                name="usdc_address"
                placeholder="Dir. USDC - Red SOLANA"
                selectionFromStart={true}
                setState={setUsdcAddress}
                maxLength={44}
                value={usdcAddress || ""}
            />
          </AppForm>
          <View style={styles.divider}/>
          <View style={styles.buttonsContainer}>
            <View>
                <AppButton onPress={outsidePressHandler} title={"Cancelar"} color="secondary"/>
            </View>
            <View>
                <AppButton onPress={outsidePressHandler} title={"Continuar"} color="danger"/>
            </View>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};

export default CryptoWithdrawalModal;