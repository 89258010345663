export default {
  argentina: "#6CACE4",
  primary: "#29baad", // Verde PIX
  secondary: "#0066a8",
  black: "#000",
  white: "#fff",
  medium: "#6e6969",
  light: "#f8f4f4",
  dark: "#0c0c0c",
  danger: "#ff5252",
  disabled: "#ebebe4",
  pending: "#ffd700",
  success: "#42ba96",
  lightGrey: "#f0f0f0",
  mediumGrey: "#d1d1cd",
  darkGrey: '#767577',
};
