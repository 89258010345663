import React, { useState } from 'react';
import { Modal, Platform, SafeAreaView, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

// styled components
import styles from './styles';
import AppButton from '../AppButton';
import usersApi from '../../api/users';
import { Pressable } from 'react-native';
import colors from '../../config/colors';
import AppText from '../AppText';
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field';
import { AppForm, ErrorMessage } from '../forms';
import ResendTimer from '../ResendTimer';
import { Keyboard } from 'react-native';


const OTPModal = ({ modalVisible, onCancelPress, onConfirmPress, color, user, code, setCode, pinReady, invalidPin, MAX_CODE_LENGTH, loading }) => {  

    // resending email
    const [activeResend, setActiveResend] = useState(false);
    const [resendStatus, setResendStatus] = useState('Reenviar');

    const ref = useBlurOnFulfill({code, cellCount: MAX_CODE_LENGTH});
    const [props, getCellOnLayoutHandler] = useClearByFocusCell({
        code,
        setCode,
    });

    const resendEmail = async (triggerTimer) => {
        setActiveResend(false);
        try {
          let result;
          let companyId;
          // make request to backend
          result = await usersApi.sendUpdateConfirmOTPEmail(companyId=user.id);
          if (!result.ok){
            setTimeout(async () => {
              result = await usersApi.sendUpdateConfirmOTPEmail(companyId=user.id);
            }, 1500)
          }
          if (!result.ok) {
              setResendStatus("Fracasado!");
              return;
          }
          setResendStatus("Enviado!");
          setResendStatus('Reenviar');
          triggerTimer();
        } catch (error) {
          setResendStatus('Fracasado!');
          alert("Error", 'Reenvio de Email Fracasado: ' + error.message);
        }
    };
    
    const safeAreaComponent = (               
        <SafeAreaView style={[styles.modal, Platform.OS == "web" && {flex: 1}]}>
            <MaterialCommunityIcons
                name="lock"
                size={100}
                color={colors[color]}
            />
            <AppText style={{ fontSize: 25, color: colors.white, marginVertical: 10, textAlign: 'center' }}>Verifique su Correo</AppText>
            <AppText style={{ color: colors.lightGrey, textAlign: 'center' }}>
                Ingrese el código de 4 dígitos enviado a su correo <AppText style={{ color: colors.lightGrey, fontWeight: 'bold' }}>{user.email}</AppText>
            </AppText>
            <AppForm
                initialValues={{ otp: "" }}
            >
                <CodeField
                    ref={ref}
                    {...props}
                    value={code}
                    onChangeText={setCode}
                    cellCount={MAX_CODE_LENGTH}
                    rootStyle={styles.codeFieldRoot}
                    keyboardType="number-pad"
                    textContentType="oneTimeCode"
                    renderCell={({index, symbol, isFocused}) => (
                    <AppText
                        key={index}
                        style={[styles.cell, isFocused && styles.focusCell]}
                        onLayout={getCellOnLayoutHandler(index)}>
                        {symbol || (isFocused ? <Cursor /> : null)}
                    </AppText>
                    )}
                />
                <ErrorMessage error="PIN invalido." visible={invalidPin}/>
                <ResendTimer
                    activeResend={activeResend}
                    setActiveResend={setActiveResend}
                    targetTimeInSeconds={30}
                    resendStatus={resendStatus}
                    resendEmail={resendEmail}
                    style={{ marginBottom: 25 }}
                    color={colors.lightGrey}
                />
            </AppForm>
            <View style={styles.buttonsContainer}>
                <View>
                    <AppButton onPress={onCancelPress} title={"Cancelar"} color={"danger"} disabled={loading}/>
                </View>
                <View>
                    <AppButton onPress={onConfirmPress} title={"Confirmar"} color={color} disabled={!pinReady || loading} loading={loading}/>
                </View>
            </View>
        </SafeAreaView>
    )

    if (Platform.OS === "web") {
        return (
            <Modal animationType="slide" visible={modalVisible} transparent={true}>
                {safeAreaComponent}
            </Modal>
        )
    } else {
        return (
            <Modal animationType="slide" visible={modalVisible} transparent={true}>
                <Pressable style={styles.container} onPress={Keyboard.dismiss}>
                    {safeAreaComponent}
                </Pressable>
            </Modal>
        );
    }
};

export default OTPModal;