import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';

import Screen from '../../components/Screen';
import { ListItem } from '../../components/lists';
import styles from './styles';
import Icon from '../../components/Icon';
import MessageModal from '../../components/MessageModal';




function SupportScreen({ navigation }) {
    
    // Message modal
    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [messageModalMessageType, setMessageModalMessageType] = useState('');
    const [messageModalHeaderText, setMessageModalHeaderText] = useState('');
    const [messageModalMessage, setMessageModalMessage] = useState('');
    const [messageModalButtonText, setMessageModalButtonText] = useState('');


    const showMessageModal = (type, headerText, message, buttonText) => {
        setMessageModalMessageType(type);
        setMessageModalHeaderText(headerText);
        setMessageModalMessage(message);
        setMessageModalButtonText(buttonText);
        setMessageModalVisible(true);
    };

    const buttonHandler = () => {
        setMessageModalVisible(false);
    };

    const outsidePressHandler = () => {
        setMessageModalVisible(false);
    }

    return (
        <Screen style={styles.screen}>
            <ScrollView>
                <View style={styles.container}>
                    <ListItem
                        title="Datos de Registro"
                        IconComponent={
                            <Icon name="account" iconColor={"black"} backgroundColor={"transparent"}/>
                        }
                        onPress={() => showMessageModal("none", "Datos de Registro", "\nPara cambiar datos de registro, enviar un correo a \n\n hola@pixglobal.io \n\n desde el correo registrado, informando CUIT/CUIL y los nuevos datos.","OK")}
                    />
                    <ListItem
                        title="Acreditación de Pagos"
                        IconComponent={
                            <Icon name="cash" iconColor={"black"} backgroundColor={"transparent"}/>
                        }
                        onPress={() => showMessageModal("none", "Acreditación de Pagos", "\nAunque intentemos acreditar los pagos inmediatamente, en raras veces latencias estacionales y demoras en la red bancaria pueden pasar.\n\n  Por favor tomar hasta 2 días hábiles completos luego de la fecha de recibo como plazo de acreditación." ,"OK")}
                    />
                </View>
            </ScrollView>
            <MessageModal
                modalVisible={messageModalVisible}
                buttonHandler={buttonHandler}
                outsidePressHandler={outsidePressHandler}
                type={messageModalMessageType}
                headerText={messageModalHeaderText}
                message={messageModalMessage}
                buttonText={messageModalButtonText}
                />
        </Screen>
    );
}

export default SupportScreen;