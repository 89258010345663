import { Platform, StyleSheet } from 'react-native';
import colors from '../../config/colors';

const styles = StyleSheet.create({
    infoContainer:{
        marginBottom: 20,
    },
    key: {
        marginLeft: 15,
        marginBottom: 5,
        fontWeight: "bold",
        fontSize: 16,
    },
    value: {
        marginLeft: 25,
    },
    dropdown1BtnStyle: {
        width: "100%",
        backgroundColor: colors.white,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.disabled,
    },
    dropdown1BtnTxtStyle: {
        color: colors.dark, 
        textAlign: 'left'
    },
    dropdown1DropdownStyle: {
        backgroundColor: colors.lightGrey,
    },
    dropdown1RowStyle: {
        backgroundColor: colors.light,
        borderBottomColor: colors.disabled,
    },
    dropdown1RowTxtStyle: {
        color: colors.dark,
        textAlign: 'left'
    },
    dropdown2BtnStyle: {
        width: "80%",
        alignSelf: "center",
        backgroundColor: colors.light,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.light,
    },
    dropdown2BtnTxtStyle: {
        color: colors.dark, 
        textAlign: Platform.OS == "web" ? 'left' : "center",
    },
    dropdown2RowTxtStyle: {
        color: colors.dark,
        textAlign: 'center'
    },
    balanceOption: {
        fontWeight: "bold",
        alignSelf: "center",
        color: "blue",
        fontSize: 13
    },
    exchange: {
        marginVertical: 15,
        alignSelf: "center",
    }
})

export default styles;