import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";
import InitialScreen from '../screens/InitialScreen/InitialScreen';
import AuthNavigator from './AuthNavigator';


const Stack = createStackNavigator();


const InitialNavigator = ({ initialUrl }) => (
    <Stack.Navigator screenOptions={{ headerTitle: "" }}>
        <Stack.Screen name="Initial" component={InitialScreen} options={{ headerShown: false }} />
        <Stack.Screen name="Auth Navigator" component={AuthNavigator} initialParams={initialUrl} options={{ headerShown: false }}/>
    </Stack.Navigator>
);

export default InitialNavigator;