import React from 'react';
import { Modal, Pressable, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import AppButton from '../AppButton';


const MessageModal = ({ modalVisible, buttonHandler, outsidePressHandler, type, headerText, message, buttonText }) => {
  return (
    <Modal animationType="slide" visible={modalVisible} transparent={false}>
      <Pressable onPress={(outsidePressHandler)} style={styles.container}>
        <View style={styles.modal}>
          {type !== "none" &&
          <MaterialCommunityIcons
            name={type === 'idleNotification' ? 'sleep' : (type === 'success' ? 'check-circle' : (type === 'deleteConfirmation' ? 'trash-can' : (type === "updateConfirmation" ? 'exclamation-thick' : (type === "advanceConfirmation" ? "hand-coin" : (type === 'existingAccount' ? 'account-multiple': 'close-circle')))))}
            size={100}
            color={type === 'idleNotification' ? colors.white : (type === 'success' ? colors.success : (['updateConfirmation', 'advanceConfirmation', 'existingAccount'].includes(type) ? colors.pending : colors.danger))}
          />}
          <AppText style={{ fontSize: 25, color: colors.white, marginVertical: 10, textAlign: 'center' }}>{headerText}</AppText>
          <AppText style={{fontSize: 15, color: colors.white, marginBottom: 20, textAlign: 'center' }}>{message}</AppText>
          <AppButton onPress={buttonHandler} title={buttonText || `Complete`} color={['idleNotification', 'none'].includes(type) ? 'secondary' : (type === 'success' ? "success" : (['updateConfirmation', 'advanceConfirmation', 'existingAccount'].includes(type) ? "pending" : "danger"))}/>
        </View>
      </Pressable>
    </Modal>
  );
};

export default MessageModal;