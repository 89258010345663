import { Dimensions, Platform, StyleSheet } from 'react-native';

const screenWidth = Dimensions.get('screen').width;


const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    logo: {
        width: 1024/6,
        height: 1024/6,
        position: "absolute",
        top: Platform.OS == "web" ? 40 : 130
    },
    buttonsContainer: {
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    footerContainer: {
        flexDirection:"row",
        marginBottom: 50,
        gap: screenWidth/5,
    },
    footerLink: {
        alignItems: "center",
    },
    icon: {
        flex: 1,
    },
    iconText: {
        fontSize: 10,
    }
})

export default styles;