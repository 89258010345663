import React, { useEffect, useState } from 'react';
import { Modal, Platform, Pressable, View } from 'react-native';
import * as Yup from "yup";
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import conversionApi from '../../api/conversion';
import usersApi from '../../api/users';
import { prettifyToString } from '../../utility/utils';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import AppButton from '../AppButton';
import { AppForm, AppFormField } from '../forms';
import SelectDropdown from 'react-native-select-dropdown';
import Icon from '../Icon';
import MessageModal from '../MessageModal';
import useAuth from '../../auth/useAuth';


const ConversionModal = ({ modalVisible, outsidePressHandler }) => {
  const { user } = useAuth();
  

  // modal
  const [secondaryModalVisible, setSecondaryModalVisible] = useState(false);
  const [modalMessageType, setModalMessageType] = useState('');
  const [headerText, setHeaderText] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [buttonText, setButtonText] = useState('');

  const showModal = (type, headerText, message, buttonText) => {
      setModalMessageType(type);
      setHeaderText(headerText);
      setModalMessage(message);
      setButtonText(buttonText);
      setSecondaryModalVisible(true);
  };

  const buttonHandler = async () => {
    if (modalMessageType === 'idleNotification'){
      setCount(0);
    }
    outsidePressHandler();
    setSecondaryModalVisible(false);
  };

  const secondaryOutsidePressHandler = () => {
      outsidePressHandler();
      setSecondaryModalVisible(false);
      if (modalMessageType === 'idleNotification'){
        setCount(0);
      }
  }

  const [fromCurrency, setFromCurrency] = useState("ARS");
  const [fromBalance, setFromBalance] = useState(null);
  const [toCurrency, setToCurrency] = useState("BRL");
  const [amountFrom, setAmountFrom] = useState("0.00");
  const [amountTo, setAmountTo] = useState("0.00");
  const [count, setCount] = useState(0);
  const [balanceArs, setBalanceArs] = useState(null);
  const [balanceBrl, setBalanceBrl] = useState(null);
  const [balanceUsdc, setBalanceUsdc] = useState(null);
  const [exchangeRateBrlArs, setExchangeRateBrlArs] = useState(null);
  const [exchangeRateArsBrl, setExchangeRateArsBrl] = useState(null);
  const [exchangeRateUsdcBrl, setExchangeRateUsdcBrl] = useState(null);
  const [exchangeRateBrlUsdc, setExchangeRateBrlUsdc] = useState(null);
  const [exchangeRateUsdcArs, setExchangeRateUsdcArs] = useState(null);
  const [exchangeRateArsUsdc, setExchangeRateArsUsdc] = useState(null);
  const [exchangeRateString, setExchangeRateString] = useState("");
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);

  const currencyOptions = [
    "ARS", "BRL", "USDC"
  ]
  const MINUTE_SECONDS_MS = 60000;

  let timerId;

  const clear = () => {
      clearTimeout(timerId);
  }

  useEffect(() => {
      if (count == 10){
          outsidePressHandler();
          showModal('idleNotification', 'Aplicación Inactiva', 'La aplicación ha estado inactiva durante 10 minutos, por lo que hemos pausado la actualización automática de precios.', 'Ok, Ya Estoy de Vuelta');
          return clear();
      }
      conversionApi.getConversionRate()
          .then((res) => {
              const exchangeRateBRLARS = res.data.exchange_rate.toFixed(2);
              const exchangeRateUSDCBRL = res.data.exchange_rate_usdc.toFixed(2);
              const exchangeRateUSDCARS = res.data.exchange_rate_ars_usdc.toFixed(2);
              const exchangeRateARSBRL = res.data.payout.exchange_rate.toFixed(2);
              const exchangeRateBRLUSDC = res.data.payout.exchange_rate_usdc.toFixed(2);
              const exchangeRateARSUSDC = res.data.payout.exchange_rate_ars_usdc.toFixed(2);
              setExchangeRateBrlArs(exchangeRateBRLARS);
              setExchangeRateArsBrl(exchangeRateARSBRL);
              setExchangeRateUsdcBrl(exchangeRateUSDCBRL);
              setExchangeRateBrlUsdc(exchangeRateBRLUSDC);
              setExchangeRateUsdcArs(exchangeRateUSDCARS);
              setExchangeRateArsUsdc(exchangeRateARSUSDC);
              timerId = setTimeout(() => {setCount(count + 1)}, MINUTE_SECONDS_MS);
          })
          .catch((error) => console.log(error));
      const companyId = user.type == 'master' ? user.id : user.company_id;
      usersApi.get(companyId)
          .then((res) => {
              setBalanceArs(res.data.balance_ars);
              setBalanceBrl(res.data.balance_brl);
              setBalanceUsdc(res.data.balance_usdc);
          })
          .catch((error) => console.log(error));
      return clear();
  }, [count, modalVisible]);

  useEffect(() => {
    setTyping(true);
    const amountFromNumber = Number(amountFrom.replace(/,/g, ''));
    if (modalVisible){
      let amountTo;
      if (fromCurrency == toCurrency){
        amountTo = amountFromNumber;
        setExchangeRateString(`Seleccione monedas distintas`)
      } else if (fromCurrency == "ARS" && toCurrency == "BRL"){
        amountTo = (Math.floor((amountFromNumber / exchangeRateArsBrl)*100)/100).toFixed(2);
        setExchangeRateString(`1 BRL = ${exchangeRateArsBrl} ARS`);
      } else if (fromCurrency == "ARS" & toCurrency == "USDC"){
        amountTo = (Math.floor((amountFromNumber / exchangeRateArsUsdc)*100)/100).toFixed(2);
        setExchangeRateString(`1 USDC = ${exchangeRateArsUsdc} ARS`);
      } else if (fromCurrency == "BRL" & toCurrency == "ARS"){
        amountTo = (Math.floor((amountFromNumber * exchangeRateBrlArs)*100)/100).toFixed(2);
        setExchangeRateString(`1 BRL = ${exchangeRateBrlArs} ARS`);
      } else if (fromCurrency == "BRL" & toCurrency == "USDC"){
        amountTo = (Math.floor((amountFromNumber / exchangeRateUsdcBrl)*100)/100).toFixed(2);
        setExchangeRateString(`1 USDC = ${exchangeRateUsdcBrl} BRL`);
      } else if (fromCurrency == "USDC" & toCurrency == "ARS"){
        amountTo = (Math.floor((amountFromNumber * exchangeRateUsdcArs)*100)/100).toFixed(2);
        setExchangeRateString(`1 USDC = ${exchangeRateUsdcArs} ARS`);
      } else if (fromCurrency == "USDC" & toCurrency == "BRL"){
        amountTo = (Math.floor((amountFromNumber * exchangeRateBrlUsdc)*100)/100).toFixed(2);
        setExchangeRateString(`1 USDC = ${exchangeRateBrlUsdc} BRL`);
      }
      const timer = setTimeout(() => {
        setAmountTo((amountTo*100).toFixed(2))
      }, 1000)
  
      return () => clearTimeout(timer)
    }
  }, [modalVisible, amountFrom, exchangeRateBrlUsdc, exchangeRateArsUsdc, fromCurrency, toCurrency])

  useEffect(() => {
    setTyping(false);
  }, [amountTo]);
  
  const getCurrencyDropdown = (initialCurrency) => {
    return (
        <SelectDropdown
            data={currencyOptions}
            defaultValue={initialCurrency}
            onSelect={(selectedItem) => {
              if (initialCurrency == "ARS"){
                setFromCurrency(selectedItem);
              } else {
                setToCurrency(selectedItem);
              }
            }}
            defaultButtonText={<AppText style={{'color': colors.medium}}>{initialCurrency}</AppText>}
            buttonTextAfterSelection={(selectedItem) => {
                return <AppText style={{fontSize: 16}}>{selectedItem}</AppText>
            }}
            rowTextForSelection={(item) => {
                return <AppText style={{fontSize: 16}}>{item}</AppText>
            }}
            buttonStyle={styles.dropdown2BtnStyle}
            buttonTextStyle={styles.dropdown2BtnTxtStyle}
            renderDropdownIcon={isOpened => {
              if (Platform.OS == "web"){
                return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} iconColor={colors.dark} backgroundColor='transparent' size={46} />;
              } else {
                  return <FontAwesome name={isOpened ? 'chevron-up' : 'chevron-down'} color={colors.dark} size={14} />
              }
            }}
            dropdownIconPosition={'left'}
            dropdownStyle={styles.dropdown1DropdownStyle}
            rowStyle={styles.dropdown1RowStyle}
            rowTextStyle={styles.dropdown2RowTxtStyle}
        />
    )
  }

  useEffect(() => {
    if (fromCurrency == "ARS"){
      setFromBalance(Number(balanceArs))
    } else if (fromCurrency == "BRL"){
      setFromBalance(Number(balanceBrl))
    } else if (fromCurrency == "USDC"){
      setFromBalance(Number(balanceUsdc))
    }
  }, [fromCurrency, modalVisible]);

  const validationSchema = Yup.object().shape({
      amountFrom: Yup.number()
          .transform((_value, originalValue) => Number(originalValue.replace(/,/g, '')))
          .min(0, 'Minimo 0')
          .max(Number(fromBalance), 'Su saldo no es suficiente.')
          .required().label("Monto")
  });

  const handleSubmit = async() => {
    setLoading(true);
    const result = await usersApi.createConversion(user.id, {
      "currency_from": fromCurrency,
      "amount_from": amountFrom.replace(/,/g, ''),
      "currency_to": toCurrency,
      "amount_to": (amountTo/100).toFixed(2),
    })

    if (!result.ok) {
      setLoading(false);
      outsidePressHandler();
      return showModal('failed', '¡Error!', "Ocurrió un error inesperado.", 'Cerrar');
    }

    setLoading(false);
    setFromCurrency("ARS");
    setToCurrency("BRL");
    setAmountFrom("0.00");
    setAmountTo("0.00");
    return showModal('success', '¡Todo está bien!', 'Conversión realizada.', 'Ok');
  };


  return (
    <>
    <Modal animationType="slide" visible={modalVisible} transparent={false}>
      <Pressable style={styles.container}>
        <View style={styles.modal}>
          <AppText style={{ fontSize: 20, color: colors.white, fontWeight: "bold", marginTop: 10, marginBottom: 20, textAlign: 'center' }}>Conversión de Monedas</AppText>
            <AppForm
                initialValues={{ amountFrom: "", amountTo: ""}}
                validateOnMount={true}
                validationSchema={validationSchema}
            >
              <AppText style={{fontSize: 15, color: "white", textAlign: "left", marginLeft: 5}}>Pagar con:</AppText>
              <AppFormField
                  masked={true}
                  type="currency"
                  options={{
                      decimalSeparator: '.',
                      groupSeparator: ',',
                      precision: 2
                  }}
                  keyboardType="numeric"
                  maxLength={16}
                  name="amountFrom"
                  setState={setAmountFrom}
                  width={120}
                  dropdown={getCurrencyDropdown("ARS")}
              />
              <View style={{alignItems: "flex-end"}}>
                <AppText style={{color: "white", fontSize:12, textAlign: "right", marginRight: 5}}>Saldo: {fromCurrency == "ARS" ? prettifyToString(balanceArs): (fromCurrency == "BRL" ? prettifyToString(balanceBrl) : prettifyToString(balanceUsdc))} {fromCurrency}</AppText>
              </View>
              <View style={styles.divider}/>
              <AppText style={{fontSize: 15, color: "white", textAlign: "left", marginLeft: 5}}>Recibir en:</AppText>
              <AppFormField
                  masked={true}
                  type="currency"
                  options={{
                      decimalSeparator: '.',
                      groupSeparator: ',',
                      precision: 2
                  }}
                  keyboardType="numeric"
                  maxLength={16}
                  name="amountTo"
                  value={amountTo}
                  width={120}
                  dropdown={getCurrencyDropdown("BRL")}
                  editable={false}
              />
            </AppForm>
            <View style={styles.divider}/>
            <View>
              <AppText style={{color: "white", textAlign: "center", fontSize: 10}}>{exchangeRateString}</AppText>
              {fromCurrency !== toCurrency && <AppText style={{color: "white", textAlign: "center", fontSize: 10}}>(custo efectivo con spread {toCurrency == "BRL" ? "0%" : "1%"})</AppText>}
            </View>
            <View style={styles.divider}/>
            <View style={styles.buttonsContainer}>
              <View>
                  <AppButton onPress={() => { outsidePressHandler(); setFromCurrency("ARS"); setToCurrency("BRL"); setAmountFrom("0.00"), setAmountTo("0.00")}} title={"Cancelar"} color="secondary"/>
              </View>
              <View>
                  <AppButton onPress={() => handleSubmit()} title={"Continuar"} color="danger" disabled={Number(amountFrom.replace(/,/g, '')) == 0 || loading || typing || fromCurrency == toCurrency || Number(amountTo.replace(/,/g, '')) == 0 || Number(amountFrom.replace(/,/g, '')) > fromBalance}/>
              </View>
            </View>
        </View>
      </Pressable>
    </Modal>
    <MessageModal
      modalVisible={secondaryModalVisible}
      buttonHandler={buttonHandler}
      outsidePressHandler={secondaryOutsidePressHandler}
      type={modalMessageType}
      headerText={headerText}
      message={modalMessage}
      buttonText={buttonText}
    />
    </>
  );
};

export default ConversionModal;