import { ScrollView, View } from 'react-native';
import styles from './styles'; 
import AppText from '../../components/AppText';
import { ErrorMessage } from '../../components/forms';
import { useState } from 'react';
import operatorsApi from '../../api/operators';
import usersApi from '../../api/users';
import useAuth from '../../auth/useAuth';
import AppTextInput from '../../components/AppTextInput';
import AppButton from '../../components/AppButton';
import { ActivityIndicator } from '../../components/indicators';
import Screen from '../../components/Screen';


function PasswordScreen({route, navigation}) { 
    const { logIn } = useAuth();

    const [loginFailed, setLoginFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);

    const [password, setPassword] = useState(null);

    const { user } = route.params;

    const handleSubmit = async () => {
        var id;
        var passcode;
        setLoading(true);
        const result = await operatorsApi.login(id=user.id, id=user.id, passcode=password);
        if (result.ok){
            setLoading(false);
            logIn(result.data["access_token"]);
        } else {
            setLoading(false);
            return setLoginFailed(true);
        }
    }

    const sendResetEmail = async () => {
        setSendingEmail(true);

        let sendResetPasswordOTPResult;
        // make request to backend
        sendResetPasswordOTPResult = await usersApi.sendResetPasswordOTPEmail(user.id);
        if (!sendResetPasswordOTPResult.ok){
            setTimeout(async () => {
                result = await usersApi.sendResetPasswordOTPEmail(user.id);
            }, 1500)
        }
    
        if (sendResetPasswordOTPResult.ok) {
            navigation.navigate("Reset Password", { userId: user.id, email: user.email });
            setTimeout(function(){ 
                setSendingEmail(false);
            }, 500);
            return;
        }
        
        setLoading(false);
        setRequestFailed(true);
        alert("Error", 'Envio de Email Fracasado: ' + error.message);
    }

    return (
        <Screen style={styles.container}>
            <ScrollView>
            {!sendingEmail &&
            <>
            <AppText style={styles.title}>{user.name}</AppText>
            <AppText style={styles.subtitle}>{user.id}</AppText>
                <View style={styles.passwordContainer}>
                    <AppTextInput
                        autoCapitalize="none"
                        autoCorrect={false}
                        icon="lock"
                        name="passcode"
                        placeholder="Contraseña"
                        secureTextEntry
                        textContentType="password"
                        value={password}
                        onChangeText={(text) => {setPassword(text)}}
                    />
                    <AppText onPress={sendResetEmail} style={styles.forgotPasswordText}>Olvidé mi contraseña</AppText>
                </View>
                <ErrorMessage error={"Contraseña inválida"} visible={loginFailed}/>
                <AppButton onPress={handleSubmit} color ={"primary"} title="Ingresar" loading={loading} disabled={loading || !password || password.length == 0} />
            </>}
            </ScrollView>
            <ActivityIndicator visible={sendingEmail} source={require('../../assets/animations/circle-loader.json')}/>
        </Screen>
    );
}

export default PasswordScreen;