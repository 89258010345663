import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        alignSelf: "center",
        marginTop: 10,
        marginBottom: 40,
        width: 100,
        height: 100,
    },
})


export default styles;