import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";

import RegisterNavigator from "./RegisterNavigator";
import WelcomeScreen from "../screens/WelcomeScreen";
import LoginNavigator from './LoginNavigator';


const Stack = createStackNavigator();

const headerBackTitle = "Regresar";

const AuthNavigator = ({ initialUrl }) => (
    <Stack.Navigator screenOptions={{ headerTitle: "" }}>
        <Stack.Screen name="Welcome" component={WelcomeScreen} options={{ headerShown: false }} />
        <Stack.Screen name="LoginNavigator" component={LoginNavigator} options={{ headerBackTitle }}/>
        <Stack.Screen name="RegisterNavigator" component={RegisterNavigator} initialParams={{initialUrl: initialUrl }} options={{ headerBackTitle }}/>
    </Stack.Navigator>
);

export default AuthNavigator;