import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    checkboxContainer: {
        alignItems: "center",
        flexDirection: "row",
    },
    container: {
        padding: 10,
    },
    logo: {
        width: 1024/10,
        height: 1024/10,
        alignSelf: "center",
        marginTop: 50,
        marginBottom: 20,
    },
})

export default styles;