import React from 'react';
import { Image, ImageBackground, View } from 'react-native';
import SelectDropdown from 'react-native-select-dropdown';

import styles from './styles';
import AppButton from '../../components/AppButton'
import colors from '../../config/colors';
import AppText from '../../components/AppText';
import Icon from '../../components/Icon';


const countryOptions = [
    {
        "description": "Argentina",
        "value": "argentina"
    },
]


function InitialScreen({ navigation }) {
    return (
        <ImageBackground
            blurRadius={4}
            style={styles.background}
            source={require('../../assets/light-grey-background.png')}
        >
            <Image style={styles.logo} source={require("../../assets/icon-grey-square.png")}/>
            <View style={styles.dropdownContainer}>
                <AppText style={styles.text}>Con Pix Global, puedes recibir dinero vía Pix desde Brasil, sin comisiones, en los siguientes países:</AppText>
                <SelectDropdown
                    data={countryOptions}
                    defaultValue={"argentina"}
                    defaultButtonText={<AppText style={{'color': colors.medium}}>Argentina</AppText>}
                    buttonTextAfterSelection={(selectedItem) => {
                        return <AppText>{selectedItem.description}</AppText>
                    }}
                    rowTextForSelection={(item) => {
                        return <AppText>{item.description}</AppText>
                    }}
                    buttonStyle={styles.dropdown1BtnStyle}
                    buttonTextStyle={styles.dropdown1BtnTxtStyle}
                    renderDropdownIcon={isOpened => {
                        return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} color={colors.dark} backgroundColor='transparent' size={48} />;
                    }}
                    dropdownIconPosition={'right'}
                    dropdownStyle={styles.dropdown1DropdownStyle}
                    rowStyle={styles.dropdown1RowStyle}
                    rowTextStyle={styles.dropdown1RowTxtStyle}
                />
            </View>
            <View style={styles.buttonsContainer}>
                <AppButton title="Seguir" onPress={() => navigation.navigate("Auth Navigator")}/>
            </View>
        </ImageBackground>
    );
}

export default InitialScreen;