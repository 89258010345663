import styles from './styles';
import { View } from 'react-native';
import LottieView  from 'react-native-web-lottie';
//import LottieView from 'lottie-react-native';
import { useState } from 'react';
import AppText from '../../AppText';


function ExpiredIndicator({ innerRef, preferredQrCodeType }) {
    const [textVisible, setTextVisible] = useState(false);

    return (
        <View style={styles.container}>
            <LottieView
                ref={innerRef}
                autoPlay={false}
                loop={false}
                source={require('../../../assets/animations/expired.json')}
                onAnimationFinish={() => setTextVisible(true)}
                style={styles.container}
            />
            {preferredQrCodeType == 'static' && <AppText style={[styles.expiredDescription, {opacity: textVisible ? 1: 0}]}>Cobro expirado y fondos devueltos al cliente. Por favor generar uno nuevo con el monto total del cobro.</AppText>}
        </View>
    )
}

export default ExpiredIndicator;