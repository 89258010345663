import { StyleSheet } from 'react-native';

import colors from '../../config/colors';

const styles = StyleSheet.create({
    balanceContainer: {
        paddingHorizontal: 20,
    },
    balanceItem: {
        paddingHorizontal: 15,
        fontSize: 15,
    },
    balanceTitle: {
        fontSize: 20,
        fontWeight: "bold",
    },
    balanceOption: {
        fontWeight: "bold",
        paddingHorizontal: 15,
        color: "blue",
    },
    container: {
        marginVertical: 20,
    },
    divider: {
        height: 20,
    },
    screen: {
        backgroundColor: colors.light,
        flex: 1,
    }
})

export default styles;