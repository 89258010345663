import React from "react";
import { ActivityIndicator, View } from "react-native";

import AppText from "../AppText";
import styles from "./styles";
import colors from "../../config/colors";


function ExchangeCard({ title, subTitle, exchangeRateString, exchangeRateARSUSDCString = null, warning = false}) {
  return (
    <>
      <View style={styles.card}>
      <View style={styles.detailsContainer}>
          <View >
            <>
          {title && !warning &&
            <AppText style={styles.textRow} numberOfLines={1}>
              {title}
            </AppText>
          }
          { subTitle && !warning &&
            <AppText style={styles.textRow} numberOfLines={1}>
              {subTitle}
            </AppText>
          }
          { warning && <AppText style={styles.warning}>Solicitar al cliente que pague solo después de generar el cobro</AppText> }
            </>
            {! title && <ActivityIndicator size="small" color={colors.secondary} />}
          </View>
      </View>
      </View>
      <View>
        <AppText style={styles.exchangeRateString}>{exchangeRateString}</AppText>
        <AppText style={styles.exchangeRateARSUSDCString}>{exchangeRateARSUSDCString}</AppText>
      </View>
    </>
  );
}

export default ExchangeCard;
