import Constants from 'expo-constants';
import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    screen: {
        paddingBottom: Constants.statusBarHeight,
        flex: 1,
    },
    view: {
        flex: 1,
    }
})

export default styles;