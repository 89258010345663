import React, { useEffect, useState } from 'react';
import { Image, ScrollView, View } from 'react-native';
import CheckBox from 'expo-checkbox';

import styles from './styles'; 
import { ErrorMessage } from '../../components/forms';
import operatorsApi from '../../api/operators';
import usersApi from '../../api/users';
import { ContactUsIndicator } from '../../components/indicators';
import AppButton from '../../components/AppButton';
import AppTextInput from '../../components/AppTextInput';
import useAuth from '../../auth/useAuth';
import AppText from '../../components/AppText';
import Screen from '../../components/Screen';


function LoginScreen({ navigation }) {
    const { logIn } = useAuth();

    const [loginFailed, setLoginFailed] = useState(false);
    const [idValue, setIdValue] = useState(null);
    const [operatorIdValue, setOperatorIdValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    
    const handleSubmit = async () => {
        setLoading(true);
        if (operatorIdValue === null || !operatorIdValue || operatorIdValue.length === 0){
            const getUserResponse = await usersApi.get(idValue);
            if (!getUserResponse.status || getUserResponse.status !== 200){
                setLoading(false);
                setLoginFailed(true);
                return 
            } else {
                navigation.navigate("Password", {user: getUserResponse.data});
                setLoading(false);
            }
        } else {
            let id;
            let company_id;
            const result = await operatorsApi.login(
                id=operatorIdValue,
                company_id=idValue,
            );
            if (result.ok){
                logIn(result.data["access_token"]);
                setLoading(false);
            } else {
                setLoading(false);
                setLoginFailed(true);
            }
        }
    }

    useEffect(() => {
        if (isChecked == false){
            setOperatorIdValue(null)
        }
    }, [isChecked]);

    return (
        <Screen style={styles.container}>
            <Image style={styles.logo} source={require("../../assets/icon-transparent-square.png")} />
            <ScrollView>
                <AppTextInput
                    masked={true}
                    mask="99-99999999-9"
                    maxLength={13}
                    autoCapitalize="none"
                    autoCorrect={false}
                    icon="identifier"
                    keyboardType="numeric"
                    name="user_id"
                    placeholder="CUIT/CUIL"
                    defaultValue={idValue}
                    editable={true}
                    onChangeText={(text) => {setIdValue(text)}}
                />
                <View style={styles.checkboxContainer}>
                    <AppText style={{ textAlign: "right", flex: 0.8}}>ID de Operador/Sucursal ?</AppText>
                    <CheckBox
                        style={{ marginLeft: 10, padding: 2}}
                        onValueChange={()=>{
                            setIsChecked(!isChecked)
                        }}
                        value={isChecked}
                    >
                    </CheckBox>
                </View>
                <AppTextInput
                    visible={isChecked}
                    maxLength={5}
                    autoCapitalize="none"
                    autoCorrect={false}
                    icon="source-branch"
                    keyboardType="numeric"
                    name="operator_id"
                    placeholder="ID de Operador/Sucursal (Opcional)"
                    value={operatorIdValue}
                    onChangeText={(text) => {setOperatorIdValue(text)}}
                />
                <ErrorMessage error="Usuario inválido." visible={loginFailed}/>
                <AppButton title="Ingresar" loading={loading} disabled={loading} onPress={handleSubmit}/>
            </ScrollView>
            <ContactUsIndicator />
        </Screen>
    );
}

export default LoginScreen;