import React, { useEffect, useState } from 'react';
import { Pressable, TouchableOpacity, View } from 'react-native';
import AppText from '../../components/AppText';
import styles from './styles';
import Screen from '../../components/Screen';
import MessageModal from '../../components/MessageModal';
import colors from '../../config/colors';
import { useIsFocused } from '@react-navigation/native';
import useAuth from '../../auth/useAuth';
import usersApi from '../../api/users';
import operatorsApi from '../../api/operators';
import SelectDropdown from 'react-native-select-dropdown';
import Icon from '../../components/Icon';
import AppButton from '../../components/AppButton';
import QRRegisterModal from '../../components/QRRegisterModal';


function PreferencesScreen({ navigation }) { 

    const { user } = useAuth();

    const isFocused = useIsFocused();

    const [usdcAddressPresent, setUsdcAddressPresent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputCurrency, setInputCurrency] = useState();
    const [initialInputCurrency, setInitialInputCurrency] = useState();
    const [withdrawalFrequency, setWithdrawalFrequency] = useState();
    const [initialWithdrawalFrequency, setInitialWithdrawalFrequency] = useState();
    const [vaspDid, setVaspDid] = useState();
    const [cryptoBeneficiaryName, setCryptoBeneficiaryName] = useState();
    const [cryptoBeneficiaryType, setCryptoBeneficiaryType] = useState();
    const [bankAccount, setBankAccount] = useState();
    const [accountantsEmail, setAccountantsEmail] = useState();
    const [usdcAddress, setUsdcAddress] = useState();
    const [qrType, setQrType] = useState();
    const [qrRegisterModalVisible, setQrRegisterModalVisible] = useState(false);
    const [initialQrType, setInitialQrType] = useState();
    const [qrKey, setQrKey] = useState(null);

    const currencyOptions = [
        "ARS", "USDC" //BRL
    ]

    const frequencyOptions = [
        {"key": "instant", "value": "Retiros Instantáneos"},
        {"key": "end_of_day", "value": "Retiros al Fin del Dia"},
        {"key": "keep", "value": "Mantener el Saldo"},
    ]

    const qrTypeOptions = [
        {"key": "dynamic", "value": "Dinámico (en pantalla)"},
        {"key": "static", "value": "Estático (impreso)"}, 
    ]

    useEffect(() => {
        setLoading(true);
        if (user.type == 'master'){
            usersApi.get(user.id)
            .then((res) => {
                setVaspDid(res.data.vasp_did);
                setCryptoBeneficiaryName(res.data.crypto_beneficiary_name);
                setCryptoBeneficiaryType(res.data.crypto_beneficiary_type);
                setBankAccount(res.data.bank_account);
                setAccountantsEmail(res.data.accountants_email);
                setUsdcAddress(res.data.usdc_address);
                setInitialInputCurrency(res.data.preferred_withdrawal_currency);
                setInputCurrency(res.data.preferred_withdrawal_currency);
                if(res.data.usdc_address) {
                    setUsdcAddressPresent(true);
                };
                setInitialWithdrawalFrequency(res.data.withdrawal_frequency);
                setWithdrawalFrequency(res.data.withdrawal_frequency);
                setLoading(false);
            })
            .catch((error) => console.log(error));
            operatorsApi.get(user.id, user.id)
            .then((res) => {
                setInitialQrType(res.data.preferred_qr_code_type);
                setQrType(res.data.preferred_qr_code_type);
                setQrKey(res.data.qr_key);
                setLoading(false);
            })
            .catch((error) => console.log(error));
        } else {
            operatorsApi.get(user.id, user.company_id)
            .then((res) => {
                setInitialQrType(res.data.preferred_qr_code_type);
                setQrType(res.data.preferred_qr_code_type);
                setQrKey(res.data.qr_key);
                setLoading(false);
            })
            .catch((error) => console.log(error));
        }
    }, [isFocused]);

    // modal
    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [messageModalMessageType, setMessageModalMessageType] = useState('');
    const [messageModalHeaderText, setMessageModalHeaderText] = useState('');
    const [messageModalMessage, setMessageModalMessage] = useState('');
    const [messageModalButtonText, setMessageModalButtonText] = useState('');

    const showMessageModal = (type, headerText, message, buttonText) => {
        setMessageModalMessageType(type);
        setMessageModalHeaderText(headerText);
        setMessageModalMessage(message);
        setMessageModalButtonText(buttonText);
        setMessageModalVisible(true);
    };

    const setNewQr = (data) => {
        setQrKey(data);
    }

    const buttonHandler = async () => {
        if (messageModalMessageType === 'advanceConfirmation') {
            navigation.navigate("Información Cripto (USDC)");
        }
        setMessageModalVisible(false);
    };

    const outsidePressHandler = () => {
        setMessageModalVisible(false);
    }

    const qrRegisterOutsidePressHandler = () => {
        setQrRegisterModalVisible(false);
        setQrType('dynamic');
    }

    const outsidePressHandlerQRFixed = () => {
        setQrRegisterModalVisible(false);
        user.type == 'master' ? handleCompanyPreferencesUpdate() : handleOperatorPreferencesUpdate()
    }


    const handleCompanyPreferencesUpdate = async () => {
        setLoading(true);
        if (!usdcAddressPresent && (withdrawalFrequency == "end_of_day" || withdrawalFrequency == "instant") && inputCurrency == "USDC"){
            setLoading(false);
            return showMessageModal('advanceConfirmation', '¿Quieres Recibir en USDC?', 'Primero debes registrar una dirección USDC de la red SOLANA (exclusivamente). \n\nSugerimos crear una cuenta en Binance. \n\nSi tienes dificultades, no dude en contactarnos en hola@pixglobal.io.', 'Sí, Registrar Mi Dirección');
        } else if (withdrawalFrequency !== "keep" && inputCurrency == "BRL"){
            setLoading(false);
            return showMessageModal('failed', '¡Retiros programados de BRL no disponibles!', "No se puede hacer retiros programados de BRL.", 'Cerrar');
        }
        const result = await usersApi.update(user.id, {
            "vasp_did": vaspDid,
            "crypto_beneficiary_name": cryptoBeneficiaryName,
            "crypto_beneficiary_type": cryptoBeneficiaryType,
            "bank_account": bankAccount,
            "accountants_email": accountantsEmail,
            "usdc_address": usdcAddress,
            "preferred_withdrawal_currency": inputCurrency,
            "withdrawal_frequency": withdrawalFrequency,
            "preferred_qr_code_type": qrType,
        });

        if (!result.ok) {
            setLoading(false);
            return showMessageModal('failed', '¡Error!', "Ocurrió un error inesperado.", 'Cerrar');
        }
        setInitialInputCurrency(inputCurrency);
        setInitialWithdrawalFrequency(withdrawalFrequency);
        setInitialQrType(qrType);
        setLoading(false);
        return showMessageModal('success', '¡Todo está bien!', 'Su preferencia ha sido actualizada.', 'Ok');
    };

    const handleOperatorPreferencesUpdate = async () => {
        setLoading(true);
        const result = await operatorsApi.updateOperator(user.company_id, user.id, {
            "preferred_qr_code_type": qrType,
        });

        if (!result.ok) {
            setLoading(false);
            return showMessageModal('failed', '¡Error!', "Ocurrió un error inesperado.", 'Cerrar');
        }
        setInitialQrType(qrType);
        setLoading(false);
        return showMessageModal('success', '¡Todo está bien!', 'Su preferencia ha sido actualizada.', 'Ok');
    };

    return (
        <Screen style={styles.screen}>
            {user.type == 'master' && <><AppText style={styles.textRow}>Moneda de retiro estándar:</AppText>
            <SelectDropdown
                data={currencyOptions}
                onSelect={(selectedItem) => {
                    setInputCurrency(selectedItem)
                }}
                defaultValue={inputCurrency}
                buttonTextAfterSelection={(selectedItem) => {
                    return <AppText>{selectedItem}</AppText>
                }}
                rowTextForSelection={(item) => {
                    return <AppText>{item}</AppText>
                }}
                buttonStyle={styles.dropdown1BtnStyle}
                buttonTextStyle={styles.dropdown1BtnTxtStyle}
                renderDropdownIcon={isOpened => {
                return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} iconColor={colors.dark} backgroundColor='transparent' size={48} />;
                }}
                dropdownIconPosition={'right'}
                dropdownStyle={styles.dropdown1DropdownStyle}
                rowStyle={styles.dropdown1RowStyle}
                rowTextStyle={styles.dropdown1RowTxtStyle}
            />
            {/* <View style={styles.divider}></View>
            <AppText style={styles.textRow}>Frecuencia de retiro:</AppText>
            <SelectDropdown
                data={frequencyOptions}
                onSelect={(selectedItem) => {
                    setWithdrawalFrequency(selectedItem.key)
                }}
                defaultValue={frequencyOptions.find(item => item.key === withdrawalFrequency)}
                buttonTextAfterSelection={(selectedItem) => {
                    return <AppText>{selectedItem.value}</AppText>
                }}
                rowTextForSelection={(item) => {
                    return <AppText>{item.value}</AppText>
                }}
                buttonStyle={styles.dropdown1BtnStyle}
                buttonTextStyle={styles.dropdown1BtnTxtStyle}
                renderDropdownIcon={isOpened => {
                return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} iconColor={colors.dark} backgroundColor='transparent' size={48} />;
                }}
                dropdownIconPosition={'right'}
                dropdownStyle={styles.dropdown1DropdownStyle}
                rowStyle={styles.dropdown1RowStyle}
                rowTextStyle={styles.dropdown1RowTxtStyle}
            /> */}
            <View style={styles.divider}></View></>}
            <AppText style={styles.textRow}>Tipo de QR:</AppText>
            <SelectDropdown
                data={qrTypeOptions}
                onSelect={(selectedItem) => {
                    setQrType(selectedItem.key)
                    if (selectedItem.key == 'static' && initialQrType == 'dynamic' && !qrKey){
                        setQrRegisterModalVisible(true);
                    }
                }}
                defaultValue={qrTypeOptions.find(item => item.key === qrType)}
                buttonTextAfterSelection={(selectedItem) => {
                    return <AppText>{selectedItem.value}</AppText>
                }}
                rowTextForSelection={(item) => {
                    return <AppText>{item.value}</AppText>
                }}
                buttonStyle={styles.dropdown1BtnStyle}
                buttonTextStyle={styles.dropdown1BtnTxtStyle}
                renderDropdownIcon={isOpened => {
                return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} iconColor={colors.dark} backgroundColor='transparent' size={48} />;
                }}
                dropdownIconPosition={'right'}
                dropdownStyle={styles.dropdown1DropdownStyle}
                rowStyle={styles.dropdown1RowStyle}
                rowTextStyle={styles.dropdown1RowTxtStyle}
            />
            {qrKey && <AppText style={styles.keyText}>Clave QR Estático: {qrKey} - <AppText onPress={()=>{setQrRegisterModalVisible(true)}} style={[styles.keyText, {color: colors.secondary}]}>Cambiar?</AppText></AppText>}
            <View style={styles.divider}></View>
            <AppButton color = "primary" title="Guardar" loading={false} disabled={user.type == 'master' ? (!withdrawalFrequency || loading || (inputCurrency == initialInputCurrency && withdrawalFrequency == initialWithdrawalFrequency && qrType == initialQrType)) : (qrType == initialQrType) } onPress={user.type == 'master' ? handleCompanyPreferencesUpdate : handleOperatorPreferencesUpdate}/>
            <MessageModal
                modalVisible={messageModalVisible}
                buttonHandler={buttonHandler}
                outsidePressHandler={outsidePressHandler}
                type={messageModalMessageType}
                headerText={messageModalHeaderText}
                message={messageModalMessage}
                buttonText={messageModalButtonText}
            />
            <QRRegisterModal
                qrRegisterModalVisible={qrRegisterModalVisible}
                outsidePressHandler={qrRegisterOutsidePressHandler}
                outsidePressHandlerQRFixed={outsidePressHandlerQRFixed}
                companyId={user.type == 'master' ? user.id : user.company_id}
                operatorId={user.id}
                setNewQr={setNewQr}
            />
        </Screen>
    );
}

export default PreferencesScreen;