import { StyleSheet } from 'react-native';

import colors from '../../config/colors';


const styles = StyleSheet.create({
    card: {
      alignSelf: "center",
      borderWidth: 2,
      borderColor: colors.black,
      borderRadius: 15,
      backgroundColor: colors.white,
      marginBottom: 10,
      width: "85%",
    },
    detailsContainer: {
      padding: 15,
    },
    textRow: {
      fontSize: 15,
      color: colors.secondary,
      fontWeight: "bold",
      textAlign: "center",
    },
    exchangeRateString:{
      fontSize: 12,
      fontWeight: "bold",
      textAlign: 'center',
    },
    exchangeRateARSUSDCString:{
      fontSize: 12,
      fontWeight: "bold",
      textAlign: 'center',
      color: colors.pending,
    },
    warning: {
      fontSize: 12,
      fontWeight: "bold",
      textAlign: 'center',
      color: colors.danger,
    }
  });


export default styles;