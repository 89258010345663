import React, { useState } from "react";
import { Platform, TextInput, View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { MaskedTextInput } from "react-native-mask-text";


import defaultStyles from '../../config/styles';
import styles from "./styles";
import GradientTextInput from '../GradientTextInput';


function AppTextInput({icon, masked, editable, textSize = 18, selectionFromStart=false, faded=false, visible=true, button=null, half=false, dropdown=null, ...otherProps}){
    const [selection, setSelection] = useState(selectionFromStart ? {start: 0} : null);

    const onFocus = () => {
        setSelection(null)
    }

    if (!visible) {
        return
    }

    if (faded) {
        return (
            <View style={[styles.container, (!editable && !dropdown) && {backgroundColor: defaultStyles.colors.disabled }, half && {width: "50%"}]}>
                    {icon && <MaterialCommunityIcons name={icon} size={20} color={defaultStyles.colors.medium} style={styles.icon} />}
                    {masked ? <MaskedTextInput selection={selection} onFocus={onFocus} editable={editable} style={[defaultStyles.text, {flex:1, fontSize: textSize}]} {...otherProps}/> : <GradientTextInput selection={selection} onFocus={onFocus} editable={editable} style={[defaultStyles.text, Platform.OS === 'web' && {fontSize: textSize}]} {...otherProps}/>}
            </View>
        )
    }
    else {
        return (
            <View style={[styles.container, (!editable && !dropdown) && {backgroundColor: defaultStyles.colors.disabled }, half && {width: "50%"}]}>
                {icon && <MaterialCommunityIcons name={icon} size={20} color={defaultStyles.colors.medium} style={styles.icon} />}
                {dropdown}
                {masked ? <MaskedTextInput selection={selection} onFocus={onFocus} editable={editable} style={[defaultStyles.text, {flex:1, fontSize: textSize}, (!editable && dropdown) && {color: "gray"}]} {...otherProps}/> : <TextInput selection={selection} onFocus={onFocus} editable={editable} style={[defaultStyles.text, {flex:1, fontSize: textSize}]} {...otherProps}/>}
                {button}
            </View>
        )
    }
}

export default AppTextInput;