import client from './client';
import bufferClient from './bufferClient';
import { Platform } from 'react-native';

const addPushToken = (companyId, id, addPushTokenInput) => client.post(`/operator/${companyId}/${id}/push-token`, addPushTokenInput);
const createOperator = (createOperatorInput) => client.post('/operator', createOperatorInput);
const deleteOperator = (companyId, id) => client.delete(`/operator/${companyId}/${id}`);
const getCompanyOperators = (companyId, pageSize, nextPage) => client.get(`/operator/${companyId}?page_size=${pageSize}` + (nextPage ? (`&next_page=${encodeURIComponent(nextPage)}`) : ''));
const login = (id, company_id, passcode = null) => client.post('/operator/session', {id, company_id, passcode});
const get = (id, companyId) => client.get(`/operator/${companyId}/${id}`);
const updateOperator = (companyId, id, updateOperatorInput) => client.patch(`/operator/${companyId}/${id}`, updateOperatorInput);

let closeLot = null;
if (Platform.OS == "web"){
    closeLot = (operatorId, companyId, closeLotInput) => bufferClient.patch(`/operator/${companyId}/${operatorId}/lot`, closeLotInput);
} else {
    closeLot = (operatorId, companyId, closeLotInput) => client.patch(`/operator/${companyId}/${operatorId}/lot`, closeLotInput);
}


export default {
    addPushToken,
    createOperator,
    deleteOperator,
    getCompanyOperators,
    get,
    login,
    closeLot,
    updateOperator,
};