import { Dimensions, Platform } from 'react-native';
import { StyleSheet } from 'react-native';

const dimensions = Dimensions.get('window');


const styles = StyleSheet.create({
    container:{
        alignSelf: "center",
        marginTop: Platform.OS == "web" ? -100 : -10,
        height: 150,
        width: 150,
    },
    expiredDescription: {
        marginTop: Math.min(dimensions.height, dimensions.width) / 10,
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "center",
    },
})


export default styles;