import React from 'react';
import { Image, ImageBackground, Linking, TouchableOpacity, View } from 'react-native';

import styles from './styles';
import AppButton from '../../components/AppButton'
import Icon from '../../components/Icon';
import colors from '../../config/colors';
import AppText from '../../components/AppText';


function WelcomeScreen({ navigation }) {
    return (
        <ImageBackground
            blurRadius={4}
            style={styles.background}
            source={require('../../assets/light-grey-background.png')}
        >
            <Image style={styles.logo} source={require("../../assets/icon-grey-square-argentina.png")}/>
            <View style={styles.buttonsContainer}>
                <AppButton title="Ingresá" onPress={() => navigation.navigate("LoginNavigator")}/>
                <AppButton title="Creá tu cuenta" color = "secondary" onPress={() => navigation.navigate("RegisterNavigator")}/>
            </View>
            <View style={styles.footerContainer}>
                <TouchableOpacity onPress={() => Linking.openURL('https://pixglobal.io/pol%C3%ADtica-de-privacidad')}>
                    <View style={styles.footerLink}>
                        <Icon name="archive-lock" backgroundColor={colors.lightGrey} iconColor={colors.primary} size={50} style={styles.icon}/>
                        <AppText style={styles.iconText}>Política de Privacidad</AppText>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => Linking.openURL('https://pixglobal.io/t%C3%A9rminos-y-condiciones')}>
                    <View style={styles.footerLink}>
                        <Icon name="file-document" backgroundColor={colors.lightGrey} iconColor={colors.primary} size={50} style={styles.icon}/>
                        <AppText style={styles.iconText}>Términos y Condiciones</AppText>
                    </View>
                </TouchableOpacity>
            </View>
        </ImageBackground>
    );
}

export default WelcomeScreen;