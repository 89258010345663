import React from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";

import colors from "../../config/colors";
import styles from './styles';

function AppButton({ title, onPress, color = "primary", disabled=false, loading=false, icon=null }) {
  return (
    <TouchableOpacity
      style={[styles.button, { backgroundColor: disabled ? colors.disabled : colors[color] }]}
      onPress={onPress}
      disabled={disabled}
    >
      {loading ? <ActivityIndicator size="small" color={colors.secondary} /> : <View style={{flexDirection: 'row', alignItems: 'center'}}>{icon}<Text style={styles.text}>{title}</Text></View>}
    </TouchableOpacity>
  );
}


export default AppButton;
