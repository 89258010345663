import { StyleSheet } from 'react-native';
import colors from '../../config/colors';

const styles = StyleSheet.create({
    deleteButton: {
        color: colors.danger,
    },
    text: {
        fontWeight: "bold",
        marginTop: 5,
        textAlign: 'center',
    },
    screen: {
        flex: 1,
    }
})

export default styles;