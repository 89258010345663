import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";


import EmailVerificationScreen from '../screens/EmailVerificationScreen';
import RegisterScreen from '../screens/RegisterScreen/RegisterScreen';


const Stack = createStackNavigator();


const RegisterNavigator = ({ route }) => (
    <Stack.Navigator initialRouteName="Registerscreen"> 
        <Stack.Screen name="Register" component={RegisterScreen} initialParams={{initialUrl: route.params.initialUrl}} options={{ headerShown: false }}/>
        <Stack.Screen name="Email Verification" component={EmailVerificationScreen} options={{ headerShown: false }}/>
    </Stack.Navigator>
);

export default RegisterNavigator;