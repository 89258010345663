import client from './client';

const FIVE_MINUTES_S = 300;
const SIX_HOURS_S = 21600;
const PIX_DESCRIPTION = "Pagamento GlobalPix";

const addCharge = (userId, operatorId, amountDueBRL, amountDueARS, amountUSDC, paymentType, payoutMethod, chargeType, referenceId = null) => {
    const data = { 
        "company_id": userId,
        "operator_id": operatorId,
        "calendario": {
          "expiracao": paymentType === "long_term_charge" ? SIX_HOURS_S : FIVE_MINUTES_S
        },
        "devedor": null,
        "value_source": {
          "original": amountDueBRL
        },
        "value_destination": amountDueARS,
        "value_destination_usdc": amountUSDC,
        "solicitacaoPagador": PIX_DESCRIPTION,
        "payment_type": paymentType,
        "payout_method": payoutMethod,
        "reference_id": referenceId,
        "charge_type": chargeType,
    }
    console.log(data);
    return client.post("/inter/charge", data);
}

const expireCharge = (txid) => {
  return client.patch(`/inter/charge/${txid}/expire`);
}

const getCharge = (txid) => {
  return client.get(`/inter/charge/${txid}`);
}

const createRefund = (txid, payerId, refundReason) => {
  const data = {
    "txid": txid,
    "payer_id": payerId,
    "refund_reason": refundReason,
  };
  return client.post("/inter/refund", data)
}

const parseQrCode = (emv) => {
  const data = {
    "emv": emv
  };
  return client.post("/inter/qrcode/parse", data)
}

const getPixInformation = (pixKey) => {
  return client.get(`/inter/key/${pixKey}`);
}

const payout = (companyId, amount, valuePaid, currencyPaid, receiverName, destinationPixKey = null, paymentEmv=null) => {
  const data = {
    "company_id": companyId,
    "amount": amount.toFixed(2),
    "value_paid": valuePaid,
    "currency_paid": currencyPaid,
    "receiver_name": receiverName,
    "destination_pix_key": destinationPixKey,
    "emv": paymentEmv,
  };
  return client.post("/inter/payout", data);
}

export default {
    addCharge,
    expireCharge,
    getCharge,
    createRefund,
    parseQrCode,
    getPixInformation,
    payout,
};