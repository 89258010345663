import React, { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import colors from '../../config/colors';
import Screen from '../../components/Screen';
import { ListItem } from '../../components/lists';
import styles from './styles';
import Icon from '../../components/Icon';
import useAuth from '../../auth/useAuth';
import usersApi from '../../api/users';
import conversionApi from '../../api/conversion';
import MessageModal from '../../components/MessageModal';
import { ActivityIndicator } from '../../components/indicators';
import OTPModal from '../../components/OTPModal';
import AppText from '../../components/AppText';
import DepositOptionsModal from '../../components/DepositOptionsModal';
import WithdrawalOptionsModal from '../../components/WithdrawalOptionsModal';
import ConversionModal from '../../components/ConversionModal';


function AccountScreen({ navigation }) {
    const { user, logOut } = useAuth();
    const [loading, setLoading] = useState(false);
    const [balanceArs, setBalanceArs] = useState(null);
    const [balanceBrl, setBalanceBrl] = useState(null);
    const [balanceUsdc, setBalanceUsdc] = useState(null);
    const [count, setCount] = useState(0);

    const [exchangeRateBrlArs, setExchangeRateBrlArs] = useState(null);
    const [exchangeRateBrlUsdc, setExchangeRateBrlUsdc] = useState(null);
    const [exchangeRateArsUsdc, setExchangeRateArsUsdc] = useState(null);

    // code input
    const MAX_CODE_LENGTH = 4;
    const [code, setCode] = useState('');
    const [pinReady, setPinReady] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);

    // OTP modal
    const [otpModalVisible, setOtpModalVisible] = useState(false);

    // Message modal
    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [messageModalMessageType, setMessageModalMessageType] = useState('');
    const [messageModalHeaderText, setMessageModalHeaderText] = useState('');
    const [messageModalMessage, setMessageModalMessage] = useState('');
    const [messageModalButtonText, setMessageModalButtonText] = useState('');

    // Conversion modal
    const [conversionModalVisible, setConversionModalVisible] = useState(false);

    // Deposit Options modal
    const [depositOptionsModalVisible, setDepositOptionsModalVisible] = useState(false);

    // Withdrawal Options modal
    const [withdrawalOptionsModalVisible, setWithdrawalOptionsModalVisible] = useState(false);

    const messageModalButtonHandler = async () => {
        if (messageModalMessageType === 'success'){
            await usersApi.deleteUser(user.id);
            logOut();
        }
        setMessageModalVisible(false);
    }

    const showMessageModal = (type, headerText, message, buttonText) => {
        setMessageModalMessageType(type);
        setMessageModalHeaderText(headerText);
        setMessageModalMessage(message);
        setMessageModalButtonText(buttonText);
        setMessageModalVisible(true);
    };


    const showModal = (type, headerText, message, buttonText) => {
        setMessageModalMessageType(type);
        setMessageModalHeaderText(headerText);
        setMessageModalMessage(message);
        setMessageModalButtonText(buttonText);
        setMessageModalVisible(true);
    };

    const MINUTE_SECONDS_MS = 60000;

    let timerId;
  
    const clear = () => {
        clearTimeout(timerId);
    }
  
    useEffect(() => {
        if (count == 10){
            showModal('idleNotification', 'Aplicación Inactiva', 'La aplicación ha estado inactiva durante 10 minutos, por lo que hemos pausado la actualización automática de precios.', 'Ok, Ya Estoy de Vuelta');
            return clear();
        }
        conversionApi.getConversionRate()
            .then((res) => {
                const exchangeRate = res.data.payout.exchange_rate.toFixed(2);
                const exchangeRateBRLUSDC = res.data.payout.exchange_rate_usdc.toFixed(2);
                const exchangeRateARSUSDC = res.data.payout.exchange_rate_ars_usdc.toFixed(2);
                setExchangeRateBrlArs(exchangeRate);
                setExchangeRateBrlUsdc(exchangeRateBRLUSDC);
                setExchangeRateArsUsdc(exchangeRateARSUSDC);
                timerId = setTimeout(() => {setCount(count + 1)}, MINUTE_SECONDS_MS);
            })
            .catch((error) => console.log(error));

        const companyId = user.type == 'master' ? user.id : user.company_id;
        usersApi.get(companyId)
        .then((res) => {
            setBalanceArs(res.data.balance_ars);
            setBalanceBrl(res.data.balance_brl);
            setBalanceUsdc(res.data.balance_usdc);
        })
        .catch((error) => console.log(error));
        return clear();
    }, [count, conversionModalVisible]);

    // const handleOtpModalShow = async () => {
    //     setLoading(true);
    //     let sendDeleteConfirmOTPResult;
    //     // make request to backend
    //     sendDeleteConfirmOTPResult = await usersApi.sendDeleteConfirmOTPEmail(user.id);
    //     if (!sendDeleteConfirmOTPResult.ok){
    //         setTimeout(async () => {
    //             sendDeleteConfirmOTPResult = await usersApi.sendDeleteConfirmOTPEmail(user.id);
    //         }, 1500)
    //     }

    //     if (sendDeleteConfirmOTPResult.ok) {
    //         setOtpModalVisible(true);
    //         setLoading(false);
    //         return
    //     }
    //     alert("Error", 'Inténtelo de nuevo en breve.');
    //     setLoading(false);
    // }

    const handleSubmit = async () => {
        setLoading(true);
        const result = await usersApi.deleteUser(user.id, {"otp": code} );

        if (!result.ok) {
            setLoading(false);
            onCancelPress();
            setOtpModalVisible(false);
            return showMessageModal('failed', '¡Error!', result.data ? "PIN inválido." : "Ocurrió un error inesperado.", 'Cerrar');
        }
        setLoading(false);
        setOtpModalVisible(false);
        setCode('');
        return showMessageModal('success', '¡Todo está bien!', 'Su cuenta ha sido cancelada. Todos los pagos pendientes serán procesados.', 'Ok');
    };

    const onCancelPress = () => {
        setOtpModalVisible(false);
        setCode('');
    }

    useEffect(() => {
        // toggle pinReady
        if (code.length < MAX_CODE_LENGTH) {
            setInvalidPin(false);
        }
        setPinReady(code.length === MAX_CODE_LENGTH);
        return () => setPinReady(false);
    }, [code]);


    return (
        <Screen style={styles.screen}>
            {!loading && 
            <ScrollView>
                <View style={styles.container}>
                    <ListItem
                        title={ user.name }
                        description={user.type == 'master' ? user.id : `Em nombre de ${user.company_id}`}
                        IconComponent={
                            <Icon name="store" size={60}/>    
                        }
                    />
                </View>
                {/* {user.type == 'master' && <><View style={styles.balanceContainer}>
                    {(exchangeRateArsUsdc && exchangeRateBrlArs && exchangeRateBrlUsdc) ? <AppText style={styles.balanceTitle}>Saldo - {(balanceUsdc + (balanceBrl / exchangeRateBrlUsdc) + (balanceArs / exchangeRateArsUsdc)).toFixed(2)} USD</AppText> : <AppText style={styles.balanceTitle}>Saldo - ... USD</AppText>}
                    {(exchangeRateArsUsdc && exchangeRateBrlArs && exchangeRateBrlUsdc) ? <AppText style={styles.balanceItem}>{balanceBrl} BRL</AppText> : <AppText style={styles.balanceItem}>... BRL</AppText>} 
                    {(exchangeRateArsUsdc && exchangeRateBrlArs && exchangeRateBrlUsdc) ? <AppText style={styles.balanceItem}>{balanceArs} ARS</AppText> : <AppText style={styles.balanceItem}>... BRL</AppText>} 
                    {(exchangeRateArsUsdc && exchangeRateBrlArs && exchangeRateBrlUsdc) ? <AppText style={styles.balanceItem}>{balanceUsdc} USDC</AppText> : <AppText style={styles.balanceItem}>... USDC</AppText>} 
                </View>
                <View style={styles.divider}></View>
                <View style={{flexDirection:"row", alignSelf: "center"}}>
                    <TouchableOpacity onPress={() => setDepositOptionsModalVisible(true)}>
                        <AppText style={styles.balanceOption}>Depositar</AppText> 
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => setConversionModalVisible(true)}>
                        <AppText style={styles.balanceOption}>Convertir</AppText> 
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => setWithdrawalOptionsModalVisible(true)}>
                        <AppText style={styles.balanceOption}>Retirar</AppText> 
                    </TouchableOpacity>
                </View></> 
                }*/}
                <View style={styles.container}>
                    {user.type == 'master' &&
                    <ListItem
                        title="Información Bancária (ARS)"
                        IconComponent={
                            <Icon name="bank" backgroundColor={colors.primary}/>
                        }
                        SecondaryIconComponent={
                            <Icon name="lock" iconColor={colors.black} backgroundColor={colors.white}/>
                        }
                        onPress={() => navigation.navigate("Información Bancária (ARS)")}
                    />}
                    {user.type == 'master' &&
                    <ListItem
                        title="Información Cripto (USDC)"
                        IconComponent={
                            <Icon name="hand-coin" backgroundColor={colors.pending}/>
                        }
                        SecondaryIconComponent={
                            <Icon name="lock" iconColor={colors.black} backgroundColor={colors.white}/>
                        }
                        onPress={() => navigation.navigate("Información Cripto (USDC)")}
                    />}
                    {user.type == 'master' &&
                    <ListItem
                        title="Operadores"
                        IconComponent={
                            <Icon name="human" backgroundColor={colors.argentina}/>
                        }
                        onPress={() => navigation.navigate("Operadores")}
                    />}
                    <ListItem
                        title="Preferencias"
                        IconComponent={
                            <Icon name="cog" backgroundColor={colors.darkGrey}/>
                        }
                        onPress={() => navigation.navigate("Preferencias")}
                    />
                    <ListItem
                        title="Soporte y Ayuda"
                        IconComponent={
                            <Icon name="help" backgroundColor={"red"}/>
                        }
                        onPress={() => navigation.navigate("Soporte y Ayuda")}
                    />
                </View>
                <View style={styles.container}>
                    <ListItem
                        title="Cerrar Sesión"
                        IconComponent={
                            <Icon name="logout" backgroundColor={colors.secondary}/>
                        }
                        onPress={() => logOut()}
                    />
                    {/* {user.type == 'master' && <ListItem
                        title="Eliminar Cuenta Permanentemente"
                        titleColor = {colors.danger}
                        IconComponent={
                            <Icon name="delete" backgroundColor={colors.danger}/>
                        }
                        onPress={handleOtpModalShow}
                    />} */}
                </View>
                <View style={styles.divider} />
            </ScrollView>}
            <OTPModal
                modalVisible={otpModalVisible}
                onCancelPress={onCancelPress}
                onConfirmPress={handleSubmit}
                color={"danger"}
                user={user}
                code={code}
                setCode={setCode}
                pinReady={pinReady}
                invalidPin={invalidPin}
                MAX_CODE_LENGTH={MAX_CODE_LENGTH} 
                loading={loading}
            />
            <MessageModal
                modalVisible={messageModalVisible}
                buttonHandler={messageModalButtonHandler}
                outsidePressHandler={messageModalButtonHandler}
                type={messageModalMessageType}
                headerText={messageModalHeaderText}
                message={messageModalMessage}
                buttonText={messageModalButtonText}
            />
            <DepositOptionsModal
                modalVisible={depositOptionsModalVisible}
                outsidePressHandler={() => setDepositOptionsModalVisible(false)}
            />
            <WithdrawalOptionsModal
                modalVisible={withdrawalOptionsModalVisible}
                outsidePressHandler={() => setWithdrawalOptionsModalVisible(false)}
            />
            <ConversionModal
                modalVisible={conversionModalVisible}
                outsidePressHandler={() => setConversionModalVisible(false)}
            />
            <ActivityIndicator visible={loading} source={require('../../assets/animations/circle-loader.json')}/>
        </Screen>
    );
}

export default AccountScreen;