import React from 'react';
import { Modal, Pressable, View } from 'react-native';
import * as Clipboard from 'expo-clipboard';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import AppButton from '../AppButton';
import { TouchableOpacity } from 'react-native';
import AppTextInput from '../AppTextInput';


const DOSAM_CBU = '3220001805020028920013';

const copyToClipboard = async () => {
    await Clipboard.setStringAsync(DOSAM_CBU);
};

const DepositModal = ({ modalVisible, outsidePressHandler }) => {

  return (
    <Modal animationType="slide" visible={modalVisible} transparent={false}>
      <Pressable style={styles.container} onPressOut={outsidePressHandler}>
        <View style={styles.modal}>
          <AppText style={{ fontSize: 20, color: colors.white, marginTop: 10, marginBottom: 20, textAlign: 'center' }}>Instrucciones para Depósito</AppText>
          <AppText style={{fontSize: 14, color: colors.white, marginBottom: 10, textAlign: 'center', fontWeight: 'bold', color: "white" }}>Deposite cualquier importe en pesos en el siguiente CBU. Luego de la acreditación en nuestra cuenta, el monto se agregará al saldo Pix Global en pesos.</AppText>
          <TouchableOpacity style={{alignSelf: "stretch"}} onPress={copyToClipboard}>
            <AppTextInput textSize={15} icon="content-copy" editable={false} value={DOSAM_CBU}/> 
          </TouchableOpacity>
          <AppText style={{fontSize: 12, color: colors.white, marginBottom: 2, textAlign: 'center' }}>Banco Industrial</AppText>
          <AppText style={{fontSize: 12, color: colors.white, marginBottom: 20, textAlign: 'center' }}>Beneficiario: DOSAM 15 S.A.</AppText>
          <AppText style={{fontSize: 14, color: colors.white, marginBottom: 10, textAlign: 'center', fontWeight: 'bold', color: "red" }}>Deposite desde una cuenta con el mismo CUIT o CUIL que su cuenta Pix Global. No aceptamos depósitos de terceros!</AppText>
          <AppButton onPress={outsidePressHandler} title={"OK"} color="blue"/>
        </View>
      </Pressable>
    </Modal>
  );
};

export default DepositModal;