import { StyleSheet } from 'react-native';
import colors from '../../config/colors';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 25,
        backgroundColor: colors.white,
        opacity: 1.0,
        justifyContent: "center",
        alignItems: "center",
    },
    modal: {
        backgroundColor: colors.dark,
        borderRadius: 20,
        width: "100%",
        padding: 35,
        alignItems: "center",
        elevation: 5,
        shadowColor: "black",
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.25,
        shadowRadius: 4,
    }
})


export default styles;