import { Dimensions, Platform, StyleSheet } from 'react-native';
import colors from '../../config/colors';

const screenWidth = Dimensions.get('screen').width;


const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 25,
        backgroundColor: colors.white,
        opacity: 0.9,
        justifyContent: "center",
        alignItems: "center",
    },
    buttonsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        gap: screenWidth / 20,
    },
    modal: {
        backgroundColor: colors.dark,
        borderRadius: 20,
        width: "100%",
        padding: 15,
        alignItems: "center",
        elevation: 5,
        shadowColor: "black",
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.25,
        shadowRadius: 4,
    },
    cell: {
        width: 40,
        height: 40,
        lineHeight: 38,
        borderWidth: 2,
        borderColor: colors.mediumGrey,
        textAlign: 'center',
        margin: Platform.OS === "web" ? 1 : (screenWidth * 0.05),
        color: colors.white,
    },
    codeFieldRoot: {
        color: colors.lightGrey,
        margin: 20,
    },
    focusCell: {
        borderColor: colors.white,
    }
})


export default styles;