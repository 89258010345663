import React, { useEffect, useState } from 'react';
import { Modal, Pressable, View } from 'react-native';
import * as Clipboard from 'expo-clipboard';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import { TouchableOpacity } from 'react-native';
import CountryFlag from 'react-native-country-flag';
import DepositModal from '../DepositModal';



const DepositOptionsModal = ({ modalVisible, outsidePressHandler }) => {

  const [depositModalVisible, setDepositModalVisible] = useState(false);

  const [depositOptionsModalVisible, setDepositOptionsModalVisible] = useState(false);


  useEffect(() => {
    setDepositOptionsModalVisible(modalVisible);
  }, [modalVisible]);


  return (
    <>
    <Modal animationType="slide" visible={depositOptionsModalVisible} transparent={false}>
      <Pressable style={styles.container} onPressOut={outsidePressHandler}>
        <View style={styles.modal}>
          <AppText style={{ fontSize: 20, color: colors.white, marginTop: 10, marginBottom: 20, textAlign: 'center' }}>Moneda de Depósito</AppText>
          <TouchableOpacity style={{alignSelf: "stretch"}} onPress={() => setDepositModalVisible(true)}>
           <AppText><CountryFlag isoCode="ar" size={22} />   <AppText style={{fontSize: 14, color: colors.white, marginBottom: 10, textAlign: 'center', fontWeight: 'bold', color: "white" }}>Pesos Argentinos (ARS)</AppText></AppText>
          </TouchableOpacity>
          <View style={styles.divider}></View>
          <TouchableOpacity style={{alignSelf: "stretch"}}>
            <AppText><CountryFlag isoCode="us" size={22}/>   <AppText style={{fontSize: 14, color: colors.white, marginBottom: 10, textAlign: 'center', fontWeight: 'bold', color: "gray" }}>Dólares Digitales (USDC) - estará disponible en 2024</AppText></AppText>
          </TouchableOpacity>
        </View>
      </Pressable>
    </Modal>
    <DepositModal
      modalVisible={depositModalVisible}
      outsidePressHandler={() => {
        setDepositModalVisible(false);
        outsidePressHandler();
      }}
    />
    </>
  );
};

export default DepositOptionsModal;