import styles from './styles';
import LottieView  from 'react-native-web-lottie';
//import LottieView from 'lottie-react-native';


function DownArrowIndicator({ innerRef = null}) {
    return <LottieView
        ref={innerRef}
        onLayout={() => {innerRef?.current?.play()}}
        autoPlay
        loop
        source={require('../../../assets/animations/down-arrow.json')}
        style={styles.container}
    />
}

export default DownArrowIndicator;