import { StyleSheet } from 'react-native';
import colors from '../../config/colors';

const styles = StyleSheet.create({
    button: {
        color: colors.primary,
        fontWeight: "bold",
    },
    container: {
        flex:1,
        padding: 10,
    },
})

export default styles;