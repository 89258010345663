import React, { useEffect, useRef, useState } from 'react';
import * as Yup from "yup";

import styles from './styles';
import {
    AppForm,
    AppFormField,
    ErrorMessage,
} from "../../components/forms";
import usersApi from '../../api/users';
import operatorsApi from '../../api/operators';
import { validateBankBranch, validateAccount, validateAccountOriginal, validateBankBranchOriginal, validateCuit } from '../../utility/validators';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import MessageModal from '../../components/MessageModal';
import Screen from '../../components/Screen';
import { ProgressSteps, ProgressStep } from 'react-native-progress-steps';
import { useIsFocused } from '@react-navigation/native';
import colors from '../../config/colors';


const validationSchema = Yup.object().shape({
    id: Yup.string().required("CUIT/CUIL es un campo obligatorio.")
        .test("validate-length", "CUIT/CUIL inválido.", (value) => value.length === 13)
        .test("validate-cuit", "CUIT/CUIL inválido.", validateCuit)
        .label("CUIT/CUIL"),
    name: Yup.string().required("Razón Social / Nombre Completo es un campo obligatorio.").label("Razón Social / Nombre Completo"),
    bank_account: Yup.string().required("CBU es un campo obligatorio.")
        .test("validate-length", "CBU inválido.", (value) => value.length === 26)
        .test("validate-bank-branch", "CBU inválido.", validateBankBranchOriginal)
        .test("validate-account", "CBU inválido.", validateAccountOriginal)
        .label("CBU (donde ingresarán los pagos)"),
    email: Yup.string().required("Email es un campo obligatorio.").email("El email debe ser válido.").label("Email"),
    confirm_email: Yup.string().oneOf([Yup.ref('email'), null], "Los emails no coinciden").required("Confirmación de email es un campo obligatorio.").email("El email debe ser válido.").label("Confirmación de email"),
    address: Yup.string().required("Dirección es un campo obligatorio").label("Dirección (donde enviaremos calcomanías)"),
    city: Yup.string().label("Localidad"),
    province: Yup.string().required("Provincia es un campo obligatorio").label("Provincia"),
    phone_number: Yup.string().label("Número de teléfono"),
    referral_code: Yup.string().label("Código de Referencia"),
    password: Yup.string().required("Contraseña es un campo obligatorio.").min(4, "La contraseña debe tener al menos 4 dígitos.").label("Contraseña"),
    confirm_password: Yup.string().oneOf([Yup.ref('password'), null], "Las contraseñas no coinciden").required("Confirmación de contraseña es un campo obligatorio.").min(4, "La contraseña debe tener al menos 4 dígitos.").label("Confirmación de contraseña")
  });

function RegisterScreen({navigation, route}) {

    const { initialUrl } = route.params;

    const NEXT_BUTTON_TEXT = "Seguir";
    const PREVIOUS_BUTTON_TEXT = "Regresar";
    const FINISH_BUTTON_TEXT = "Registrar";

    const [id, setId] = useState(null);
    const [name, setName] = useState(null);
    const [bankAccount, setBankAccount] = useState(null);
    const [email, setEmail] = useState(null);
    const [confirmEmail, setConfirmEmail] = useState(null);
    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const [province, setProvince] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [referralCode, setReferralCode] = useState(initialUrl?.split("/").pop() ? initialUrl.split("/").pop().concat(' ', "(no cambiar)") : null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [errors, setErrors] = useState(false);

    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const [finalLoading, setFinalLoading] = useState();
    const [idValue, setIdValue] = useState();

    const isFocused = useIsFocused();

    useEffect(() => {
        setErrors(false);
    }, [isFocused]);

    useEffect(() => {
        if (bankAccount){
            setBankAccount(bankAccount.replace(/\s/g, ''));
        }
    }, [bankAccount]);


    // modal
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessageType, setModalMessageType] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [buttonText, setButtonText] = useState('');

    const buttonHandler = async () => {
        if (modalMessageType === 'existingAccount') {
            const result = await operatorsApi.getCompanyOperators(idValue, 20);
            if (!result.data.operators || result.data.operators.length == 0){
                setLoading(false);
                return
            } else {
                navigation.navigate('LoginNavigator', {screen: "Password", params: {user: result.data.operators[0]}});
            }
        }
        setModalVisible(false);
    };

    const showModal = (type, headerText, message, buttonText, userId) => {
        setModalMessageType(type);
        setHeaderText(headerText);
        setModalMessage(message);
        setButtonText(buttonText);
        setModalVisible(true);
        setIdValue(userId);
    };

    const handleSubmit = async () => {
        setFinalLoading(true);
        const userInfo = {
            "id": id,
            "email": email,
            "confirm_email": confirmEmail,
            "phone_number": phoneNumber,
            "referral_code": referralCode,
            "address": address,
            "province": province,
            "city": city,
            "password": password,
            "confirm_password": confirmPassword,
            "name": name,
            "bank_account": bankAccount,
        }
        const result = await usersApi.register(userInfo);

        if (!result.ok) {
            if (result.status == 409 && result.data.detail == "A user with that ID already exists."){
                setError("Ya existe un usuário con este CUIT/CUIL.");
            }
            else if (result.data) setError("Ya existe un usuario con esta CBU o correo electrónico.");
            else {
                setError("Ocurrió un error inesperado.");
            }
            return;
        }
        navigation.navigate("Email Verification", { userId: userInfo.id, password: userInfo.password, email: userInfo.email })
        // setTimeout(function(){ 
        //     setFinalLoading(false);
        // }, 500);
    };

    const checkExistingCuit = async () => {
        setLoading(true);
        const result = await usersApi.get(id);
        if (result.data.id){
            setLoading(false);
            setErrors(true);
            return showModal('existingAccount', '!Parece que ya tienes una cuenta!', 'Ya existe un usuario con este CUIT/CUIL. Inicia sesión ahora.', 'Acceder', id);
        }
        setLoading(false);
    }

    if (!finalLoading){
        return (
            <Screen style={styles.container}>
                <ScrollView>
                    <AppForm
                        initialValues={{ id: "", name: "", bank_account: "", email: "", confirm_email: "", address:"", city:"", province:"", phone_number: "", referral_code: "", password: "", confirm_password:"" }}
                        validationSchema={validationSchema}
                    >
                        <ErrorMessage error={error} visible={error} />
                        <ProgressSteps labelFontSize={12}>
                        <ProgressStep 
                            label="Información Personal"
                            nextBtnText="Seguir"
                            nextBtnTextStyle={styles.button}
                            nextBtnDisabled={!id || validateCuit(id) === false || !name}
                            onNext={checkExistingCuit}
                            errors={errors}
                        >
                            <AppFormField
                                masked={true}
                                mask="99-99999999-9"
                                maxLength={13}
                                autoCapitalize="none"
                                autoCorrect={false}
                                icon="identifier"
                                keyboardType="numeric"
                                name="id"
                                placeholder="CUIT/CUIL"
                                value={id}
                                setState={setId}
                            />
                            <AppFormField
                                autoCorrect={false}
                                icon="account"
                                name="name"
                                placeholder="Razón Social / Nombre Completo"
                                value={name}
                                setState={setName}
                            />
                        </ProgressStep>
                        <ProgressStep
                            label="Información Bancária"
                            nextBtnText={NEXT_BUTTON_TEXT}
                            nextBtnTextStyle={styles.button}
                            previousBtnText={PREVIOUS_BUTTON_TEXT}
                            previousBtnTextStyle={styles.button}
                            nextBtnDisabled={!bankAccount || validateAccount(bankAccount) === false || validateBankBranch(bankAccount) === false}
                            errors={errors}
                        >
                            <AppFormField
                                masked={true}
                                mask="999 9999 9 9999999999999 9"
                                maxLength={26}
                                keyboardType="numeric"
                                autoCorrect={false}
                                icon="bank"
                                name="bank_account"
                                placeholder="CBU (donde ingresarán los pagos)"
                                value={bankAccount}
                                setState={setBankAccount}
                            />
                        </ProgressStep>
                        <ProgressStep
                            label="Log In y Contraseña"
                            nextBtnText={NEXT_BUTTON_TEXT}
                            nextBtnTextStyle={styles.button}
                            previousBtnText={PREVIOUS_BUTTON_TEXT}
                            nextBtnDisabled={!email || email !== confirmEmail || !password || password.length < 4 || password !== confirmPassword }
                            previousBtnTextStyle={styles.button}
                        >
                            <AppFormField
                                autoCapitalize="none"
                                autoCorrect={false}
                                icon="email"
                                keyboardType="email-address"
                                name="email"
                                placeholder="Email"
                                textContentType="emailAddress"
                                value={email}
                                setState={setEmail}
                            />
                            <AppFormField
                                autoCapitalize="none"
                                autoCorrect={false}
                                icon="email"
                                keyboardType="email-address"
                                name="confirm_email"
                                placeholder="Confirmación de Email"
                                textContentType="emailAddress"
                                value={confirmEmail}
                                setState={setConfirmEmail}
                            />
                            <AppFormField
                                autoCapitalize="none"
                                autoCorrect={false}
                                icon="lock-open-variant"
                                name="password"
                                placeholder="Contraseña"
                                secureTextEntry
                                textContentType="password"
                                value={password}
                                setState={setPassword}
                            />
                            <AppFormField
                                autoCapitalize="none"
                                autoCorrect={false}
                                icon="lock-open-variant"
                                name="confirm_password"
                                placeholder="Confirmación de Contraseña"
                                secureTextEntry
                                textContentType="password"
                                value={confirmPassword}
                                setState={setConfirmPassword}
                            />
                        </ProgressStep>
                        <ProgressStep label="Dirección y Contacto" onSubmit={handleSubmit} finishBtnDisabled={loading} finishBtnText={FINISH_BUTTON_TEXT} nextBtnTextStyle={styles.button} previousBtnText={PREVIOUS_BUTTON_TEXT} previousBtnTextStyle={styles.button} nextBtnDisabled={!province}>
                            <AppFormField
                                autoCapitalize="none"
                                autoCorrect={false}
                                icon="home"
                                name="address"
                                placeholder="Dirección (recibir calcomanías)"
                                value={address}
                                setState={setAddress}
                            />
                            <View style={{display: "flex", flexDirection:"row", gap: 5}}>
                                <AppFormField
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    icon="home"
                                    name="city"
                                    placeholder="Localidad"
                                    half={true}
                                    value={city}
                                    setState={setCity}
                                />
                                <AppFormField
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    icon="home"
                                    name="province"
                                    placeholder="Provincia"
                                    half={true}
                                    value={province}
                                    setState={setProvince}
                                />
                            </View>
                            <AppFormField
                                maxLength={15}
                                autoCapitalize="none"
                                autoCorrect={false}
                                icon="phone"
                                keyboardType="phone-pad"
                                name="phone_number"
                                placeholder="Número de Teléfono"
                                value={phoneNumber}
                                setState={setPhoneNumber}
                            />
                            <AppFormField
                                maxLength={15}
                                autoCapitalize="none"
                                autoCorrect={false}
                                icon="account-voice"
                                name="referral_code"
                                placeholder="Código de Referencia (Opcional)"
                                disabled={initialUrl?.split("/").pop() ? true : false}
                                value={referralCode}
                                setState={setReferralCode}
                            />
                        </ProgressStep>
                    </ProgressSteps>
                    </AppForm>
                </ScrollView>
                <MessageModal
                    modalVisible={modalVisible}
                    buttonHandler={buttonHandler}
                    outsidePressHandler={buttonHandler}
                    type={modalMessageType}
                    headerText={headerText}
                    message={modalMessage}
                    buttonText={buttonText}
                />
            </Screen>
        )
    } else {
        return <Screen style={{justifyContent: "center"}}>
                <ActivityIndicator size="large" color={colors.secondary}/>
            </Screen>
    }

}

export default RegisterScreen;