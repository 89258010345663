import { Platform } from 'react-native';
import LottieView  from 'react-native-web-lottie';
//import LottieView from 'lottie-react-native';


function ActivityIndicator({ innerRef = null, visible = false, source }) {
    if (!visible) return null;
    return <LottieView
        ref={innerRef}
        onLayout={() => {innerRef?.current?.play()}}
        autoPlay
        loop
        source={source}
        style={[{alignSelf: "center" }, Platform.OS === "web" && {width: 500}]}
    />
}

export default ActivityIndicator;