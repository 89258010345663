import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        padding: 10,
    },
    canceledMessage: {
        alignContent: "center",
        alignItems: "center",
        alignSelf:"center",
        justifyContent: "center",
        marginHorizontal: 10,
        flex: 1,
    },
    title: {
        marginTop: 10,
        fontSize: 22,
        fontWeight: "bold",
        textAlign: "center",
    },
    description: {
        marginTop: 10,
        fontSize: 16,
        textAlign: "center",
    },
})

export default styles;