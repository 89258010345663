import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';
import { Platform } from 'react-native';

const accessTokenKey = 'accessToken';

const storeAccessToken = async accessToken => {
    try {
        if (Platform.OS === "web") {
            return await AsyncStorage.setItem(accessTokenKey, accessToken);
        } else {
            return await SecureStore.setItemAsync(accessTokenKey, accessToken);
        }
    } catch (error) {
        console.log("Error storing the access token", error);
    }
}


const getAccessToken = async () => {
    try {
        if (Platform.OS === "web") {
            return await AsyncStorage.getItem(accessTokenKey);
        } else {
            return await SecureStore.getItemAsync(accessTokenKey);
        }
    } catch (error) {
        console.log("Error getting the access token", error);
    }
}

const getUser = async () => {
    const token = await getAccessToken();
    return (token) ? jwtDecode(token) : null; 
}

const removeAccessToken = async () => {
    try {
        if (Platform.OS === "web") {
            return await AsyncStorage.removeItem(accessTokenKey);
        } else {
            return await SecureStore.deleteItemAsync(accessTokenKey);
        }
    } catch (error) {
        console.log("Error removing the access token", error);
    }
}

export default { 
    getAccessToken,
    getUser,
    removeAccessToken,
    storeAccessToken,
};