import { useContext } from "react"
import jwtDecode from "jwt-decode";

import AuthContext from './context';
import authStorage from './storage';


const useAuth = () => {
    const { user, setUser } = useContext(AuthContext);

    const logIn = (accessToken) => {
        const user = jwtDecode(accessToken);
        setUser(user);
        authStorage.storeAccessToken(accessToken);
    }

    const logOut = () => {
        setUser(null);
        authStorage.removeAccessToken();
    }

    return { user, logIn, logOut };
}

export default useAuth;