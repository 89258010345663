import React from 'react';
import { Platform, SafeAreaView, StatusBar } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import NewPaymentInScreen from '../screens/NewPaymentInScreen';
import NewPaymentOutScreen from '../screens/NewPaymentOutScreen';


const Tab = createMaterialTopTabNavigator();



const PaymentScreenNavigator = () => {

    return <SafeAreaView style={{
        width: "100%",
        height: "100%",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0
    }}>

        <Tab.Navigator>
            <Tab.Screen name="Recibir" component={NewPaymentInScreen} />
            <Tab.Screen name="Pagar" component={NewPaymentOutScreen} />
        </Tab.Navigator>

    </SafeAreaView>
};

export default PaymentScreenNavigator;