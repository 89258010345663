import React, { useEffect, useState } from 'react';
import { Dimensions, View } from 'react-native';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import colors from '../../config/colors';
import {
    CodeField,
    Cursor,
    useBlurOnFulfill,
    useClearByFocusCell,
  } from 'react-native-confirmation-code-field';

// custom components
import { KeyboardAvoidingView } from 'react-native';
import Screen from '../../components/Screen';
import AppButton from '../../components/AppButton';
import AppText from '../../components/AppText';
import MessageModal from '../../components/MessageModal';
import styles from './styles';
import ResendTimer from '../../components/ResendTimer';
import operatorsApi from '../../api/operators'; 
import usersApi from '../../api/users';
import useAuth from '../../auth/useAuth';
import { ErrorMessage } from '../../components/forms';

const ScreenHeight = Dimensions.get('screen').height;


const EmailVerificationScreen = ({route}) => {
  const { logIn } = useAuth();
  const [loading, setLoading] = useState(false);

  const { userId, password, email } = route.params;

  // code input
  const MAX_CODE_LENGTH = 4;
  const [code, setCode] = useState('');
  const [pinReady, setPinReady] = useState(false);
  const [invalidPin, setInvalidPin] = useState(false);

  const ref = useBlurOnFulfill({code, cellCount: MAX_CODE_LENGTH});
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    code,
    setCode,
  });

  // resending email
  const [activeResend, setActiveResend] = useState(false);
  const [resendStatus, setResendStatus] = useState('Reenviar');

  // modal
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessageType, setModalMessageType] = useState('');
  const [headerText, setHeaderText] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [buttonText, setButtonText] = useState('');

  const buttonHandler = async () => {
    let id;
    let company_id;
    let passcode;
    if (modalMessageType === 'success') {
      const { data } = await operatorsApi.login(
        id=userId,
        company_id=userId,
        passcode=password,
      );
      logIn(data["access_token"]);
    }
    setModalVisible(false);
  };

  const showModal = (type, headerText, message, buttonText) => {
    setModalMessageType(type);
    setHeaderText(headerText);
    setModalMessage(message);
    setButtonText(buttonText);
    setModalVisible(true);
  };

  const resendEmail = async (triggerTimer) => {
    setActiveResend(false);
    try {
      let result;
      let companyId;
      // make request to backend
      result = await usersApi.sendVerificationOTPEmail(companyId=userId);
      if (!result.ok){
          setTimeout(async () => {
              result = await usersApi.sendVerificationOTPEmail(companyId=userId);
          }, 1500)
      }

      if (!result.ok) {
          setResendStatus("Fracasado!");
          return;
      }
      setResendStatus("Enviado!");
      setResendStatus('Reenviar');
      triggerTimer();
    } catch (error) {
      setResendStatus('Fracasado!');
      alert("Error", 'Reenvio de Email Fracasado: ' + error.message);
    }
  };

  const handleEmailVerification = async () => {
    let companyId;
    let verifyEmailInput;
    try {
      setLoading(true);

      // call backend
      const result = await usersApi.verifyEmail(
        companyId=userId,
        verifyEmailInput={otp: code}
      );

      if (result.ok){
        setInvalidPin(false);
        setLoading(false);
        return showModal('success', '¡Todo está bien!', 'Su correo electrónico ha sido verificado.', 'Entrar');
      }
      setInvalidPin(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return showModal('failed', '¡Error!', error.message, 'Cerrar');
    }
  };

  useEffect(() => {
    // toggle pinReady
    if (code.length < MAX_CODE_LENGTH) {
      setInvalidPin(false);
    }
    setPinReady(code.length === MAX_CODE_LENGTH);
    return () => setPinReady(false);
  }, [code]);


  return (
    <Screen>
      <KeyboardAvoidingView style={styles.container}>
        <View style={styles.icon}>
            <MaterialCommunityIcons name="lock-open" size={ScreenHeight * 0.08} color={colors.white}/>
        </View>
        <AppText style={{ textAlign: 'center' }}>
            Ingrese el código de 4 dígitos enviado a su correo <AppText style={{ fontWeight: 'bold' }}>{email}</AppText>
        </AppText>
        <CodeField
            ref={ref}
            {...props}
            value={code}
            onChangeText={setCode}
            cellCount={MAX_CODE_LENGTH}
            rootStyle={styles.codeFieldRoot}
            keyboardType="number-pad"
            textContentType="oneTimeCode"
            renderCell={({index, symbol, isFocused}) => (
            <AppText
                key={index}
                style={[styles.cell, isFocused && styles.focusCell]}
                onLayout={getCellOnLayoutHandler(index)}>
                {symbol || (isFocused ? <Cursor /> : null)}
            </AppText>
            )}
        />
        <ErrorMessage error="PIN invalido." visible={invalidPin}/>
        <AppButton onPress={handleEmailVerification} title="Verificar" disabled={!pinReady || loading} loading={loading}/>
        <ResendTimer
          activeResend={activeResend}
          setActiveResend={setActiveResend}
          targetTimeInSeconds={30}
          resendStatus={resendStatus}
          resendEmail={resendEmail}
        />
        <MessageModal
          modalVisible={modalVisible}
          buttonHandler={buttonHandler}
          outsidePressHandler={buttonHandler}
          type={modalMessageType}
          headerText={headerText}
          message={modalMessage}
          buttonText={buttonText}
        />
      </KeyboardAvoidingView>
    </Screen>
  );
};

export default EmailVerificationScreen;