import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";

import AccountScreen from '../screens/AccountScreen';
import BankingInformationEditScreen from '../screens/BankingInformationEditScreen';
import CryptoInformationEditScreen from '../screens/CryptoInformationEditScreen';
import OperatorsScreen from '../screens/OperatorsScreen';
import PreferencesScreen from '../screens/PreferencesScreen';
import SupportScreen from '../screens/SupportScreen';


const Stack = createStackNavigator();


const AccountNavigator = () => (
    <Stack.Navigator initialRouteName="Mi Cuenta">
        <Stack.Screen name="Mi Cuenta" component={AccountScreen}/>
        <Stack.Screen name="Información Bancária (ARS)" component={BankingInformationEditScreen}/>
        <Stack.Screen name="Información Cripto (USDC)" component={CryptoInformationEditScreen}/>
        <Stack.Screen name="Operadores" component={OperatorsScreen}/>
        <Stack.Screen name="Preferencias" component={PreferencesScreen}/>
        <Stack.Screen name="Soporte y Ayuda" component={SupportScreen}/>
    </Stack.Navigator>
);

export default AccountNavigator;