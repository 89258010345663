import { Dimensions, Platform, StyleSheet } from 'react-native';
import colors from '../../config/colors';

const screenWidth = Dimensions.get('screen').width;


const styles = StyleSheet.create({
    buttonsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        gap: screenWidth / 20,
    },
    container: {
        flex: 1,
        padding: 25,
        backgroundColor: colors.white,
        opacity: 1.0,
        justifyContent: "center",
        alignItems: "center",
    },
    divider: {
        height: 10,
    },
    modal: {
        backgroundColor: colors.dark,
        borderRadius: 20,
        width: "100%",
        padding: 35,
        elevation: 5,
        shadowColor: "black",
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.25,
        shadowRadius: 4,
    },
    dropdown1BtnStyle: {
        width: "100%",
        backgroundColor: colors.white,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.disabled,
    },
    dropdown1BtnTxtStyle: {
        color: colors.dark, 
        textAlign: 'left'
    },
    dropdown1DropdownStyle: {
        backgroundColor: colors.lightGrey,
    },
    dropdown1RowStyle: {
        backgroundColor: colors.light,
        borderBottomColor: colors.disabled,
    },
    dropdown1RowTxtStyle: {
        color: colors.dark,
        textAlign: 'left'
    },
    dropdown2BtnStyle: {
        width: "40%",
        height: "100%",
        backgroundColor: colors.light,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.light,
    },
    dropdown2BtnTxtStyle: {
        color: colors.dark, 
        textAlign: Platform.OS == "web" ? 'left' : "center",
    },
    dropdown2RowTxtStyle: {
        color: colors.dark,
        textAlign: 'center'
    }
})


export default styles;