import React from 'react';
import { TouchableHighlight, View } from 'react-native';

import AppText from '../../AppText';
import colors from '../../../config/colors';
import styles from './styles';
import { statusTranslations } from '../../../utility/utils';


function ListItem({title, description, image, IconComponent, SecondaryIconComponent = null, onPress, status}) {
    return (
        <TouchableHighlight
            underlayColor={colors.light}
            onPress={onPress}
        >
            <View style={styles.container}>
                {IconComponent}
                {image && <Image style={styles.image} source={image}/>}
                <View style={[styles.detailsContainer, !status && {flex: 1}]}>
                    <AppText style={[styles.title, {fontSize: 16}, title.startsWith("+") && {color: colors.success}, title.startsWith("-") && {color: "red"}]}>{title}</AppText>
                    {description && <AppText style={styles.description}>{description}</AppText>}
                </View>
                {SecondaryIconComponent && SecondaryIconComponent}
                { status && <View style={[styles.statusContainer, { backgroundColor: statusTranslations[status].statusColor}]}>
                    <AppText style={styles.status}>{statusTranslations[status].statusName}</AppText>
                </View> }
            </View>
        </TouchableHighlight>
    );
}

export default ListItem;