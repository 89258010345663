import { StyleSheet } from 'react-native';
import colors from '../../config/colors';

const styles = StyleSheet.create({
    container: {
        padding: 10,
        flex: 1,
    },
    divider: {
        height: 10,
    },
    dropdown1BtnStyle: {
        width: "100%",
        backgroundColor: colors.white,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.disabled,
      },
    dropdown1BtnTxtStyle: {
        color: colors.dark, 
        textAlign: 'left'
    },
    dropdown1DropdownStyle: {
        backgroundColor: colors.lightGrey,
    },
    dropdown1RowStyle: {
        backgroundColor: colors.light,
        borderBottomColor: colors.disabled,
    },
    dropdown1RowTxtStyle: {
        color: colors.dark,
        textAlign: 'left'
    },
    dropdown1searchInputStyleStyle: {
        backgroundColor: '#EFEFEF',
        borderRadius: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#444',
    }
})

export default styles;