import React from 'react';

import styles from './styles';
import { Keyboard, SafeAreaView, TouchableWithoutFeedback, Platform, View } from 'react-native';

function Screen({children, style}) {
    const safeAreaComponent = (
        <SafeAreaView style={[styles.screen, style]}>
            <View style={[styles.view, style]}>{children}</View>
        </SafeAreaView>
    )

    if (Platform.OS === "web") {
        return safeAreaComponent
    } else {
        return (
            <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
                {safeAreaComponent}
            </TouchableWithoutFeedback>
        );
    }
}

export default Screen;