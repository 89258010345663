import { Platform } from 'react-native';
import { Dimensions, StyleSheet } from 'react-native';


const dimensions = Dimensions.get('window');


const styles = StyleSheet.create({
    container: {
        alignSelf: "center",
        marginTop:  Platform.OS == "web" ? "8%": "15%",
        height: 150,
        width: 150,
    },
    doneDescription: {
        marginTop: Math.min(dimensions.height, dimensions.width) / 10,
        fontSize: 16,
        textAlign: "center",
    },
})


export default styles;