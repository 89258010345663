import React, { useEffect, useState } from 'react';
import { Modal, Pressable, View } from 'react-native';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import { prettifyToString, statusTranslations } from '../../utility/utils';
import AppText from '../AppText';
import AppButton from '../AppButton';
import RefundModal from '../RefundModal';
import PendingRefundModal from '../PendingRefundModal';
import usersApi from '../../api/users';
import Icon from '../Icon';



const PaymentDetailModal = ({ modalVisible, outsidePressHandler, headerText, subheaderText, txid, valueDestination, valueDestinationUsdc, referenceId, payer, status, operator = null, payoutMethod, companyId, loggedOperatorId, paidAt }) => {
    
  const [loading, setLoading] = useState(false);

  const [paymentDetailModalVisible, setPaymentDetailModalVisible] = useState(false);


  // Pending Refund Modal
  const [pendingRefundModalVisible, setPendingRefundModalVisible] = useState(false);


  useEffect(() => {
    setPaymentDetailModalVisible(modalVisible);
    setRefundModalVisible(false);
    setPendingRefundModalVisible(false);
  }, [modalVisible]);


  // Refund modal
  const [refundModalVisible, setRefundModalVisible] = useState(false);

  const deleteRefundModal = () => {
    setRefundModalVisible(false);
  }

  const showRefundOptions = async () => {
    setLoading(true);
    const refundDebtsResponse = await usersApi.getUserRefundDebts(companyId);

    const maxRefundDebt = (payoutMethod == "fiat") ? refundDebtsResponse.data.max_refund_debt_fiat : refundDebtsResponse.data.max_refund_debt_usdc;
    const refundDebt = (payoutMethod == "fiat") ? (refundDebtsResponse.data.refund_debt_fiat + valueDestination) : (refundDebtsResponse.data.refund_debt_usdc + valueDestinationUsdc);

    setPaymentDetailModalVisible(false);
    if (refundDebt > maxRefundDebt){
        setPendingRefundModalVisible(true);
    } else {
      setRefundModalVisible(true);
    }
    setLoading(false);
  };


  const getEarliestRefundDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }
    
  const shortenName = (fullName) => {
    const firstWord = fullName.substring(0, fullName.indexOf(' '));
    const restOfWords = fullName.substring(fullName.indexOf(' ') + 1);
    var matches = restOfWords.match(/\b(\w)/g);
    return (firstWord.charAt(0).toUpperCase() + firstWord.slice(1).toLowerCase()) + " " + matches?.join(' ');
}


  return (
    <>
      <Modal animationType="slide" visible={paymentDetailModalVisible} transparent={false}>
        <Pressable onPress={(outsidePressHandler)} style={styles.container}>
          <View style={styles.modal}>
            <Icon style={{alignSelf: "left"}} name="close" size={30}/>
            <View style={{alignItems:"center"}}>
              <AppText style={{ fontSize: 25, color: colors.white, marginVertical: 8, textAlign: 'center' }}>{headerText}</AppText>
              <AppText style={{ fontSize: 12, color: colors.white, marginBottom: 8, textAlign: 'center' }}>{subheaderText}</AppText>
              {status && 
              <View style={styles.statusRow}>
                <AppText style={{fontSize: 15, color: colors.white, textAlign: 'center' }}>ID: {referenceId}</AppText>
                <View style={[styles.statusContainer, { backgroundColor: statusTranslations[status].statusColor}]}>
                    <AppText style={styles.status}>{statusTranslations[status].statusName}</AppText>
                </View>
              </View>}
              {status !== "SENT_OUT" && <AppText style={{fontSize: 15, color: colors.white, marginBottom: 10, textAlign: 'center' }}>Pagador: {shortenName(payer)}</AppText>}
              {status === "SENT_OUT" && <AppText style={{fontSize: 15, color: colors.white, marginBottom: 10, textAlign: 'center' }}>Enviado a: {payer}</AppText>}
              {operator && status !== "SENT_OUT" && <AppText style={{fontSize: 15, color: colors.white, marginBottom: 10, textAlign: 'justify' }}>Operador: {operator}</AppText>}
              {((status === "FIAT_SOURCE" || status == "PAID_TO_CUSTOMER") && (new Date(paidAt) >= getEarliestRefundDate())) && (!(['30-64481315-7','33-69723504-9','30-71210931-5','30-71405110-1','30-70905848-3','30-71058320-6', '30-70777944-2'].includes(companyId) && !['30-64481315-7', '33-69723504-9','30-71210931-5','30-71405110-1','30-70905848-3','30-71058320-6', '30-70777944-2'].includes(loggedOperatorId))) &&
                    <View>
                        <AppButton onPress={showRefundOptions} title={"Devolver"} color="danger" disabled={loading} loading={loading}/>
                    </View>}
            </View>
          </View>
        </Pressable>
      </Modal>
      <RefundModal
        txid={txid}
        referenceId={referenceId}
        modalVisible={refundModalVisible}
        payoutMethod={payoutMethod}
        outsidePressHandler={() => {outsidePressHandler(); deleteRefundModal()}}
      />
      {valueDestination && <PendingRefundModal
        modalVisible={pendingRefundModalVisible}
        outsidePressHandler={outsidePressHandler}
        referenceId={referenceId}
        valueDestination={prettifyToString((valueDestination).toFixed(2))}
      />}
    </>
  );
};

export default PaymentDetailModal;