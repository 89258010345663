import { StyleSheet } from 'react-native';
import Constants from 'expo-constants';

import colors from '../../config/colors';


const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        backgroundColor: colors.primary,
        height: 50,
        justifyContent: "center",
        position: "absolute",
        top: Constants.statusBarHeight,
        width: "100%",
        zIndex: 1,
    },
    text: {
        color: colors.white,
    }
})

export default styles;