import { Dimensions, Platform, StyleSheet } from 'react-native';
import colors from '../../config/colors';
import Constants from 'expo-constants';

const { statusBarHeight } = Constants;
const screenHeight = Dimensions.get('screen').height;
const screenWidth = Dimensions.get('screen').width;


const styles = StyleSheet.create({
    bottomHalf: {
        alignItems: "center",
        justifyContent: "space-around",
        padding: 20,
    },
    container: {
        flex: 1,
        paddingHorizontal: 25,
        paddingTop: statusBarHeight,
        alignContent: "center",
        alignItems: "center",
    },
    icon: {
        backgroundColor: colors.primary,
        width: screenHeight * 0.15,
        height: screenHeight * 0.15,
        borderRadius: screenHeight * 0.2,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        marginBottom: 20,
    },
    text: {
        textAlign: "center"
    },
    title: {
        fontSize: 25,
        fontWeight: "bold",
        padding: 10,
        color: colors.primary,
    },
    cell: {
        width: 40,
        height: 40,
        lineHeight: 38,
        borderWidth: 2,
        borderColor: '#00000030',
        textAlign: 'center',
        margin: Platform.OS === "web" ? 1 : (screenWidth * 0.05),
    },
    codeFieldRoot: {
        margin: 20,
    },
    focusCell: {
        borderColor: '#000',
    },
})

export default styles;