import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        alignSelf: "center",
        marginTop: "2%",
        width: 150,
    },
})


export default styles;