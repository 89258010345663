import React, { useEffect, useRef, useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import * as Yup from "yup";
import SelectDropdown from 'react-native-select-dropdown';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useIsFocused } from "@react-navigation/native";

import alert from '../../utility/alert';
import conversionApi from '../../api/conversion';
import interApi from '../../api/inter';
import { ActivityIndicator } from '../../components/indicators';
import ExchangeCard from '../../components/ExchangeCard';
import styles from './styles';
import Screen from '../../components/Screen';
import {
    AppForm,
    AppFormField,
    SubmitButton,
  } from "../../components/forms";
import useAuth from '../../auth/useAuth';
import AppText from '../../components/AppText';
import colors from '../../config/colors';
import usersApi from '../../api/users';
import operatorsApi from '../../api/operators';
import { getStringFloatFromRawInput, prettifyToString } from '../../utility/utils';
import MessageModal from '../../components/MessageModal';
import Icon from '../../components/Icon';


const paymentOptions = [
    {
        "description": "Recibir Ahora",
        "value": "instant_charge"
    },
    {
        "description": "Enviar Enlace",
        "value": "long_term_charge"
    }
]

const validationRegex = /^(?=.*[1-9])\d{1,3}(,\d{3})*(\.\d{1,2})?$/;

const validationSchema = Yup.object().shape({
    amountDue: Yup.string()
        .matches(validationRegex, 'Debe ser mayor que cero.')
        .required().label("Monto"),
});


function NewPaymentInScreen({ navigation }) {
    const loadingRef = useRef(null);
    const { user } = useAuth();

    const isFocused = useIsFocused();

    const tabBarHeight = useBottomTabBarHeight();

    const qrCodeErrorMessage = "No se pudo crear el cobro Pix. Inténtalo de nuevo más tarde."
    const [loading, setLoading] = useState(false);
    const [exchangeRate, setExchangeRate] = useState();
    const [exchangeRateString, setExchangeRateString] = useState();
    const [exchangeRateARSUSDC, setExchangeRateARSUSDC] = useState();
    const [exchangeRateBRLUSDC, setExchangeRateBRLUSDC] = useState();
    const [exchangeRateARSUSDCString, setExchangeRateARSUSDCString] = useState();
    const [count, setCount] = useState(0);
    const [inputAmount, setInputAmount] = useState("0.00");
    const [inputCurrency, setInputCurrency] = useState("ARS");
    const [arsAmount, setArsAmount] = useState(0);
    const [brlAmount, setBrlAmount] = useState(0);
    const [usdcAmount, setUsdcAmount] = useState(0);
    const [paymentType, setPaymentType] = useState('instant_charge');
    const [referenceId, setReferenceId] = useState(null);
    const [usdcAddressPresent, setUsdcAddressPresent] = useState(false);
    const [travelRuleInfoPresent, setTravelRuleInfoPresent] = useState(false);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [preferredQrCodeType, setPreferredQrCodeType] = useState('dynamic');

    // modal
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessageType, setModalMessageType] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [buttonText, setButtonText] = useState('');

    const showModal = (type, headerText, message, buttonText) => {
        setModalMessageType(type);
        setHeaderText(headerText);
        setModalMessage(message);
        setButtonText(buttonText);
        setModalVisible(true);
    };

    const buttonHandler = async () => {
        if (modalMessageType === 'advanceConfirmation') {
            navigation.navigate("Account Navigator", {screen: "Información Cripto (USDC)"});
        } else if (modalMessageType === 'idleNotification'){
            setCount(0);
        }
        setModalVisible(false);
    };

    const outsidePressHandler = () => {
        setModalVisible(false);
        if (modalMessageType === 'idleNotification'){
            setCount(0);
        }
    }

    const [isToggleEnabled, setIsToggleEnabled] = useState();

    const MINUTE_SECONDS_MS = 60000;

    const currencyOptions = [
        "ARS", "BRL", "USDC"
    ]

    let timerId;

    const clear = () => {
        clearTimeout(timerId);
    }

    useEffect(() => {
        if (count == 10){
            showModal('idleNotification', 'Aplicación Inactiva', 'La aplicación ha estado inactiva durante 10 minutos, por lo que hemos pausado la actualización automática de precios.', 'Ok, Ya Estoy de Vuelta');
            return () => {navigation.removeListener('blur', clear)};
        }
        conversionApi.getConversionRate()
            .then((res) => {
                const exchangeRate = res.data.exchange_rate.toFixed(2);
                const exchangeRateBRLUSDC = res.data.exchange_rate_usdc.toFixed(2);
                const exchangeRateARSUSDC = res.data.exchange_rate_ars_usdc.toFixed(2);
                setExchangeRate(exchangeRate);
                setExchangeRateString(`1 BRL = ${exchangeRate} ARS`);
                setExchangeRateBRLUSDC(exchangeRateBRLUSDC);
                setExchangeRateARSUSDC(exchangeRateARSUSDC);
                setExchangeRateARSUSDCString(`1 USDC = ${exchangeRateARSUSDC} ARS`);
                timerId = setTimeout(() => {setCount(count + 1)}, MINUTE_SECONDS_MS);
            })
            .catch((error) => console.log(error));
        navigation.addListener('blur', clear);
        return () => {navigation.removeListener('blur', clear)}
      }, [count]);

      useEffect(() => {
        const companyId = user.type == 'master' ? user.id : user.company_id;
        usersApi.get(companyId)
        .then((res) => {
            if (user.type == 'master'){
                setPreferredQrCodeType(res.data.preferred_qr_code_type);
            } else {
                operatorsApi.get(user.id, user.company_id)
                .then((res) => {
                    setPreferredQrCodeType(res.data.preferred_qr_code_type);
                })
                .catch((error) => console.log(error));
            }
            if (res.data.preferred_withdrawal_currency == "USDC"){
                setIsToggleEnabled(true)
            } else {
                setIsToggleEnabled(false)
            }
            if(res.data.usdc_address) {
                setUsdcAddressPresent(true);
            };
            if (res.data.vasp_did && res.data.crypto_beneficiary_name && res.data.crypto_beneficiary_type){
                setTravelRuleInfoPresent(true);
            }
            setFinishedLoading(true);
        })
        .catch((error) => console.log(error));
      }, [isFocused]);


      useEffect(() => {
        setInputCurrency("ARS");
        setPaymentType("instant_charge");
      }, [isFocused]);


      useEffect(() => {
        const amount = parseFloat(getStringFloatFromRawInput(inputAmount)); 
        if (inputCurrency == "ARS"){
            setArsAmount(amount.toFixed(2));
            setBrlAmount(convertCurrencyCeil(amount, exchangeRate));
            setUsdcAmount(convertCurrencyFloor(amount, exchangeRateARSUSDC));
        } else if (inputCurrency == "BRL"){
            setBrlAmount(amount.toFixed(2));
            setArsAmount(convertCurrencyFloor(amount, 1/exchangeRate))
            setUsdcAmount(convertCurrencyFloor(amount, exchangeRateBRLUSDC));
        } else if (inputCurrency == "USDC"){
            setUsdcAmount(amount.toFixed(2));
            setArsAmount(convertCurrencyFloor(amount, 1/exchangeRateARSUSDC))
            setBrlAmount(convertCurrencyCeil(amount, 1/exchangeRateBRLUSDC));
        }
      }, [inputAmount, inputCurrency, exchangeRate]);


    const convertCurrencyCeil = (sourceCurrency, exchangeRate) => {
        return (Math.ceil((parseFloat(sourceCurrency) / exchangeRate)*100)/100).toFixed(2);
    };

    const convertCurrencyFloor = (sourceCurrency, exchangeRate) => {
        return (Math.floor((parseFloat(sourceCurrency) / exchangeRate)*100)/100).toFixed(2); 
    }

    const handleSubmit = async () => {
        const amountDueARS = String(arsAmount);
        const amountDueBRL = String(brlAmount);
        const amountUSDC = String(usdcAmount);
        
        if (arsAmount < 1000 && inputCurrency == "ARS"){
            return alert("Monto Demasiado Pequeño", "Monto mínimo para recibir: 1000 ARS")
        } else if (brlAmount < 5 && inputCurrency == "BRL"){
            return alert("Monto Demasiado Pequeño", "Monto mínimo para recibir: 5 BRL")
        } else if (usdcAmount < 1 && inputCurrency == "USDC"){
            return alert("Monto Demasiado Pequeño", "Monto mínimo para recibir: 1 USDC")
        }

        if (usdcAmount > 1000 && isToggleEnabled && !travelRuleInfoPresent) {
            if (user.type == 'master'){
                return showModal('advanceConfirmation', '¡Necesitamos un poco más de datos!', 'Para recibir transacciones superiores a 1,000 USDC, necesitamos la siguiente información: \n\n- Nombre completo / razón social del beneficiario de la billetera\n\n- Nombre del provedor de la billetera (VASP)\n\n- Tipo de billetera (persona física o jurídica)', 'Completar Información');
            } else {
                return showModal('failed', "¡Necesitamos un poco más de datos!", "Para recibir transacciones superiores a 1,000 USDC, el Operador Principal debe completar todas las informaciónes cripto.", "Ok")
            }
        }
        const payoutMethod = isToggleEnabled ? "crypto" : "fiat";

        setLoading(true); 
        const companyId = user.type == 'master' ? user.id : user.company_id;

        var userId;
        var operatorId;
        var chargeType;

        const createChargeResult = await interApi.addCharge(
            userId = companyId,
            operatorId = user.id,
            amountDueBRL,
            amountDueARS,
            amountUSDC,
            paymentType,
            payoutMethod,
            chargeType=((preferredQrCodeType == 'static' && paymentType == 'instant_charge') ? 'cash-in-fixed' : 'cash-in'),
            referenceId
        );
        if (!createChargeResult.ok) {
            setLoading(false);
            if (createChargeResult.status === 400){
                return alert("Servicio indisponible", qrCodeErrorMessage)
            } else if (createChargeResult.status === 426){
                return alert("Servicio indisponible", "Para continuar operando por favor registrar una dirección de depósito de Dólar cripto USDC por red Solana. Pago automático en segundos.\n\nAlgunas wallets que lo permiten y bajan del Playstore:  Ripio, BuenBit, Binance, etc...\n\nDe momento los *nuevos* depósitos en pesos están en pausa mientras nos estamos integrando a otro PSP de Argentina para mejorar los tiempos de acreditación.")
            }
        }

        const txid = createChargeResult.data.txid;
        const chargeReferenceId = createChargeResult.data.reference_id;
        const amountDueARSString = prettifyToString(amountDueARS);
        const amountDueBRLString = prettifyToString(amountDueBRL);
        const amountUSDCString = prettifyToString(amountUSDC);
        if (paymentType === "instant_charge") {
            navigation.navigate("QR Code", {
                txid: txid,
                qrCode: `data:image/png;base64,${createChargeResult.data.qrCode}`,
                exchangeRate: exchangeRateString,
                amountDueARS: amountDueARSString,
                amountDueBRL: amountDueBRLString,
                amountUSDC: isToggleEnabled ? amountUSDCString : null,
                referenceId: chargeReferenceId,
                pixPaidNotificationReceived: false,
                preferredQrCodeType: preferredQrCodeType,
            })
        } else {
            navigation.navigate("Copia e Cola", {
                pixCopiaECola: createChargeResult.data.pixCopiaECola,
                exchangeRate: exchangeRateString,
                amountDueARS: amountDueARSString,
                amountDueBRL: amountDueBRLString,
                amountUSDC: isToggleEnabled ? amountUSDCString : null,
                referenceId: chargeReferenceId,
            })
        }
        setTimeout(function(){ 
            setLoading(false);
        }, 500);
    };

    const getCurrencyDropdown = () => {
        const companyId = user.type == 'master' ? user.id : user.company_id;
        return (
            <SelectDropdown
                data={["30-71210931-5","30-71405110-1", "30-70905848-3", "30-71058320-6", '30-70777944-2'].includes(companyId) ? ["ARS"] : currencyOptions}
                defaultValue={"ARS"}
                onSelect={(selectedItem) => {
                    setInputCurrency(selectedItem)
                }}
                defaultButtonText={<AppText style={{'color': colors.medium}}>ARS</AppText>}
                buttonTextAfterSelection={(selectedItem) => {
                    return <AppText style={{fontSize: 16}}>{selectedItem}</AppText>
                }}
                rowTextForSelection={(item) => {
                    return <AppText style={{fontSize: 16}}>{item}</AppText>
                }}
                buttonStyle={styles.dropdown2BtnStyle}
                buttonTextStyle={styles.dropdown2BtnTxtStyle}
                renderDropdownIcon={isOpened => {
                    if (Platform.OS == "web"){
                        return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} iconColor={colors.dark} backgroundColor='transparent' size={46} />;
                    } else {
                        return <FontAwesome name={isOpened ? 'chevron-up' : 'chevron-down'} color={colors.dark} size={14} />
                    }
                }}
                dropdownIconPosition={'left'}
                dropdownStyle={styles.dropdown1DropdownStyle}
                rowStyle={styles.dropdown1RowStyle}
                rowTextStyle={styles.dropdown2RowTxtStyle}
            />
        )
    }

    if (!loading) {
        return (
            <Screen style={styles.container}>
                <KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={Platform.select({android: tabBarHeight + 50, ios: tabBarHeight})} style={[styles.footer, {marginBottom: 2}]}>
                    <ExchangeCard
                        title={exchangeRate && `Cliente paga: BRL ${prettifyToString(brlAmount)}`}
                        subTitle={exchangeRate && `Recibes: ${isToggleEnabled ? "USDC".concat(" ", prettifyToString(usdcAmount)) : "ARS".concat(" ", prettifyToString(arsAmount))}`}
                        exchangeRateString={exchangeRateString}
                        exchangeRateARSUSDCString={isToggleEnabled ? exchangeRateARSUSDCString : null}
                        warning={exchangeRate && (preferredQrCodeType == 'static')}
                    ></ExchangeCard>
                </KeyboardAvoidingView>
                <ScrollView>
                    <AppForm
                        initialValues={{ amountDue: "", paymentType: "", referenceId: ""}}
                        onSubmit={handleSubmit}
                        validateOnMount={true}
                        validationSchema={validationSchema}
                    >
                        <AppFormField
                            masked={true}
                            type="currency"
                            options={{
                                decimalSeparator: '.',
                                groupSeparator: ',',
                                precision: 2
                            }}
                            keyboardType="numeric"
                            maxLength={16}
                            name="amountDue"
                            setState={setInputAmount}
                            width={120}
                            dropdown={getCurrencyDropdown()}
                        />
                        <SelectDropdown
                            data={paymentOptions}
                            onSelect={(selectedItem) => {
                                setPaymentType(selectedItem.value)
                            }}
                            defaultValue={paymentOptions[0]}
                            buttonTextAfterSelection={(selectedItem) => {
                                return <AppText>{selectedItem.description}</AppText>
                            }}
                            rowTextForSelection={(item) => {
                                return <AppText>{item.description}</AppText>
                            }}
                            buttonStyle={styles.dropdown1BtnStyle}
                            buttonTextStyle={styles.dropdown1BtnTxtStyle}
                            renderDropdownIcon={isOpened => {
                            return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} iconColor={colors.dark} backgroundColor='transparent' size={48} />;
                            }}
                            dropdownIconPosition={'right'}
                            dropdownStyle={styles.dropdown1DropdownStyle}
                            rowStyle={styles.dropdown1RowStyle}
                            rowTextStyle={styles.dropdown1RowTxtStyle}
                        />
                        <AppFormField
                            type="numeric"
                            placeholder="ID de Referencia (Opcional)"
                            keyboardType="numeric"
                            maxLength={11}
                            name="referenceId"
                            setState={setReferenceId}
                            width={120}
                        />
                        {user.type == "master" && <View style={styles.divider} /> }
                        {user.type == "master" && <TouchableOpacity onPress={() => navigation.navigate("Account Navigator", {screen: "Preferencias"})}>
                            <AppText style={{fontWeight: "bold", color: "blue", fontSize: 12, textAlign:"center"}}>Cambiar Moneda de Retiro</AppText> 
                        </TouchableOpacity>}
                        <View style={styles.divider} />
                        <SubmitButton title="Generá Cobro Pix" disabled={!exchangeRate || !validationRegex.test(inputAmount) || !paymentType || !usdcAmount || !arsAmount || !brlAmount}/>
                    </AppForm>
                </ScrollView>
                <MessageModal
                    modalVisible={modalVisible}
                    buttonHandler={buttonHandler}
                    outsidePressHandler={outsidePressHandler}
                    type={modalMessageType}
                    headerText={headerText}
                    message={modalMessage}
                    buttonText={buttonText}
                />
            </Screen>
        );
    } else {
        return (
            <>
                <ActivityIndicator innerRef={loadingRef} visible={loading} source={require('../../assets/animations/circle-loader.json')}/> 
                <AppText style={{"textAlign": "center", "marginTop": "110%"}}>Por favor espere mientras creamos su cobro Pix. Esto puede demorar unos pocos segundos.</AppText>
            </>
        )
    }
}

export default NewPaymentInScreen;