import colors from "../config/colors";

import { cpf, cnpj } from 'cpf-cnpj-validator';



export const CPF_MASK = '999.999.999-999';
export const CNPJ_MASK = '99.999.999/9999-99';


export const prettifyToString = (amount) => {
    if (isNaN(amount) || amount == 0){
        return "0.00"
    }
    return String(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export const statusTranslations = {
    "FIAT_SOURCE": {
        statusName: "RECIBIDO ✓",
        statusColor: colors.success,
    },
    "PAID_TO_CUSTOMER": {
        statusName: "ACREDITADO ✓✓",
        statusColor: colors.secondary,
    },
    "REFUNDED": {
        statusName: "DEVUELTO",
        statusColor: colors.mediumGrey,
    },
    "SENT_OUT": {
        statusName: "PAGADO ✓✓",
        statusColor: colors.secondary,
    }
};

export const str2bytes = (str) => {
    var bytes = new Uint8Array(str.length);
    for (var i=0; i<str.length; i++) {
        bytes[i] = str.charCodeAt(i);
    }
    return bytes;
}


export const getStringFloatFromRawInput = (rawInput) => {
    return rawInput.replace(/,(?=\d{3})|[a-zA-Z]+|\s/g, '');
}


export const validateCpf = (payerId) => {
    const payerIdDigits = payerId.replace(/\D/g,'');
    if (cpf.isValid(payerIdDigits)){
        return true;
    }
    return false;
}

export const validateCnpj = (payerId) => {
    const payerIdDigits = payerId.replace(/\D/g,'');
    if (cnpj.isValid(payerIdDigits)){
        return true;
    }
    return false;
}

export const maskCnpj = (v) => {
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
}

export const maskCpf = (v) => {
    v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
    v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}