import React, { useEffect, useState } from 'react';
import { Modal, Pressable, TouchableOpacity, View } from 'react-native';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import CountryFlag from 'react-native-country-flag';
import CryptoWithdrawalModal from '../CryptoWithdrawalModal';
import FiatWithdrawalModal from '../FiatWithdrawalModal';



const WithdrawalOptionsModal = ({ modalVisible, outsidePressHandler }) => {

  const [cryptoWithdrawalModalVisible, setCryptoWithdrawalModalVisible] = useState(false);
  const [fiatWithdrawalModalVisible, setFiatWithdrawalModalVisible] = useState(false);
  const [withdrawalOptionsModalVisible, setWithdrawalOptionsModalVisible] = useState(false);


  useEffect(() => {
    setWithdrawalOptionsModalVisible(modalVisible);
  }, [modalVisible]);


  return (
    <>
    <Modal animationType="slide" visible={withdrawalOptionsModalVisible} transparent={false}>
      <Pressable style={styles.container} onPressOut={outsidePressHandler}>
        <View style={styles.modal}>
          <AppText style={{ fontSize: 20, color: colors.white, marginTop: 10, marginBottom: 20, textAlign: 'center' }}>Moneda de Retiro</AppText>
          <TouchableOpacity style={{alignSelf: "stretch"}} onPress={() => setFiatWithdrawalModalVisible(true)}>
           <AppText><CountryFlag isoCode="ar" size={22} />   <AppText style={{fontSize: 14, color: colors.white, marginBottom: 10, textAlign: 'center', fontWeight: 'bold', color: "white" }}>Pesos Argentinos (ARS)</AppText></AppText>
          </TouchableOpacity>
          <View style={styles.divider}></View>
          <TouchableOpacity style={{alignSelf: "stretch"}} onPress={() => setCryptoWithdrawalModalVisible(true)}>
            <AppText><CountryFlag isoCode="us" size={22} />   <AppText style={{fontSize: 14, color: colors.white, marginBottom: 10, textAlign: 'center', fontWeight: 'bold', color: "white" }}>Dólares Digitales (USDC)</AppText></AppText>
          </TouchableOpacity>
        </View>
      </Pressable>
    </Modal>
    <FiatWithdrawalModal
      modalVisible={fiatWithdrawalModalVisible}
      outsidePressHandler={() => {
        setFiatWithdrawalModalVisible(false);
        outsidePressHandler();
      }}
    />
    <CryptoWithdrawalModal
      modalVisible={cryptoWithdrawalModalVisible}
      outsidePressHandler={() => {
        setCryptoWithdrawalModalVisible(false);
        outsidePressHandler();
      }}
    />
    </>
  );
};

export default WithdrawalOptionsModal;