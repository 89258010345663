import { Dimensions, StyleSheet } from 'react-native';
import colors from '../../config/colors';

const screenWidth = Dimensions.get('screen').width;


const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 25,
        backgroundColor: colors.white,
        opacity: 1.0,
        justifyContent: "center",
        alignItems: "center",
    },
    modal: {
        backgroundColor: colors.dark,
        borderRadius: 20,
        width: "100%",
        padding: 35,
        elevation: 5,
        shadowColor: "black",
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.25,
        shadowRadius: 4,
    },
    statusContainer: {
        borderRadius: 5,
        justifyContent: "center",
    },
    statusRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        gap: screenWidth / 30,
        alignItems: "center",
        marginBottom: 10, 
    },
    status: {
        fontSize: 11,
        fontWeight: "900",
        textAlign: "center",
        padding: 5,
        color: colors.white,
    },
})


export default styles;