import React from 'react';
import { Formik } from 'formik';

function AppForm({ initialValues, onSubmit, validateOnMount, validationSchema, children }) {
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}
            validateOnMount={validateOnMount}
            validationSchema={validationSchema}
        >
            { () => (
                <>
                    {children}
                </>
            ) }
        </Formik>
    );
}

export default AppForm;