import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
    CodeField,
    Cursor,
    useBlurOnFulfill,
    useClearByFocusCell,
} from 'react-native-confirmation-code-field';

import operatorsApi from '../../api/operators';
import usersApi from '../../api/users';
import useAuth from '../../auth/useAuth';
import { KeyboardAvoidingView } from 'react-native';
import styles from './styles';
import AppText from '../../components/AppText';
import { AppForm, AppFormField, ErrorMessage, SubmitButton } from '../../components/forms';
import ResendTimer from '../../components/ResendTimer';
import MessageModal from '../../components/MessageModal';
import Screen from '../../components/Screen';


const validationSchema = Yup.object().shape({
    new_password: Yup.string().required("Nueva contraseña es un campo obligatorio.").min(4, "La contraseña debe tener al menos 4 dígitos.").label("Nueva contraseña"),
    confirm_new_password: Yup.string().oneOf([Yup.ref('new_password'), null], "Las contraseñas no coinciden").required("Confirmación de contraseña es un campo obligatorio.").min(4, "La contraseña debe tener al menos 4 dígitos.").label("Confirmación de contraseña")
});

function ResetPasswordScreen({ route }) {
    const { logIn } = useAuth();
    const { userId, email } = route.params;
    const [loading, setLoading] = useState(false);

    const [message, setMessage] = useState('');
    const [newPassword, setNewPassword] = useState(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  
    // code input
    const MAX_CODE_LENGTH = 4;
    const [code, setCode] = useState('');
    const [pinReady, setPinReady] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);

    const ref = useBlurOnFulfill({code, cellCount: MAX_CODE_LENGTH});
    const [props, getCellOnLayoutHandler] = useClearByFocusCell({
      code,
      setCode,
    });
  
    // resending email
    const [activeResend, setActiveResend] = useState(false);
    const [resendStatus, setResendStatus] = useState('Reenviar');
  
    // modal
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessageType, setModalMessageType] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [buttonText, setButtonText] = useState('');

    useEffect(() => {
      // toggle pinReady
      if (code.length < MAX_CODE_LENGTH) {
        setInvalidPin(false);
      }
      setPinReady(code.length === MAX_CODE_LENGTH);
      return () => setPinReady(false);
    }, [code]);

    const buttonHandler = async () => {
        let id;
        let company_id;
        let passcode;
        if (modalMessageType === 'success') {
          const { data } = await operatorsApi.login(
            id=userId,
            company_id=userId,
            passcode=newPassword,
          );
          logIn(data["access_token"]);
        }
        setModalVisible(false);
    };

    const showModal = (type, headerText, message, buttonText) => {
        setModalMessageType(type);
        setHeaderText(headerText);
        setModalMessage(message);
        setButtonText(buttonText);
        setModalVisible(true);
    };

    const handleSubmit = async (resetPasswordInput) => {
      setLoading(true);
      resetPasswordInput.otp = code;
        let companyId;
        try {
          setMessage(null);
          const result = await usersApi.resetPassword(
            companyId=userId,
            resetPasswordInput,
          )

          if (result.ok) {
            setInvalidPin(false);
            setLoading(false);
            return showModal('success', '¡Todo está bien!', 'Su contraseña ha sido restablecida.', 'Entrar');
          }
          setLoading(false);
          setInvalidPin(true);
        } catch (error) {
          setLoading(false);
          return showModal('failed', '¡Error!', error.message, 'Cerrar');
        }
    };

    const resendEmail = async (triggerTimer) => {
        setActiveResend(false);
        try {
          let result;
          let companyId;
          // make request to backend
          result = await usersApi.sendResetPasswordOTPEmail(companyId=userId);
          if (!result.ok){
            setTimeout(async () => {
              result = await usersApi.sendResetPasswordOTPEmail(companyId=userId);
            }, 1500)
          }
          
          if (!result.ok) {
              setResendStatus("¡Fracasado!");
              return;
          }
          setResendStatus("¡Enviado!");
          setResendStatus('Reenviar');
          triggerTimer();
        } catch (error) {
          setResendStatus('¡Fracasado!');
          alert("Error", 'Reenvio de Email Fracasado: ' + error.message);
        }
    };

    return (
        <Screen>
          <KeyboardAvoidingView style={styles.container}>
            <AppText style={{ textAlign: 'center' }}>
                Ingrese el código de 4 dígitos enviado a su correo <AppText style={{ fontWeight: 'bold' }}>{email}</AppText>
            </AppText>
            <AppForm
                initialValues={{ new_password: "", confirm_new_password: "", otp: "" }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                <CodeField
                    ref={ref}
                    {...props}
                    value={code}
                    onChangeText={(text) => setCode(text)}
                    cellCount={MAX_CODE_LENGTH}
                    rootStyle={styles.codeFieldRoot}
                    keyboardType="number-pad"
                    textContentType="oneTimeCode"
                    renderCell={({index, symbol, isFocused}) => (
                    <AppText
                        key={index}
                        style={[styles.cell, isFocused && styles.focusCell]}
                        onLayout={getCellOnLayoutHandler(index)}>
                        {symbol || (isFocused ? <Cursor /> : null)}
                    </AppText>
                    )}
                />
                <ErrorMessage error="PIN invalido." visible={invalidPin}/>
                <ResendTimer
                  activeResend={activeResend}
                  setActiveResend={setActiveResend}
                  targetTimeInSeconds={30}
                  resendStatus={resendStatus}
                  resendEmail={resendEmail}
                  style={{ marginBottom: 25 }}
                />
                <AppFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    icon="lock-open-variant"
                    name="new_password"
                    placeholder="Nueva Contraseña"
                    secureTextEntry
                    textContentType="password"
                    setState={setNewPassword}
                />
                <AppFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    icon="lock-open-variant"
                    name="confirm_new_password"
                    placeholder="Confirmación de Contraseña"
                    secureTextEntry
                    textContentType="password"
                    setState={setConfirmNewPassword}
                />
                <SubmitButton title="Restablecer Contraseña" disabled={loading || (!pinReady || (newPassword !== confirmNewPassword) || newPassword === null)} loading={loading}/>
            </AppForm>
            <MessageModal
              modalVisible={modalVisible}
              buttonHandler={buttonHandler}
              outsidePressHandler={buttonHandler}
              type={modalMessageType}
              headerText={headerText}
              message={modalMessage}
              buttonText={buttonText}
            />
          </KeyboardAvoidingView>
        </Screen>
    );
};

export default ResetPasswordScreen;