import React from 'react';
import CountryFlag from "react-native-country-flag";
import { Platform, Share, View } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { MaterialCommunityIcons } from "@expo/vector-icons";

import styles from './styles';
import Screen from '../../components/Screen';
import AppText from '../../components/AppText';
import { TouchableOpacity } from 'react-native';
import AppTextInput from '../../components/AppTextInput';
import AppButton from '../../components/AppButton';
import colors from '../../config/colors';
import Icon from '../../components/Icon';


function CopiaEColaScreen({ route }) {

    const { amountDueARS, amountDueBRL, amountUSDC, pixCopiaECola, exchangeRate } = route.params;

    const copyToClipboard = async () => {
        await Clipboard.setStringAsync(pixCopiaECola);
    };

    const shareCode = async () => {
        try {
            await Share.share({
                message: pixCopiaECola,
            });
        } catch (error) {
            alert(error.message);
        }
    };

    return <Screen style={styles.container}>
      <View style={styles.message}>
        <AppText style={styles.title}>"Pix Copia e Cola" Generado</AppText>
        <TouchableOpacity style={Platform.OS === 'web' && {alignSelf: "stretch"}} onPress={copyToClipboard}>
            <AppTextInput faded={Platform.OS !== 'web'} icon="content-copy" editable={false} value={Platform.OS !== 'web' ? pixCopiaECola.substring(0,30) : pixCopiaECola}/> 
        </TouchableOpacity>
        { Platform.OS !== 'web' && <AppButton onPress={shareCode} title="Compartir"></AppButton>}
        <AppText style={styles.description}>Envíe este código al pagador. Él tiene 12 horas para pagar, después de lo cual el pago será visible en "Pagos".</AppText>
        <View style={styles.divider}></View>
        <View style={{ flexDirection: 'row', justifyContent: 'center'}}>
            <AppText style={styles.amountDueBRL}><CountryFlag isoCode="br" size={22} /> {amountDueBRL} BRL</AppText>
            <AppText style={styles.amountDueARS}><CountryFlag isoCode="ar" size={22} /> {amountDueARS} ARS</AppText>
        </View>
        {amountUSDC && 
        <>
            <View style={{height: 5}}></View>
            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <Icon name="hand-coin" size={22}/><AppText style={{fontSize: 14}}>  Recibes <AppText style={{fontSize: 14, fontWeight: "bold"}}>{amountUSDC} USDC</AppText></AppText>
            </View>
            <View style={styles.divider}></View>
        </>
        }
        <AppText style={styles.exchangeRate}><MaterialCommunityIcons name={"swap-horizontal-bold"} color={colors.black} size={20} /> {exchangeRate}</AppText>
        <AppText style={{marginTop: 25, fontWeight:"bold", color: colors.danger}}>¡Atención!</AppText>
        <AppText style={styles.warning}>1- El código/link no se pone en un explorador web, sino en el "Area Pix -&gt; Pix Copia e Cola" del banco pagador.</AppText>
        <AppText style={styles.warning}>2- El cliente debe copiar el código completo, incluidos los espacios en blanco.</AppText>
      </View>
    </Screen>
}

export default CopiaEColaScreen;