import { StyleSheet } from 'react-native';

import colors from '../../../config/colors';

const styles = StyleSheet.create({
    separator: {
        width: "100%",
        height: 1,
        color: colors.light,
    }
})

export default styles;