//import { usdcValidate } from '@dartess/multicoin-address-validator/lib/currencies/usdc';


// Validate the bank/branch section of a CBU
export const validateBankBranchOriginal = (value) => {
    var bank = value.substring(0, 3),
        branch = value.substring(4, 8),
        checksum2 = +value[9],
        sum = +bank[0] * 7 + +bank[1] * 1 + +bank[2] * 3 +
            +branch[0] * 9 + +branch[1] * 7 + +branch[2] * 1 + +branch[3] * 3,
        diff = 10 - (sum % 10);
    return diff == checksum2 || (diff === 10 && checksum2 === 0);
};


// Validate the account section of a CBU
export const validateAccountOriginal = (value) => {
    var checksum = +value[25],
        sum = +value[11] * 3 +
            +value[12] * 9 + +value[13] * 7 + +value[14] * 1 + +value[15] * 3 +
            +value[16] * 9 + +value[17] * 7 + +value[18] * 1 + +value[19] * 3 +
            +value[20] * 9 + +value[21] * 7 + +value[22] * 1 + +value[23] * 3,
        diff = 10 - (sum % 10);
    return diff == checksum || (diff === 10 && checksum === 0)
};


// Validate the bank/branch section of a CBU
export const validateBankBranch = (value) => {
    var bank = value.substring(0, 3),
        branch = value.substring(3, 7),
        checksum2 = +value[7],
        sum = +bank[0] * 7 + +bank[1] * 1 + +bank[2] * 3 +
            +branch[0] * 9 + +branch[1] * 7 + +branch[2] * 1 + +branch[3] * 3,
        diff = 10 - (sum % 10);
    return diff == checksum2 || (diff === 10 && checksum2 === 0);
};


// Validate the account section of a CBU
export const validateAccount = (value) => {
    var checksum = +value[21],
        sum = +value[8] * 3 +
            +value[9] * 9 + +value[10] * 7 + +value[11] * 1 + +value[12] * 3 +
            +value[13] * 9 + +value[14] * 7 + +value[15] * 1 + +value[16] * 3 +
            +value[17] * 9 + +value[18] * 7 + +value[19] * 1 + +value[20] * 3,
        diff = 10 - (sum % 10);
    return diff == checksum || (diff === 10 && checksum === 0)
};

export const validateCuit = (cuit) => {  
    let acumulado = 0;
    let digitos = cuit.replace(/-/g, "");
    let digito = parseInt(digitos.slice(-1));
  
    for (let i = 0; i < digitos.length - 1; i++) {
      acumulado += +digitos[9 - i] * (2 + (i % 6));
    }
  
    let verif = 11 - (acumulado % 11);
    if (verif === 11) {
      verif = 0;
    } else if (verif === 10) {
      verif = 9;
    }
  
    return digito === verif;
};