import { Platform, StyleSheet } from 'react-native';
import colors from '../../config/colors';


const styles = StyleSheet.create({
    amountDueARS: {
        fontSize: 16,
        justifyContent: 'flex-start',
        marginHorizontal: 10,
    },
    amountDueBRL: {
        fontWeight: "bold",
        marginBottom: 10,
        justifyContent: 'flex-start',
        marginHorizontal: 10,
    },
    container: {
        flex: 1,
        padding: 10,
    },
    countdown: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 'auto',
        padding: 8,
        alignSelf:"center",
    },
    divider: {
        height: 10,
    },
    doneMessage: {
        alignContent: "center",
        alignItems: "center",
        alignSelf:"center",
        justifyContent: "center",
        marginHorizontal: 10,
        flex: 1,
    },
    exchangeRate: {
        fontSize: 16,
        marginBottom: 10,
        justifyContent: 'flex-start',
        marginHorizontal: 30,
    },
    paymentSummary: {
        marginTop: Platform.OS === "web" ? "2%" : "8.5%",
        alignItems: "center",
        alignSelf:"center",
        borderWidth: 2,
        borderColor: colors.black,
        borderRadius: 15,
        justifyContent: "center",
        marginHorizontal: 10,
    },
    qrCode: qrCodeSize => ({
        width: qrCodeSize,
        height: qrCodeSize,
        alignSelf: "center",
        marginTop: 10,
    }),
    title: {
        marginTop: 10,
        fontSize: 22,
        fontWeight: "bold",
        textAlign: "center",
    }
})

export default styles;