import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        padding: 10,
        flex: 1,
    },
    divider: {
        height: 22,
    }
})

export default styles;