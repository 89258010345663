import { StyleSheet } from 'react-native';
import colors from '../../config/colors';



const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    logo: {
        width: 1024/6,
        height: 1024/6,
        alignSelf: "center",
        marginTop: 50,
        marginBottom: 20,
    },
    text: {
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: 10,
    },
    buttonsContainer: {
        width: '100%',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    icon: {
        flex: 1,
    },
    iconText: {
        fontSize: 10,
    },
    dropdownContainer: {
        paddingHorizontal: 20,
    },
    dropdown1BtnStyle: {
        width: "100%",
        backgroundColor: colors.white,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.disabled,
      },
    dropdown1BtnTxtStyle: {
        color: colors.dark, 
        textAlign: 'left'
    },
    dropdown1DropdownStyle: {
        backgroundColor: colors.lightGrey,
    },
    dropdown1RowStyle: {
        backgroundColor: colors.light,
        borderBottomColor: colors.disabled,
    },
    dropdown1RowTxtStyle: {
        color: colors.dark,
        textAlign: 'left'
    },
})

export default styles;