import React, { useEffect, useState } from 'react';
import { FlatList, View, SafeAreaView } from 'react-native';
import { ActivityIndicator  as ReactNativeActivityIndicator } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import * as Yup from "yup";

import { ListItem, ListItemSeparator } from '../../components/lists';
import { ActivityIndicator } from '../../components/indicators';
import AppText from '../../components/AppText';
import AppButton from '../../components/AppButton';
import styles from './styles';
import useAuth from '../../auth/useAuth';
import operatorsApi from '../../api/operators';
import { AppForm, AppFormField, ErrorMessage, SubmitButton } from '../../components/forms';
import Screen from '../../components/Screen';


const validationSchema = Yup.object().shape({
    id: Yup.string().required("")
        .matches(/^[0-9]+$/, "Deben ser solo dígitos")
        .min(3, 'Debe tener por lo menos 3 dígitos')
        .max(5, 'Debe tener hasta 5 dígitos').label("ID de Operador/Sucursal"),
    name: Yup.string().required("").label("Nombre"),
});

function OperatorsScreen() { 

    const isFocused = useIsFocused();
    const { user } = useAuth();
    const [idValue, setIdValue] = useState(null);
    const [nameValue, setNameValue] = useState(null);
    const [getOperatorsError, setGetOperatorsError] = useState();
    const [createOperatorsError, setCreateOperatorsError] = useState();
    const [operators, setOperators] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nextPage, setNextPage] = useState(null);
    const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState(true);
    const [endLoading, setEndLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);

    const pageSize = 15;

    const renderFooter = () => {
        if (!endLoading) return null;
        return <ReactNativeActivityIndicator />
    }

    useEffect(() => {
        if(isFocused){ 
            loadOperators();
        }
        if (!isFocused){
            setNextPage(null);
            setOperators([]);
            setOnEndReachedCalledDuringMomentum(true);
        }
    }, [isFocused]);

    const loadOperators = async () => {
        let companyId;
        setLoading(true);
        const response = await operatorsApi.getCompanyOperators(companyId=user.id, pageSize, nextPage);
        if (!response.ok) {
            setLoading(false);
            return setGetOperatorsError(true);
        }

        setGetOperatorsError(false);

        if (response.data.operators.length === 0){ // No more operators...
            setLoading(false);
            setEndLoading(false);
            return
        }
        setOperators(operators.concat(response.data.operators));
        setNextPage(response.data.next_page[0][0]);
        setLoading(false);
        setEndLoading(false);
    };

    const deleteItem = async (item) => {
        let companyId;
        let id;
        await operatorsApi.deleteOperator(companyId = user.id, id=item.id);
        await refreshOperators();
    }

    const refreshOperators = async () => {
        let companyId;
        setRefreshing(true);
        const response = await operatorsApi.getCompanyOperators(companyId=user.id, pageSize, null);

        if (!response.ok) {
            setRefreshing(false);
            return setGetOperatorsError(true);
        }

        setGetOperatorsError(false);
        setOperators(response.data.operators);
        setRefreshing(false);
        if (response.data.next_page[0]){
            setNextPage(response.data.next_page[0][0]);
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        const result = await operatorsApi.createOperator({ id: idValue , company_id: user.id , name: nameValue});

        if (!result.ok) {
            if (result.data) setCreateOperatorsError(result.data.detail);
            else {
                setCreateOperatorsError("Ocurrió un error inesperado.");
            }
            setIdValue(null);
            setNameValue(null);
            setLoading(false);
            return;
        }
        setIdValue(null);
        setNameValue(null);
        setTimeout(function(){ 
            setLoading(false);
        }, 500);
        await refreshOperators();
    };

    return (
        <Screen style={styles.screen}>
            {getOperatorsError && <>
                <AppText>No pudimos recuperar los operadores.</AppText>
                <AppButton title="Volver a procesar" onPress={refreshOperators}/>
            </>}
            <View style={{width: "90%", alignSelf:"center"}}>
            <ErrorMessage error={createOperatorsError}/>
            <AppForm
                initialValues={{ id: "", name: "" }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                <AppFormField
                    maxLength={5}
                    autoCapitalize="none"
                    autoCorrect={false}
                    icon="identifier"
                    keyboardType="numeric"
                    name="id"
                    placeholder="ID de Operador/Sucursal"
                    setState={setIdValue}
                    value={idValue}
                />
                <AppFormField
                    autoCapitalize="words"
                    autoCorrect={false}
                    icon="human"
                    name="name"
                    placeholder="Nombre del Operador"
                    setState={setNameValue}
                    value={nameValue}
                />
                <SubmitButton color = "argentina" title="Registrá Operador" loading={loading} disabled={loading || !nameValue || !idValue} />
            </AppForm>
            </View>
            {((endLoading || !loading) && operators && operators.length > 0) && 
            <FlatList
                data={operators}
                keyExtractor={message => message.id}
                renderItem={({ item }) =>
                    <ListItem
                        title={item.name}
                        description={`ID: ${item.id}`}
                        SecondaryIconComponent={item.type == 'secondary' && <AppText style={styles.deleteButton} onPress={() => deleteItem(item)}>X</AppText>}
                    />
                }
                ItemSeparatorComponent={ListItemSeparator}
                refreshing={refreshing}
                onRefresh={refreshOperators}
                onEndReached={() =>  {if(!onEndReachedCalledDuringMomentum){
                    setEndLoading(true);
                    loadOperators();
                    setOnEndReachedCalledDuringMomentum(true);
                }}}
                onEndReachedThreshold={0.01}
                ListFooterComponent={renderFooter}
                onMomentumScrollBegin={() => setOnEndReachedCalledDuringMomentum(false)}
            />}
            <ActivityIndicator visible={loading && !endLoading} source={require('../../assets/animations/circle-loader.json')}/>
        </Screen>
    );
}

export default OperatorsScreen;