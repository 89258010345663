import { StyleSheet } from 'react-native';
import colors from '../../config/colors';


const styles = StyleSheet.create({
    amountDueARS: {
        fontSize: 17,
    },
    amountDueBRL: {
        fontSize: 17,
        fontWeight: "bold",
        marginBottom: 10,
        justifyContent: 'flex-start',
        marginHorizontal: 20,
    },
    container: {
        flexDirection: "column",
        padding: 10,
    },
    divider: {
        height: 20,
    },
    message: {
        alignContent: "center",
        alignItems: "center",
        alignSelf:"center",
        justifyContent: "center",
        marginHorizontal: 10,
        flex: 1,
    },
    title: {
        marginTop: 10,
        fontSize: 22,
        fontWeight: "bold",
        textAlign: "center",
    },
    description: {
        marginTop: 10,
        fontSize: 16,
        textAlign: "center",
    },
    exchangeRate: {
        fontSize: 16,
        marginBottom: 10,
        justifyContent: 'flex-start',
        marginHorizontal: 30,
    },
    warning: {
        marginTop: 15,
        color: colors.danger,
        fontWeight:"bold",
        fontSize: 13,
        textAlign: "center",
    },
})

export default styles;