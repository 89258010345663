import { StyleSheet } from "react-native"
import colors from "../../config/colors";

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        backgroundColor: colors.primary,
        borderColor: colors.white,
        borderRadius: 40,
        borderWidth: 3,
        bottom: 20,
        height: 70,
        justifyContent: "center",
        width: 70,
    }
})

export default styles;