import React, { useState, useEffect } from 'react';
import { Linking, LogBox, Platform } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import { NavigationContainer } from '@react-navigation/native';
import VersionCheck from 'react-native-version-check-expo';
import semver from 'semver';
import * as ScreenOrientation from 'expo-screen-orientation';
import * as LocalAuthentication from 'expo-local-authentication';
import * as Device from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getInitialURL } from 'expo-linking';

import alert from './app/utility/alert';
import ApiInterceptor from './app/components/ApiInterceptor';
import AppNavigator from './app/navigation/AppNavigator';
import AuthNavigator from './app/navigation/AuthNavigator';
import InitialNavigator from './app/navigation/InitialNavigator';
import navigationTheme from './app/navigation/navigationTheme';
import OfflineNotice from './app/components/OfflineNotice';
import AuthContext from './app/auth/context';
import authStorage from './app/auth/storage';
import versionApi from './app/api/version';
import AppText from './app/components/AppText';
import colors from './app/config/colors';


LogBox.ignoreLogs(['new NativeEventEmitter()'])

SplashScreen.preventAutoHideAsync();

export default function App() {  
  const [user, setUser] = useState();
  const [isReady, setIsReady] = useState(false);
  const [supportedVersion, setSupportedVersion] = useState(true);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [initialUrl, setInitialUrl] = useState("");

  const checkWelcomeScreen = async () => {
    try {
      const welcomeShown = await AsyncStorage.getItem('welcomeShown');
      if (!welcomeShown || welcomeShown != 'yes'){
        setShowWelcomeScreen(true);
        await AsyncStorage.setItem('welcomeShown', 'yes');
      } else {
        setShowWelcomeScreen(false);
      }
    } catch (error) {
      console.error('Error checking welcome screen:', error);
    }
  };
  
  useEffect(() => {
    checkWelcomeScreen();
  }, [user]);

  useEffect(() => {
    const lockOrientation = async () => {
      try {
        const result = await Device.getDeviceTypeAsync();
        await ScreenOrientation.lockAsync(
          result === Device.DeviceType.TABLET
            ? ScreenOrientation.OrientationLock.LANDSCAPE
            : ScreenOrientation.OrientationLock.PORTRAIT_UP
        );
      } catch (error) {
        if (__DEV__) {
          console.log('Error locking orientation', error);
        }
      }
    };
    {Platform.OS !== "web" && lockOrientation()};
  }, []);

  const restoreUser = async () => {
    const user = await authStorage.getUser();
    if (user && !user.type) {
      setUser(null);
      authStorage.removeAccessToken();
      return (
        <AuthContext.Provider value={{ user, setUser }}>
          <ApiInterceptor></ApiInterceptor>
          <OfflineNotice />
          {supportedVersion && <NavigationContainer theme={navigationTheme}> 
              { <AuthNavigator/> }
          </NavigationContainer>}
          {!supportedVersion && <AppText style={{ color: colors.primary, marginTop: "100%", flex: 1, alignItems: "center", textAlign: "center", justifyContent: "center"}} onPress={() => Linking.openURL(marketUrl)}>Actualizar App</AppText>}
        </AuthContext.Provider>
      )
    }
    if (user) setUser(user);
    setIsReady(true);
  }

  const hideSplashScreen = async () => {
    await SplashScreen.hideAsync();
  }

  const marketUrl = Platform.select({android: "market://details?id=com.starfoodys.globalpix", ios: "https://apps.apple.com/br/app/pix-global/id6450020039"})


  useEffect(() => {
    if (Platform.OS !== "web"){
      const currentVersion = VersionCheck.getCurrentVersion();
      versionApi.getMinimumRecommendedVersion()
      .then((res) => {
        if (res.data){
          if (semver.lt(currentVersion, res.data.minimum_required_version)){
            setSupportedVersion(false);
            alert(
              "Esta Versión Ya No Es Compatible",
              "Actualice la aplicación, hay una versión más nueva disponible.",
              [
                { text: 'Actualizar',
                onPress: () => Linking.openURL(marketUrl)},
              ],
              { cancelable: false }
            );
          } else if(semver.lt(currentVersion, res.data.minimum_recommended_version)){
            alert(
              "Nueva Versión Disponible",
              "Actualice la aplicación, hay una versión más nueva disponible.",
              [
                { text: 'Actualizar',
                onPress: () => Linking.openURL(marketUrl)},
                {
                  text: 'Mantener Versión',
                }
              ],
              { cancelable: true }
            );
          }
        }
      })
      .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    restoreUser();
    (async () => {
      const compatible = await LocalAuthentication.hasHardwareAsync();
      await AsyncStorage.setItem('isBiometricEnabled', String(compatible));
    })();
  }, []);


  const setUrl = async () => {
    const initialUrl = await getInitialURL();
    setInitialUrl(initialUrl);
  }

  useEffect(() => {
    setUrl();
  }, []);

  useEffect(() => {
    hideSplashScreen();
  }, [isReady]);


  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <ApiInterceptor></ApiInterceptor>
      <OfflineNotice />
      {supportedVersion && <NavigationContainer theme={navigationTheme}> 
          { user ? <AppNavigator/> : ( (showWelcomeScreen && !(initialUrl?.split("/").pop())) ? <InitialNavigator initialUrl={initialUrl}/> : <AuthNavigator initialUrl={initialUrl}/> ) }
      </NavigationContainer>}
      {!supportedVersion && <AppText style={{ color: colors.primary, marginTop: "100%", flex: 1, alignItems: "center", textAlign: "center", justifyContent: "center"}} onPress={() => Linking.openURL(marketUrl)}>Actualizar App</AppText>}
    </AuthContext.Provider>
  );
}