import { Platform, StyleSheet } from 'react-native';
import colors from '../../config/colors';

const styles = StyleSheet.create({
    button: {
        width: "50%",
    },
    divider: {
        height: 20,
    },
    screen: {
        flex: 1,
    },
    textRow: {
        margin: 9,
        fontSize: 15,
        fontWeight: "bold",
        textAlign: "center",
    },
    dropdown1BtnStyle: {
        width: "80%",
        backgroundColor: colors.white,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.disabled,
        alignSelf: "center"
    },
    dropdown1BtnTxtStyle: {
        color: colors.dark, 
        textAlign: 'left',
    },
    dropdown1DropdownStyle: {
        backgroundColor: colors.lightGrey,
    },
    dropdown1RowStyle: {
        backgroundColor: colors.light,
        borderBottomColor: colors.disabled,
    },
    dropdown1RowTxtStyle: {
        color: colors.dark,
        textAlign: 'left'
    },
    keyText: {
        fontSize: 14,
        paddingTop: 10,
        textAlign: "center",
        fontWeight: "bold",
    }
})

export default styles;