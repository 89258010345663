import { StyleSheet } from 'react-native';

import colors from "../../config/colors";

const styles = StyleSheet.create({
    button: {
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        width: "100%",
        alignSelf: "center",
        marginVertical: 10,
    },
    text: {
        color: colors.white,
        fontSize: 18,
        textTransform: "uppercase",
        fontWeight: "bold",
        textAlign: "center",
    },
});

export default styles;