import { StyleSheet } from 'react-native';


const styles = StyleSheet.create({
    error: { 
        color: "red",
        flex: 0,
        fontSize: 12,
        textAlign: "center",
        fontWeight: "bold"
    }
})

export default styles;