import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";

import QRCodeScreen from '../screens/QRCodeScreen';
import CanceledChargeScreen from '../screens/CanceledChargeScreen';
import CopiaEColaScreen from '../screens/CopiaEColaScreen';
//import ScanScreen from '../screens/ScanScreen';
import AccountNavigator from './AccountNavigator';
import PaymentScreenNavigator from './PaymentScreenNavigator';
import PaymentOutConfirmationScreen from '../screens/PaymentOutConfirmationScreen';
import NewPaymentInScreen from '../screens/NewPaymentInScreen';


const Stack = createStackNavigator();


const NewPaymentNavigator = () => (
    <Stack.Navigator>
        <Stack.Screen name="Nuevo Pago" component={NewPaymentInScreen} options={{ headerShown: true }}/>
        <Stack.Screen name="QR Code" component={QRCodeScreen} options={{ headerShown: false }}/>
        <Stack.Screen name="Canceled Charge" component={CanceledChargeScreen} options={{ headerShown: false }}/>
        <Stack.Screen name="Copia e Cola" component={CopiaEColaScreen} options={{ headerShown: false }} />
        {/* <Stack.Screen name="Scan" component={ScanScreen} options={{ headerShown: false }} /> */}
        <Stack.Screen name="Confirmación de Pago" component={PaymentOutConfirmationScreen} options={{ headerLeft: null }} />
        <Stack.Screen name="Account Navigator" component={AccountNavigator} options={{ headerShown: false }} />
    </Stack.Navigator>
);

export default NewPaymentNavigator;