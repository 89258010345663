import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        marginBottom: 20,
        marginHorizontal: 20,
    },
    text: {
        fontSize: 16,
    },
    timeLeftText: {
        fontSize: 16,
        fontWeight: "bold",
    }
})


export default styles;