import React from 'react';
import { Modal, Pressable, View } from 'react-native';
import * as Clipboard from 'expo-clipboard';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import AppButton from '../AppButton';
import { TouchableOpacity } from 'react-native';
import AppTextInput from '../AppTextInput';


const DOSAM_CBU = '3220001805020028920013';

const copyToClipboard = async () => {
    await Clipboard.setStringAsync(DOSAM_CBU);
};

const PendingRefundModal = ({ modalVisible, outsidePressHandler, referenceId, valueDestination = null }) => {


  return (
    <Modal animationType="slide" visible={modalVisible} transparent={false}>
      <Pressable style={styles.container}>
        <View style={styles.modal}>
          <AppText style={{ fontSize: 20, color: colors.white, marginTop: 10, marginBottom: 20, textAlign: 'center' }}>Instrucciones para Devolución Manual</AppText>
          {valueDestination && <AppText style={{fontSize: 14, color: colors.white, marginBottom: 15, textAlign: 'center' }}>El importe a devolver excede sus límites actuales para reembolsos instantáneos. Favor enviar el importe de <AppText style={{fontSize: 14, fontWeight: "bold", color: colors.white}}>{valueDestination} ARS</AppText> al siguiente CBU:</AppText>}
          <TouchableOpacity style={{alignSelf: "stretch"}} onPress={copyToClipboard}>
            <AppTextInput textSize={15} icon="content-copy" editable={false} value={DOSAM_CBU}/> 
          </TouchableOpacity>
          <AppText style={{fontSize: 12, color: colors.white, marginBottom: 2, textAlign: 'center' }}>Banco Industrial</AppText>
          <AppText style={{fontSize: 12, color: colors.white, marginBottom: 20, textAlign: 'center' }}>Beneficiario: DOSAM 15 SA</AppText>
          <AppText style={{fontSize: 14, color: colors.white, marginBottom: 20, textAlign: 'center' }}>Luego de transferir, enviar el recibo y ID de referencia <AppText style={{fontSize: 14, fontWeight: "bold", color: colors.white}}>{referenceId}</AppText> a la dirección <AppText style={{fontSize: 14, fontWeight: "bold", color: colors.white}}>hola@pixglobal.io</AppText>. El importe en reales será devuelto al cliente dentro de 1 día hábil posterior.</AppText>
          <AppText style={{fontSize: 14, color: colors.white, marginBottom: 10, textAlign: 'center' }}>Para habilitar o incrementar sus límites de reembolsos instantáneos, no dude en contactar con nosotros.</AppText>
          <AppButton onPress={outsidePressHandler} title={"OK"} color="pending"/>
        </View>
      </Pressable>
    </Modal>
  );
};

export default PendingRefundModal;