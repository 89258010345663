import React, { useEffect, useRef } from 'react';
import { Dimensions, Platform, SafeAreaView } from 'react-native';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as Notifications from 'expo-notifications';

import alert from '../utility/alert';
import AccountNavigator from './AccountNavigator';
import NewPaymentButton from './NewPaymentButton';
import NewPaymentNavigator from './NewPaymentNavigator';
import PaymentsScreen from './../screens/PaymentsScreen';
import routes from './routes';
import AppText from '../components/AppText';
import operatorsApi from '../api/operators';
import useAuth from '../auth/useAuth';
import { useNavigation } from '@react-navigation/native';

const { height } = Dimensions.get("screen");
const Tab = createBottomTabNavigator();


Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true
    }),
});

async function registerForPushNotificationsAsync(user) {
    let token;

    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
    }
    if (finalStatus !== 'granted') {
        alert('No has permitido las notificationes push.');
        return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    return token;
}

const AppNavigator = () => {
    const { user } = useAuth();

    if (Platform.OS !== "web"){

        const notificationListener = useRef();
        const responseListener = useRef();
        const navigation = useNavigation();

        useEffect(() => {
            const companyId = user.type == 'master' ? user.id : user.company_id;
            registerForPushNotificationsAsync(user).then(token => operatorsApi.addPushToken(companyId, user.id, {"push_token": token}));
            responseListener.current = Notifications.addNotificationResponseReceivedListener(notification => {
                const payout_method = notification.notification.request.content.data.payout_method;
                navigation.navigate(routes.PAYMENTS_TAB, {isToggleInitiallyEnabled: (payout_method === "crypto" ? true : false)});
            });
    
            notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                if (navigation.getCurrentRoute().name == "QR Code"){
                    const navigationParams =  navigation.getCurrentRoute().params;
                    const currentTxid = navigationParams["txid"];
                    const currentReferenceId = navigationParams["referenceId"];
                    const amountDueARS = navigationParams["amountDueARS"];
                    const amountDueBRL = navigationParams["amountDueBRL"];
                    const amountUSDC = navigationParams["amountUSDC"];
                    const qrCode = navigationParams["qrCode"];
                    const exchangeRate = navigationParams["exchangeRate"]
    
                    const notificationTxid = notification.request.content.data.txid;
                    const expires_in_seconds = notification.request.content.data.expires_in_seconds;
                    if (notificationTxid == currentTxid && expires_in_seconds < 10000) { // instant charge
                        navigation.navigate("QR Code", {
                            txid: currentTxid,
                            qrCode: qrCode,
                            exchangeRate: exchangeRate,
                            amountDueARS: amountDueARS,
                            amountDueBRL: amountDueBRL,
                            amountUSDC: amountUSDC,
                            referenceId: currentReferenceId,
                            pixPaidNotificationReceived: true,
                        })
                    }
                }
            });
    
            // Unsubscribe from events
            return () => {
                Notifications.removeNotificationSubscription(responseListener.current);
            };
        }, []);
    }

    return <SafeAreaView style={{
        width: "100%",
        height: "100%",
    }}>
        <Tab.Navigator>
            <Tab.Screen
                name={routes.PAYMENTS_TAB}
                component={PaymentsScreen}
                options={() => ({
                    tabBarIcon: ({ color, size }) => <MaterialCommunityIcons name="cash-check" color={color} size={size}/>,
                    tabBarLabel: "Pagos",
                    headerTitle: "",
                    headerRight: () => (
                        user.type == 'master' ?
                        <AppText style={{fontSize: 14, paddingRight: "8%"}}>
                            Exportar CSV
                        </AppText> : 
                        <AppText style={{fontSize: 14, paddingRight: "8%"}}>
                            Cerrar Lote
                        </AppText> 
                    )
                })}
                initialParams={{isToggleInitiallyEnabled: false}}
            />
            <Tab.Screen
                name={routes.NEW_PAYMENT_TAB}
                component={NewPaymentNavigator}
                options={({ navigation }) => ({
                    tabBarButton: () => <NewPaymentButton onPress={
                        () => navigation.navigate(routes.NEW_PAYMENT_TAB, { screen: 'Nuevo Pago' })
                    }/>,
                    headerShown: false,
                    tabBarLabel: "Nuevo Pago",
                    headerTitle: "Nuevo Pago",
                    unmountOnBlur: true,
                })}
            />
            <Tab.Screen 
                name={routes.ACCOUNT_TAB}
                component={AccountNavigator}
                options={{
                    tabBarIcon: ({ color, size }) => <MaterialCommunityIcons name="account" color={color} size={size}/>,
                    headerShown: false,
                    tabBarLabel: "Mi Cuenta",
                    headerTitle: "Mi Cuenta",
                    unmountOnBlur: true,
                }}
            />
        </Tab.Navigator>
    </SafeAreaView>
};

export default AppNavigator;