import { Platform, StyleSheet } from 'react-native';
import colors from '../../config/colors';

const styles = StyleSheet.create({
    container: {
        padding: 10,
        flex: 1,
    },
    text: {
        textAlign: "center",
        fontSize: 24,
    },
    footer: {
        marginTop: 2,
    },
    dropdown1BtnStyle: {
        width: "100%",
        backgroundColor: colors.white,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.disabled,
    },
    dropdown1BtnTxtStyle: {
        color: colors.dark, 
        textAlign: 'left'
    },
    dropdown1DropdownStyle: {
        backgroundColor: colors.lightGrey,
    },
    dropdown1RowStyle: {
        backgroundColor: colors.light,
        borderBottomColor: colors.disabled,
    },
    dropdown1RowTxtStyle: {
        color: colors.dark,
        textAlign: 'left'
    },
    dropdown2BtnStyle: {
        width: Platform.OS == "web" ? "16%" : "30%",
        height: "100%",
        backgroundColor: colors.light,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.light,
    },
    dropdown2BtnTxtStyle: {
        color: colors.dark, 
        textAlign: Platform.OS == "web" ? 'left' : "center",
    },
    dropdown2RowTxtStyle: {
        color: colors.dark,
        textAlign: 'center'
    },
    divider: {
        height: 15,
    },
    switch: {
        transform: [{ scaleX: Platform.select({android: 1, ios: 0.75})}, { scaleY: Platform.select({android: 1, ios: 0.75}) }]
    },
    switchContainer: {
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
    }
})

export default styles;