import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import colors from '../../config/colors';
import styles from './styles';


function NewPaymentButton({ onPress }) {
    return (
        <TouchableOpacity onPress={onPress}>
            <View style={styles.container}>
                <MaterialCommunityIcons name = "plus-circle" color={colors.white} size={40}/>
            </View>
        </TouchableOpacity>
    );
}

export default NewPaymentButton;