import React from 'react';
import { View } from 'react-native';

import styles from './styles';
import Screen from '../../components/Screen';
import AppText from '../../components/AppText';
import { CanceledChargeIndicator } from '../../components/indicators';


function CanceledChargeScreen() {

    return <Screen style={styles.container}>
      <View style={styles.canceledMessage}>
        <AppText style={styles.title}>¡Solicitud de pago cancelada!</AppText>
        <CanceledChargeIndicator/>
        <AppText style={styles.description}>Debido a la inactividad de la aplicación, este pago ya no se puede procesar. Por favor generá uno nuevo.</AppText>
      </View>
    </Screen>
}

export default CanceledChargeScreen;