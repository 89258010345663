import React, { useEffect, useState } from 'react';
import { LogBox, FlatList, Modal, Pressable, ScrollView, TouchableOpacity, View, Platform } from 'react-native';
import { RadioButton } from 'react-native-paper';
import FileSaver from 'file-saver';

// styled components
import alert from '../../utility/alert';
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import AppButton from '../AppButton';
import { ListItemSeparator } from '../lists';
import useAuth from '../../auth/useAuth';
import usersApi from '../../api/users';


const CSVExportModal = ({ modalVisible, outsidePressHandler, headerText, buttonText, payoutMethod }) => {
  useEffect(() => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  }, [])
  
  const { user } = useAuth();

  const [checked, setChecked] = useState(null);
  const [loading, setLoading] = useState(false);

  const createdAtDate = new Date(user.created_at);
  const createdAtMonth = createdAtDate.getMonth();
  const createdAtYear = createdAtDate.getFullYear();

  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];;
  const date = new Date();
  let currentMonth = date.getMonth();
  let currentYear = date.getFullYear();

  let radio_props = [];

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getExportableMonthsRadioProps = () => {
    var start_date_local;
    var end_date_local;
    while (currentYear >= createdAtYear) {
      if (currentYear > createdAtYear){
        while (currentMonth >= 0) {
          start_date_local = new Date(currentYear + "-" + String(currentMonth+1).padStart(2, '0') + "-01T00:00")
          end_date_local = new Date(currentYear + "-" + String(currentMonth+1).padStart(2, '0') + "-" + getDaysInMonth(currentMonth, currentYear).toString() + "T23:59:59.999")
          radio_props.push(
            {
              "value": [start_date_local, end_date_local],
              "label": months[currentMonth] + " " + currentYear,
              "label_condensed": months[currentMonth] + "_" + currentYear,
            }
          );
          currentMonth--;
       }
      } else {
        while (currentMonth >= createdAtMonth) {
          start_date_local = new Date(currentYear + "-" + String(currentMonth+1).padStart(2, '0') + "-01T00:00")
          end_date_local = new Date(currentYear + "-" + String(currentMonth+1).padStart(2, '0') + "-" + getDaysInMonth(currentMonth, currentYear).toString() + "T23:59:59.999")
          radio_props.push(
            {
              "value": [start_date_local, end_date_local],
              "label": months[currentMonth] + " " + currentYear,
              "label_condensed": months[currentMonth] + "_" + currentYear,
            }
          );
          currentMonth--;
        }
      }
      currentMonth = 11;
      currentYear--;
    }
    return radio_props;
  }

  const exportableMonthsRadioProps = getExportableMonthsRadioProps();

  const onPressOut = () => {
    outsidePressHandler();
    setChecked(null);
  }

  const exportHandler = async () => {
    const companyId = user.type == 'master' ? user.id : user.company_id;
    let paymentSummaryResponse;
    setLoading(true);
    paymentSummaryResponse = await usersApi.getPaymentSummary(companyId, exportableMonthsRadioProps[checked].value[0].toISOString(), exportableMonthsRadioProps[checked].value[1].toISOString(), payoutMethod);
    if (!paymentSummaryResponse.ok) {
      setTimeout(async () => {
        paymentSummaryResponse = await usersApi.getPaymentSummary(companyId, exportableMonthsRadioProps[checked].value[0].toISOString(), exportableMonthsRadioProps[checked].value[1].toISOString(), payoutMethod);
      }, 3000)
    }
    setLoading(false);
    outsidePressHandler();
    setChecked(null);
    if (paymentSummaryResponse.ok){
      if (Platform.OS == "web"){
        var fileData = new Blob([paymentSummaryResponse.data], {type: "application/zip"});
        FileSaver.saveAs(fileData, `pagos_pixglobal_${exportableMonthsRadioProps[checked].label_condensed}.zip`);
        return alert("Reporte Descargado Exitosamente", "Pagos de " + exportableMonthsRadioProps[checked].label + " se han descargado exitosamente.")
      } else {
        return alert("Verifique su correo electrónico", "Pagos de " + exportableMonthsRadioProps[checked].label + " han sido enviados a su correo y a su contador(a).")
      }
    }
    return alert("Se ha producido un error", "Por favor inténtelo de nuevo en breve.")
  }
  
  return (
    <Modal swipeDirection={['down']} propagateSwipe={true} animationType="slide" visible={modalVisible} transparent={true}>
      <Pressable style={styles.container} onPressOut={onPressOut}>
        <View style={styles.scrollableModal}>
          <AppText style={{ fontSize: 25, color: colors.white, marginVertical: 10, textAlign: 'center' }}>{headerText}</AppText>
            <ScrollView style={styles.scrollView}>
              <View style={{flex: 1}} onStartShouldSetResponder={() => true}>
                  <FlatList
                    data={exportableMonthsRadioProps}
                    keyExtractor={message => message.label}
                    renderItem={
                      ({ item, index }) => 
                      <TouchableOpacity style={{flexDirection:"row", alignItems: 'center', paddingLeft: 15, paddingBottom: 7}} onPress={() => setChecked(index)}>
                        <RadioButton
                          value={item.value}
                          status={ checked === index ? 'checked' : 'unchecked' }
                          onPress={() => setChecked(index)}
                        />
                        <AppText style={{color: "white"}}>{item.label}</AppText>
                      </TouchableOpacity>
                    }
                    ItemSeparatorComponent={ListItemSeparator}
                    refreshing={false}
                />
              </View>
            </ScrollView>
          <AppButton disabled={checked === null || loading} onPress={exportHandler} loading={loading} title={buttonText} color="secondary"/>
        </View>
      </Pressable>
    </Modal>
  );
};

export default CSVExportModal;