import client from './client';
import bufferClient from './bufferClient';
import { Platform } from 'react-native';

const createConversion = (companyId, createConversionInput) => client.post(`/user/${companyId}/conversion`, createConversionInput);
const get = (companyId) => client.get(`/user/${companyId}`);
const getPayments = (companyId, operatorId, payoutMethod, pageSize, nextPage) => client.get(`/user/${companyId}/payments?payout_method=${payoutMethod}&page_size=${pageSize}` + (nextPage ? (`&next_page=${encodeURIComponent(nextPage)}`) : '') + (operatorId ? (`&operator_id=${operatorId}`) : ''));
const getUserRefundDebts = (companyId) => client.get(`/user/${companyId}/refund-debts`);
const deleteUser = (companyId, deleteCompanyInput) => client.delete(`/user/${companyId}`, deleteCompanyInput);
const login = (user_id, password) => client.post('/user/session', { user_id, password });
const register = (userInfo) => client.post("/user", userInfo);
const resetPassword = (companyId, resetPasswordInput) => client.post(`/user/${companyId}/reset-password`, resetPasswordInput);
const sendResetPasswordOTPEmail = (companyId) => client.post(`/user/${companyId}/send-reset-password-otp-email`);
const sendVerificationOTPEmail = (companyId) => client.post(`/user/${companyId}/send-verification-otp-email`);
const sendUpdateConfirmOTPEmail = (companyId) => client.post(`/user/${companyId}/send-update-confirm-otp-email`);
const sendDeleteConfirmOTPEmail = (companyId) => client.post(`/user/${companyId}/send-delete-confirm-otp-email`);
const update = (companyId, updateCompanyInput) => client.patch(`/user/${companyId}`, updateCompanyInput);
const verifyEmail = (companyId, verifyEmailInput) => client.post(`/user/${companyId}/verify-email`, verifyEmailInput);


let getPaymentSummary;

if (Platform.OS == "web"){
    getPaymentSummary = (companyId, startDate, endDate, payoutMethod) => bufferClient.get(`/user/${companyId}/payment-summary?payout_method=${payoutMethod}&start_date=${startDate}&end_date=${endDate}&export_method=direct`);
} else {
    getPaymentSummary = (companyId, startDate, endDate, payoutMethod) => client.get(`/user/${companyId}/payment-summary?payout_method=${payoutMethod}&start_date=${startDate}&end_date=${endDate}`);
}

export default {
    createConversion,
    get,
    getPayments,
    getPaymentSummary,
    getUserRefundDebts,
    deleteUser,
    login,
    register,
    resetPassword,
    sendResetPasswordOTPEmail,
    sendVerificationOTPEmail,
    sendUpdateConfirmOTPEmail,
    sendDeleteConfirmOTPEmail,
    update,
    verifyEmail,
};