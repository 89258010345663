import React, { useEffect, useState } from 'react';
import Screen from '../../components/Screen';
import AppText from '../../components/AppText';
import styles from './styles';
import { Platform, TouchableOpacity, View } from 'react-native';
import colors from '../../config/colors';
import { FontAwesome } from '@expo/vector-icons';
import Icon from '../../components/Icon';
import SelectDropdown from 'react-native-select-dropdown';
import ConversionModal from '../../components/ConversionModal';
import DepositOptionsModal from '../../components/DepositOptionsModal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as LocalAuthentication from 'expo-local-authentication';
import AppButton from '../../components/AppButton';
import { AppForm, AppFormField, ErrorMessage } from '../../components/forms';
import conversionApi from '../../api/conversion';
import interApi from '../../api/inter';
import usersApi from '../../api/users';
import MessageModal from '../../components/MessageModal';
import useAuth from '../../auth/useAuth';
import { getStringFloatFromRawInput, prettifyToString } from '../../utility/utils';
import alert from '../../utility/alert';


const currencyOptions = [
    "ARS", "BRL", "USDC"
]

function PaymentOutConfirmationScreen({navigation, route}) { 

    const { user } = useAuth();

    const [loading, setLoading] = useState(false);
    const [inputCurrency, setInputCurrency] = useState(null);
    const [inputAmount, setInputAmount] = useState("0.00");
    const [chargeAmount, setChargeAmount] = useState(0);
    const [count, setCount] = useState(0);
    const [exchangeRateBrlArs, setExchangeRateBrlArs] = useState(null);
    const [exchangeRateBrlArsString, setExchangeRateBrlArsString] = useState();
    const [exchangeRateBrlUsdc, setExchangeRateBrlUsdc] = useState(null);
    const [exchangeRateBrlUsdcString, setExchangeRateBrlUsdcString] = useState();
    const [exchangeRateArsUsdc, setExchangeRateArsUsdc] = useState(null);
    const [exchangeRateArsUsdcString, setExchangeRateArsUsdcString] = useState();
    const [userBalances, setUserBalances] = useState();
    const [balanceError, setBalanceError] = useState(null);

    const { amountBrl, receiver, fiscalKey, pixKey, emv } = route.params;

    // Conversion modal
    const [conversionModalVisible, setConversionModalVisible] = useState(false);

    // Deposit Options modal
    const [depositOptionsModalVisible, setDepositOptionsModalVisible] = useState(false);

    // Message Modal
    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [messageModalMessageType, setMessageModalMessageType] = useState('');
    const [messageHeaderText, setMessageHeaderText] = useState('');
    const [messageModalMessage, setMessageModalMessage] = useState('');
    const [messageButtonText, setMessageButtonText] = useState('');

    const showModal = (type, headerText, message, buttonText) => {
        setMessageModalMessageType(type);
        setMessageHeaderText(headerText);
        setMessageModalMessage(message);
        setMessageButtonText(buttonText);
        setMessageModalVisible(true);
    };

    const buttonHandler = async () => {
        if (messageModalMessageType === 'idleNotification'){
            setCount(0);
        }
        setMessageModalVisible(false);
    };

    const outsidePressHandler = () => {
        setMessageModalVisible(false);
        if (messageModalMessageType === 'idleNotification'){
            setCount(0);
        }
    }

    const convertAmountCeil = (amount) => {
        return (Math.ceil(amount*100)/100).toFixed(2);
    };

    useEffect(() => {
        let amount;
        if (amountBrl){
            amount =  amountBrl
        } else {
            amount = parseFloat(getStringFloatFromRawInput(inputAmount))
        }

        if (inputCurrency == "BRL"){
            setChargeAmount(convertAmountCeil(amount*1.002))
        } else if (inputCurrency == "ARS"){
            setChargeAmount(convertAmountCeil(amount * exchangeRateBrlArs))
        } else if (inputCurrency == "USDC") {
            setChargeAmount(convertAmountCeil(amount / exchangeRateBrlUsdc))
        }
    }, [inputAmount, inputCurrency, count]);

    const MINUTE_SECONDS_MS = 60000;

    let timerId;
  
    const clear = () => {
        clearTimeout(timerId);
    }
  
    useEffect(() => {
        if (count == 10){
            showModal('idleNotification', 'Aplicación Inactiva', 'La aplicación ha estado inactiva durante 10 minutos, por lo que hemos pausado la actualización automática de precios.', 'Ok, Ya Estoy de Vuelta');
            return clear();
        }
        conversionApi.getConversionRate()
            .then((res) => {
                const exchangeRate = res.data.payout.exchange_rate.toFixed(2);
                const exchangeRateBRLUSDC = res.data.payout.exchange_rate_usdc.toFixed(2);
                const exchangeRateARSUSDC = res.data.payout.exchange_rate_ars_usdc.toFixed(2);
                setExchangeRateBrlArs(exchangeRate);
                setExchangeRateBrlArsString(`1 BRL = ${exchangeRate} ARS`);
                setExchangeRateBrlUsdc(exchangeRateBRLUSDC);
                setExchangeRateBrlUsdcString(`1 USDC = ${exchangeRateBRLUSDC} BRL`);
                setExchangeRateArsUsdc(exchangeRateARSUSDC);
                setExchangeRateArsUsdcString(`1 USDC = ${exchangeRateARSUSDC} ARS`);
                timerId = setTimeout(() => {setCount(count + 1)}, MINUTE_SECONDS_MS);
            })
            .catch((error) => console.log(error));
        return clear();
    }, [count]);

    const handleBiometricAuth = async () => {
        const savedBiometrics = await LocalAuthentication.isEnrolledAsync();
          if (!savedBiometrics){
            return alert(
                'Datos biométricos no disponibles',
                'Por favor ingrese su PIN',
                'OK',
                () => {}
            );
          } else {
            const biometricAuth = await LocalAuthentication.authenticateAsync({
                promptMessage: 'Ingresar Biometria',
                disableDeviceFallback: true,
                cancelLabel: 'Ingresar con PIN'
            });
            if (!biometricAuth.success){
                return alert(
                    'Datos biométricos no reconocidos',
                    'Por favor ingrese su PIN',
                    'OK',
                    () => {console.log("PORRA")}
                );
            }
          }
    }

    const handleSubmit = async () => {
        if (userBalances && (userBalances[inputCurrency] < chargeAmount)){
            return showModal('failed', "¡Saldo Insuficiente!", `El saldo de ${inputCurrency} no es suficiente para realizar el pago. Por favor agregar fondos o elegir otra moneda.`, "Ok")
        }

        const isBiometricEnabled = await AsyncStorage.getItem('isBiometricEnabled');
        if (isBiometricEnabled == 'true'){
            await handleBiometricAuth();
            setLoading(true);
            let amount;
            if (amountBrl){
                amount = amountBrl
            } else {
                amount = parseFloat(inputAmount)
            }

            let result;
    
            if (emv) {
                result = await interApi.payout(
                    companyId=user.id,
                    amount=amount,
                    valuePaid=chargeAmount,
                    currencyPaid=inputCurrency,
                    receiverName=receiver,
                    destinationPixKey=pixKey,
                    paymentEmv=emv,
                )
            } else {
                result = await interApi.payout(
                    companyId=user.id,
                    amount=amount,
                    valuePaid=chargeAmount,
                    currencyPaid=inputCurrency,
                    receiverName=receiver,
                    destinationPixKey=pixKey,
                    paymentEmv=null,
                );
            }
    
    
            if (!result.ok) {
                setLoading(false);
                return alert("error");
            }
            setLoading(false);
            return alert("success");
        }
    };

    const getUserBalances = async () => {
        setLoading(true);
        usersApi.get(user.id)
        .then((res) => {
            setUserBalances({
                "ARS": res.data.balance_ars,
                "BRL": res.data.balance_brl,
                "USDC": res.data.balance_usdc,
            })
        })
        .catch((error) => {console.log(error); setLoading(false)});
        setLoading(false);
    }

    useEffect(() => {
        if (userBalances && (userBalances[inputCurrency] < chargeAmount)){
            setBalanceError(`El saldo de ${inputCurrency} no es suficiente para realizar el pago.`)
        } else {
            setBalanceError(null);
        }
    }, [userBalances, chargeAmount]);

    return (
        <Screen>
            <View style={styles.infoContainer}>
                <AppText style={styles.key}>Monto a pagar:</AppText>
                {amountBrl ? 
                    <AppText style={styles.value}>{amountBrl.toFixed(2)} BRL</AppText> : 
                    <AppForm initialValues={{amountBrl: ""}}>
                        <AppFormField
                            masked={true}
                            type="currency"
                            options={{
                                prefix: "BRL  ",
                                decimalSeparator: '.',
                                groupSeparator: ',',
                                precision: 2
                            }}
                            keyboardType="numeric"
                            maxLength={16}
                            name="amountBrl"
                            setState={setInputAmount}
                            width={120}
                    />
                    </AppForm>
                }
            </View>
            <View style={styles.infoContainer}>
                <AppText style={styles.key}>Receptor:</AppText>
                <AppText style={styles.value}>{receiver}</AppText>
            </View>
            <View style={styles.infoContainer}>
                <AppText style={styles.key}>Clave Fiscal / Clave Pix</AppText>
                <AppText style={styles.value}>{fiscalKey || pixKey}</AppText>
            </View>
            <View style={styles.infoContainer}>
                <AppText style={styles.key}>Pagar con:</AppText>
                <SelectDropdown
                    data={userBalances && currencyOptions}
                    defaultValue={""}
                    onSelect={(selectedItem) => {
                        setInputCurrency(selectedItem)
                    }}
                    onFocus={() => getUserBalances()}
                    defaultButtonText={<AppText style={{'color': colors.medium}}>Moneda de Pago</AppText>}
                    buttonTextAfterSelection={(selectedItem) => {
                        return <AppText style={{fontSize: 16}}>{selectedItem && `${selectedItem} (Saldo: ${prettifyToString(userBalances[selectedItem])} ${selectedItem})`}</AppText>
                    }}
                    rowTextForSelection={(item) => {
                        if (item == 'BRL'){
                            return <AppText style={{fontSize: 16}}>{`${item} (Saldo: ${prettifyToString(userBalances[item])} ${item}) + tasa 2%`}</AppText>
                        } else {
                            return <AppText style={{fontSize: 16}}>{`${item} (Saldo: ${prettifyToString(userBalances[item])} ${item})`}</AppText>
                        }
                    }}
                    buttonStyle={styles.dropdown2BtnStyle}
                    buttonTextStyle={styles.dropdown2BtnTxtStyle}
                    renderDropdownIcon={isOpened => {
                    if (Platform.OS == "web"){
                        return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} iconColor={colors.dark} backgroundColor='transparent' size={46} />;
                    } else {
                        return <FontAwesome name={isOpened ? 'chevron-up' : 'chevron-down'} color={colors.dark} size={14} />
                    }
                    }}
                    dropdownIconPosition={'left'}
                    dropdownStyle={styles.dropdown1DropdownStyle}
                    rowStyle={styles.dropdown1RowStyle}
                    rowTextStyle={styles.dropdown2RowTxtStyle}
                />
                {balanceError && <View style={{marginHorizontal: 10}}>
                    <ErrorMessage error={balanceError} visible={balanceError} />
                </View>}
                <View style={{marginTop: 15, flexDirection:"row", justifyContent:"space-evenly"}}>
                    <TouchableOpacity onPress={() => setDepositOptionsModalVisible(true)}>
                        <AppText style={styles.balanceOption}>Depositar</AppText> 
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => setConversionModalVisible(true)}>
                        <AppText style={styles.balanceOption}>Convertir Monedas</AppText> 
                    </TouchableOpacity>
                </View>
            </View>
            <View style={styles.infoContainer}>
                <AppText style={styles.key}>Monto a cobrar{inputCurrency == "BRL" && " + Tarifa 2%"}:</AppText>
                {inputCurrency ?
                    <AppText style={styles.value}>{prettifyToString(chargeAmount)} {inputCurrency}</AppText> :
                    <AppText style={styles.value}>-</AppText>
                }
            </View>
            <View style={{width: "80%", alignSelf:"center"}}>
                <AppButton onPress={handleSubmit} title="Pagar" disabled={!inputCurrency || loading}/>
            </View>
            <View style={styles.exchange}>
                <AppText style={{textAlign:"center", fontSize: 12}}>{exchangeRateArsUsdcString}</AppText>
                <AppText style={{textAlign:"center", fontSize: 12}}>{exchangeRateBrlArsString}</AppText>
                <AppText style={{textAlign:"center", fontSize: 12}}>{exchangeRateBrlUsdcString}</AppText>
            </View>
            <ConversionModal
                modalVisible={conversionModalVisible}
                outsidePressHandler={() => setConversionModalVisible(false)}
            />
            <DepositOptionsModal
                modalVisible={depositOptionsModalVisible}
                outsidePressHandler={() => setDepositOptionsModalVisible(false)}
            />
            <MessageModal
                modalVisible={messageModalVisible}
                buttonHandler={buttonHandler}
                outsidePressHandler={outsidePressHandler}
                type={messageModalMessageType}
                headerText={messageHeaderText}
                message={messageModalMessage}
                buttonText={messageButtonText}
            />
        </Screen>
    );
}

export default PaymentOutConfirmationScreen;