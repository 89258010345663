import { Dimensions, Platform, StyleSheet } from 'react-native';
import Constants from 'expo-constants';

const { statusBarHeight } = Constants;
const screenWidth = Dimensions.get('screen').width;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 25,
        paddingTop: statusBarHeight,
        alignContent: "center",
        alignItems: "center",
    },
    cell: {
        width: 40,
        height: 40,
        lineHeight: 38,
        borderWidth: 2,
        borderColor: '#00000030',
        textAlign: 'center',
        margin: Platform.OS === "web" ? 1 : (screenWidth * 0.05),
    },
    codeFieldRoot: {
        margin: 20,
    },
    focusCell: {
        borderColor: '#000',
    },
})

export default styles;