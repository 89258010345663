import {useEffect} from 'react';

import apiClient from '../../api/client';
import authStorage from '../../auth/storage';
import useAuth from '../../auth/useAuth';

function ApiInterceptor(props) {
    const { logOut } = useAuth();

    const addTransformers = () => {

        const requestTransformers = apiClient.asyncRequestTransforms.length;
        const responseTransformers = apiClient.asyncResponseTransforms.length;
        const transformersAdded = requestTransformers + responseTransformers > 1;

        if (!transformersAdded) {
            apiClient.addAsyncRequestTransform(async (request) => {
                const accessToken = await authStorage.getAccessToken();
                if (!accessToken) return;
                request.headers["Authorization"] = "Bearer " + accessToken;
            });
            
            apiClient.addAsyncResponseTransform(async (response) => {
                if(!["/operator/session", "/inter/refund"].includes(response.config.url) && (response.status === 401 || response.status === 403)){
                    const accessToken = await authStorage.getAccessToken();
                    apiClient.setHeader("Authorization", "Bearer " + accessToken)
                    const res = await apiClient.post('/operator/session/refresh');
                    if (!res.ok || (res.status && (res.status === 401 || res.status === 403 || res.status === 500))) {
                        logOut();
                    } else {
                        const accessToken = res.data["access_token"];
                        await authStorage.storeAccessToken(accessToken);
                        // retry
                        const result = await apiClient.any(response.config)
                        // replace data
                        response.ok = result.ok;
                        response.data = result.data;
                    }
                }
            })
        }
    }

    useEffect( () => {
        addTransformers();
    }, []);

    return null;
}

export default ApiInterceptor;