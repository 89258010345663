import { useKeepAwake } from 'expo-keep-awake';
import React, { useEffect, useRef, useState } from 'react';
import CountryFlag from "react-native-country-flag";
import { Dimensions, Image, Platform, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import CountDown from 'react-native-countdown-accurate';
import * as ScreenOrientation from 'expo-screen-orientation';


import colors from '../../config/colors';
import styles from './styles';
import Screen from '../../components/Screen';
import { DoneIndicator, ExpiredIndicator } from '../../components/indicators';
import AppText from '../../components/AppText';
import interApi from '../../api/inter';
import Icon from '../../components/Icon';
import { useNetInfo } from '@react-native-community/netinfo';



function QRCodeScreen({ route, navigation }) {
    {Platform.OS !== "web" && useKeepAwake()};

    const lottiePaidRef = useRef(null);
    const lottieExpiredRef = useRef(null);
    const wsRef = useRef(); 

    const { amountDueARS, amountDueBRL, amountUSDC, txid, qrCode, exchangeRate, referenceId, pixPaidNotificationReceived = false, preferredQrCodeType } = route.params;
    const [ pixPaid, setPixPaid ] = useState(pixPaidNotificationReceived);
    const [ pixExpired, setPixExpired ] = useState(false);
    const [ connectionWasOpened, setConnectionWasOpened ] = useState(false);
    const [qrCodeSize, setQrCodeSize] = useState(0);
    const [timerSize, setTimerSize] = useState(0);
    const [partialAmountPaid, setPartialAmountPaid] = useState(0);

    
    const netInfo = useNetInfo();

    if (netInfo.type !== "unknown" && netInfo.isInternetReachable === false){
      //interApi.expireCharge(txid);
      //navigation.navigate('Canceled Charge');
      wsRef.current = new WebSocket(`wss://pixster-server-olsn46r5ua-uc.a.run.app/api/v1/websocket/pix/${txid}/ws`);
    }

    const isMounted = useRef(false); // unmounted by default

    useEffect(() => {
      const dimensions = Dimensions.get('window');
      if (Platform.OS === "web"){
        setQrCodeSize(0.4*dimensions.height);
        setTimerSize(0.03*dimensions.height);
      } else {
        ScreenOrientation.getOrientationAsync().then((data) => {
          if (data > 2){
            setQrCodeSize(0.45*dimensions.height);
            setTimerSize(0.025*dimensions.height);
          } else {
            setQrCodeSize(0.8*Math.min(dimensions.width, dimensions.height));
            setTimerSize(0.045*Math.min(dimensions.width, dimensions.height));
          }
        });
      }
      
      if (pixPaidNotificationReceived){
        lottiePaidRef.current?.play();
      }
      isMounted.current = true; // mounted
      return () => {
        isMounted.current = false; // unmounted
      }
    }, []); // run once on mount

    useEffect(() => {
        if (!wsRef.current) {
          wsRef.current = new WebSocket(`wss://pixster-server-olsn46r5ua-uc.a.run.app/api/v1/websocket/pix/${txid}/ws`);
        }
        wsRef.current.onopen = () => {
          // Connection opened
          setConnectionWasOpened(true);
        };
        wsRef.current.onmessage = (e) => {
          // Receive a message from the server
          const messageObj = JSON.parse(e.data);
          if (messageObj[txid] === "paid") {
            setPixPaid(true);
            lottiePaidRef.current?.play();
            wsRef.current.close();
          } else if (messageObj[txid] === "expired") {
            setPixExpired(true);
            lottieExpiredRef.current?.play();
            wsRef.current.close();
          } else if (messageObj[txid] === "partial"){
            setPartialAmountPaid(parseFloat(messageObj["balance"]));
          }
        };
        wsRef.current.onerror = (e) => {
          // An error occurred
          if (wsRef.current){
            wsRef.current = new WebSocket(`wss://pixster-server-olsn46r5ua-uc.a.run.app/api/v1/websocket/pix/${txid}/ws`);
            //interApi.getCharge(txid)
            //.then((res) => {
            //  const creationDate = new Date(res.data.calendario.criacao);
            //  const expirationDate = new Date(creationDate.setSeconds(creationDate.getSeconds() + res.data.calendario.expiracao))
            //  if (res.data.status == "ATIVA" && expirationDate > new Date()) {
            //      interApi.expireCharge(txid);
            //      if (isMounted.current) {
            //        navigation.navigate("Canceled Charge");
            //      }
            //  }
            //})
            //.catch((error) => console.log(error));
          }
        };
        wsRef.current.onclose = (e) => {
          wsRef.current = new WebSocket(`wss://pixster-server-olsn46r5ua-uc.a.run.app/api/v1/websocket/pix/${txid}/ws`);
          //if (Platform.OS !== "web"){
            // Connection closed
          //  interApi.getCharge(txid)
          //  .then((res) => {
          //    const creationDate = new Date(res.data.calendario.criacao);
          //    const expirationDate = new Date(creationDate.setSeconds(creationDate.getSeconds() + res.data.calendario.expiracao))
          //    if (res.data.status == "ATIVA" && expirationDate > new Date()) {
          //        interApi.expireCharge(txid);
          //        if (isMounted.current) {
          //          navigation.navigate("Canceled Charge");
          //       }
          //    }
          //  })
          //  .catch((error) => console.log(error));
          //}
        };

        return () => {
          // Anything in here is fired on component unmount.
          wsRef.current.close()
        }

    }, []);

    return <Screen style={styles.container}>
      {!pixExpired && !pixPaid && 
      <>
      <View style={[styles.paymentSummary, Platform.OS === "web" && preferredQrCodeType == 'static' && {height: "70%"}]}>
        {preferredQrCodeType == 'dynamic' && <><AppText style={styles.title}>Escaneie o QR Code do Pix</AppText>
        <Image source={{uri: qrCode}} style={styles.qrCode(qrCodeSize)}/></>}
        {preferredQrCodeType == 'static' && <><AppText style={styles.title}>Monto a Pagar - QR Estático</AppText>
        <View style={{height: "50%", justifyContent: "center", alignItems: "center"}}><AppText style={[{fontSize: 40}, styles.amountDueBRL, (partialAmountPaid > 0) && {textDecorationLine: 'line-through', textDecorationStyle: 'solid', textDecorationColor: colors.danger}]}>{amountDueBRL} BRL</AppText>
        {(partialAmountPaid > 0) && <AppText style={{color: colors.danger, textAlign: "center", fontWeight: "bold", paddingHorizontal: 10}}>Cliente ha pagado menos que el importe total. Todavía faltan  {(amountDueBRL - partialAmountPaid).toFixed(2)} BRL.</AppText> }
        </View></>}
        <View style={{flexDirection:"row", paddingBottom: 20}}>
          <View style={{ flexDirection: 'column', justifyContent: 'space-between'}}>
            <AppText style={[{fontSize: 16}, styles.amountDueBRL]}><CountryFlag isoCode="br" size={20} /> {amountDueBRL} BRL</AppText>
            <AppText style={styles.amountDueARS}><CountryFlag isoCode="ar" size={20} /> {amountDueARS} ARS</AppText>
          </View>
          <View style={{flex:1, alignItems: 'flex-end', marginHorizontal: 30}}>
            {connectionWasOpened && <CountDown
              until={300}
              size={timerSize}
              digitStyle={{backgroundColor: colors.primary}}
              digitTxtStyle={{color: colors.white}}
              timeToShow={['M', 'S']}
              timeLabels={{m: 'min', s: 'seg'}}
            />}
          </View>
        </View>
        <AppText style={styles.exchangeRate}><MaterialCommunityIcons name={"swap-horizontal-bold"} color={colors.black} size={20} /> {exchangeRate}</AppText>
      </View>
      <View style={styles.divider}></View>
      {amountUSDC && 
        <>
            <View style={{height: 5}}></View>
            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <Icon name="hand-coin" size={22}/><AppText style={{fontSize: 14}}>  Recibes <AppText style={{fontSize: 14, fontWeight: "bold"}}>{amountUSDC} USDC</AppText></AppText>
            </View>
            <View style={styles.divider}></View>
        </>
      }
      </>
      }
      <DoneIndicator innerRef={lottiePaidRef} referenceId={referenceId}/>
      <ExpiredIndicator innerRef={lottieExpiredRef} preferredQrCodeType={preferredQrCodeType}/>
    </Screen>
}

export default QRCodeScreen;