import React, { useEffect, useState } from 'react';
import * as Yup from "yup";

import Screen from '../../components/Screen';
import styles from './styles';
import AppTextInput from '../../components/AppTextInput';
import {
    AppForm,
    AppFormField,
    SubmitButton,
  } from "../../components/forms";
import bankCodes from '../../config/constants';
import { validateAccount, validateAccountOriginal, validateBankBranch, validateBankBranchOriginal } from '../../utility/validators';
import useAuth from '../../auth/useAuth';
import usersApi from '../../api/users';
import { SafeAreaView, View } from 'react-native';
import OTPModal from '../../components/OTPModal';
import MessageModal from '../../components/MessageModal';
import { ScrollView } from 'react-native';
import AppText from '../../components/AppText';


const validationSchema = Yup.object().shape({
    accountants_email: Yup.string()
        .email("El email debe ser válido.")
        .label("Correo Electrónico del Contador"),
    bank_account: Yup.string()
        .required("CBU es un campo obligatorio.")
        .test("validate-length", "CBU inválido.", (value) => value.length === 26)
        .test("validate-bank-branch", "CBU inválido.", validateBankBranchOriginal)
        .test("validate-account", "CBU inválido.", validateAccountOriginal)
        .label("Bank Account"),
  });

const maskCBU = (cbu) => {
    return `${cbu.substring(0,3)} ${cbu.substring(3,7)} ${cbu.substring(7,8)} ${cbu.substring(8,21)} ${cbu.substring(21,22)}`
}

function BankingInformationEditScreen() {
    const { user } = useAuth();

    const [loading, setLoading] = useState(false);
    const [bankAccount, setBankAccount] = useState();
    const [initialBankAccount, setInitialBankAccount] = useState();
    const [withdrawalFrequency, setWithdrawalFrequency] = useState();
    const [preferredWithdrawalCurrency, setPreferredWithdrawalCurrency] = useState();
    const [vaspDid, setVaspDid] = useState();
    const [cryptoBeneficiaryName, setCryptoBeneficiaryName] = useState();
    const [cryptoBeneficiaryType, setCryptoBeneficiaryType] = useState();
    const [usdcAddress, setUsdcAddress] = useState();
    const [preferredQrType, setPreferredQrType] = useState(); 
    const [accountantsEmail, setAccountantsEmail] = useState();


    // code input
    const MAX_CODE_LENGTH = 4;
    const [code, setCode] = useState('');
    const [pinReady, setPinReady] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);

    // OTP modal
    const [otpModalVisible, setOtpModalVisible] = useState(false);

    // Message modal
    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [messageModalMessageType, setMessageModalMessageType] = useState('');
    const [messageModalHeaderText, setMessageModalHeaderText] = useState('');
    const [messageModalMessage, setMessageModalMessage] = useState('');
    const [messageModalButtonText, setMessageModalButtonText] = useState('');


    const messageModalButtonHandler = () => {
        setMessageModalVisible(false);
    }

    const showMessageModal = (type, headerText, message, buttonText) => {
        setMessageModalMessageType(type);
        setMessageModalHeaderText(headerText);
        setMessageModalMessage(message);
        setMessageModalButtonText(buttonText);
        setMessageModalVisible(true);
    };

    useEffect(() => {
        setLoading(true);
        usersApi.get(user.id)
            .then((res) => {
                setBankAccount(maskCBU(res.data.bank_account));
                setInitialBankAccount(maskCBU(res.data.bank_account));
                setWithdrawalFrequency(res.data.withdrawal_frequency);
                setPreferredWithdrawalCurrency(res.data.preferred_withdrawal_currency);
                setVaspDid(res.data.vasp_did);
                setCryptoBeneficiaryName(res.data.crypto_beneficiary_name);
                setCryptoBeneficiaryType(res.data.crypto_beneficiary_type);
                setUsdcAddress(res.data.usdc_address);
                setPreferredQrType(res.data.preferred_qr_code_type);
            })
            .catch((error) => console.log(error));
    }, [])

    useEffect(() => {
        setLoading(false);
    }, [bankAccount]);

    const handleOtpModalShow = async () => {
        setLoading(true);
        let sendUpdateConfirmOTPResult;
        // make request to backend
        sendUpdateConfirmOTPResult = await usersApi.sendUpdateConfirmOTPEmail(user.id);
        if (!sendUpdateConfirmOTPResult.ok){
            setTimeout(async () => {
                sendUpdateConfirmOTPResult = await usersApi.sendUpdateConfirmOTPEmail(user.id);
            }, 1500)
        }

        if (sendUpdateConfirmOTPResult.ok) {
            setOtpModalVisible(true);
            setLoading(false);
            return
        }
        alert("Error", ' Inténtelo de nuevo en breve.');
        setLoading(false);
    }

    const handleSubmit = async () => {
        setLoading(true);
        const result = await usersApi.update(user.id, {
            "vasp_did": vaspDid,
            "crypto_beneficiary_name": cryptoBeneficiaryName,
            "crypto_beneficiary_type": cryptoBeneficiaryType,
            "bank_account": bankAccount,
            "preferred_withdrawal_currency": preferredWithdrawalCurrency,
            "preferred_qr_code_type": preferredQrType,
            "withdrawal_frequency": withdrawalFrequency,
            "usdc_address": usdcAddress,
            "otp": code,
        });

        if (!result.ok) {
            setLoading(false);
            onCancelPress();
            setOtpModalVisible(false);
            return showMessageModal('failed', '¡Error!', result.data ? "PIN inválido." : "Ocurrió un error inesperado.", 'Cerrar');
        }
        setLoading(false);
        setInitialBankAccount(bankAccount);
        setOtpModalVisible(false);
        setCode('');
        return showMessageModal('success', '¡Todo está bien!', 'Su información ha sido actualizada.', 'Ok');
    };

    const onCancelPress = () => {
        setBankAccount(initialBankAccount);
        setOtpModalVisible(false);
        setCode('');
    }

    useEffect(() => {
        // toggle pinReady
        if (code.length < MAX_CODE_LENGTH) {
            setInvalidPin(false);
        }
        setPinReady(code.length === MAX_CODE_LENGTH);
        return () => setPinReady(false);
    }, [code]);

    return (
        <Screen style={styles.container}>
            <ScrollView>
                <AppForm
                    initialValues={{ accountants_email: "", bank_account: "" }}
                    onSubmit={handleOtpModalShow}
                    validationSchema={validationSchema}
                >
                    <AppText style={{fontWeight:"bold"}}>CBU:</AppText>
                    <AppFormField
                        masked={true}
                        mask="999 9999 9 9999999999999 9"
                        maxLength={26}
                        keyboardType="numeric"
                        autoCorrect={false}
                        icon="identifier"
                        name="bank_account"
                        placeholder="CBU"
                        setState={setBankAccount}
                        value={bankAccount}
                    />
                    <View style={styles.divider} />
                    <AppText style={{fontWeight:"bold"}}>Email de Contador (Opcional):</AppText>
                    <AppFormField
                        autoCapitalize="none"
                        autoCorrect={false}
                        icon="book-open-variant"
                        keyboardType="email-address"
                        name="accountants_email"
                        placeholder="Email del Contador (Opcional)"
                        textContentType="emailAddress"
                        setState={setAccountantsEmail}
                        value={accountantsEmail}
                    />
                    <SubmitButton title="Actualizá Información Bancária" loading={loading} disabled={loading} />
                </AppForm>
            </ScrollView>
            <OTPModal
                modalVisible={otpModalVisible}
                onCancelPress={onCancelPress}
                onConfirmPress={handleSubmit}
                color={"primary"}
                user={user}
                code={code}
                setCode={setCode}
                pinReady={pinReady}
                invalidPin={invalidPin}
                MAX_CODE_LENGTH={MAX_CODE_LENGTH} 
                loading={loading}
            />
            <MessageModal
                modalVisible={messageModalVisible}
                buttonHandler={messageModalButtonHandler}
                outsidePressHandler={messageModalButtonHandler}
                type={messageModalMessageType}
                headerText={messageModalHeaderText}
                message={messageModalMessage}
                buttonText={messageModalButtonText}
            />
        </Screen>
    );
}

export default BankingInformationEditScreen;