import client from './client';


const getVaspOptions = () => {
  return client.get('/bitso/vasps');
}


export default {
    getVaspOptions
};