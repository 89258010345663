import React, { useState } from 'react';
import { Modal, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

// styled components
import colors from '../../config/colors';
import styles from './styles';
import AppText from '../AppText';
import AppTextInput from '../AppTextInput';
import AppButton from '../AppButton';
import MessageModal from '../MessageModal';
import { useNavigation } from '@react-navigation/native';
import Screen from '../Screen';
import qrCodeApi from '../../api/qrCode';



const QRRegisterModal = ({qrRegisterModalVisible, outsidePressHandler, outsidePressHandlerQRFixed, companyId, operatorId, setNewQr}) => {

  const [loading, setLoading] = useState(false);
  const [QRKey, setQRKey] = useState('');

  const navigation = useNavigation();

  // Message modal
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [messageModalMessageType, setMessageModalMessageType] = useState('');
  const [messageModalHeaderText, setMessageModalHeaderText] = useState('');
  const [messageModalMessage, setMessageModalMessage] = useState('');
  const [messageModalButtonText, setMessageModalButtonText] = useState('');


  const showMessageModal = (type, headerText, message, buttonText) => {
    setMessageModalMessageType(type);
    setMessageModalHeaderText(headerText);
    setMessageModalMessage(message);
    setMessageModalButtonText(buttonText);
    setMessageModalVisible(true);
  };

  const messageModalButtonHandler = async () => {
    setMessageModalVisible(false);
  }


  const handleQRRegistration = async () => {
    setLoading(true);
    const linkQrCodeInput = {
      "company_id": companyId, 
      "operator_id": operatorId,
    }
    const result = await qrCodeApi.linkQrCode(linkQrCodeInput, QRKey.toLowerCase());

    if (!result.ok) {
        setLoading(false);
        outsidePressHandler();
        setQRKey('');
        if (result.status == 409){
          return showMessageModal('failed', '¡Error!', "Clave QR ya asociada a otro operador.", 'Cerrar');
        }
        return showMessageModal('failed', '¡Error!', result.data ? "Clave QR inválida." : "Ocurrió un error inesperado.", 'Cerrar');
    }
    setLoading(false);
    setNewQr(QRKey);
    outsidePressHandlerQRFixed();
    setQRKey('');
    //return showMessageModal('success', '¡Clave QR Registrada!', 'Ahora se puede utilizar el QR estático para recibir pagos.', 'Ok');
  }



  return (
    <>
    <Modal animationType="slide" visible={qrRegisterModalVisible} transparent={false}>
      <Screen style={styles.container}>
        <View style={styles.modal}>
          <MaterialCommunityIcons
            name={'qrcode-plus'}
            size={100}
            color={colors.white}
          />
          <AppText style={{ fontSize: 25, color: colors.white, marginVertical: 10, textAlign: 'center' }}>Registro de QR</AppText>
          <AppText style={{fontSize: 15, color: colors.white, textAlign: 'justify' }}>Por favor escriba la clave de 6 dígitos que se muestra en su código QR:</AppText>
          <AppTextInput
            maxLength={6}
            autoCorrect={false}
            icon="qrcode-plus"
            editable={true}
            name="qr_code"
            placeholder="Clave QR (6 dígitos)"
            placeholderTextColor={colors.darkGrey}
            onChangeText={(text) => {
                setQRKey(text);
            }}
            value={QRKey}
          />
          <AppText style={{fontSize: 10, color: colors.white, textAlign: 'center' }}>Solicitar QRs estáticos si los desea en hola@pixglobal.io</AppText>
          <View style={styles.buttonsContainer}>
            <View>
              <AppButton onPress={() => {
                outsidePressHandler();
                setQRKey('');
               }} title={"Cancelar"} color="secondary" />
            </View>
            <View>
              <AppButton title="Asociar QR" color="primary" disabled={false} onPress={handleQRRegistration}/>
            </View>
          </View>
        </View>
      </Screen>
    </Modal>
    <MessageModal
        modalVisible={messageModalVisible}
        buttonHandler={messageModalButtonHandler}
        outsidePressHandler={messageModalButtonHandler}
        type={messageModalMessageType}
        headerText={messageModalHeaderText}
        message={messageModalMessage}
        buttonText={messageModalButtonText}
    />
    </>
  );
};

export default QRRegisterModal;