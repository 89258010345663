import React, { useState, useEffect } from 'react';
import { Pressable, View } from 'react-native';
import AppText from '../AppText';

import styles from './styles';
import colors from '../../config/colors';

const ResendTimer = ({ activeResend, setActiveResend, targetTimeInSeconds, resendEmail, resendStatus, color = null, ...props }) => {
    const [timeLeft, setTimeLeft] = useState(null);
    const [targetTime, setTargetTime] = useState(null);

    let resendTimeInterval;

    const triggerTimer = (targetTimeInSeconds = 30) => {
        setTargetTime(targetTimeInSeconds);
        setActiveResend(false);
        const finalTime = +new Date() + targetTimeInSeconds * 1000;
        resendTimeInterval = setInterval(() => calculateTimeLeft(finalTime), 1000);
    }

    const calculateTimeLeft = (finalTime) => {
        const difference = finalTime - +new Date();
        if (difference >= 0){
            setTimeLeft(Math.round(difference / 1000));
        } else {
            clearInterval(resendTimeInterval);
            setActiveResend(true);
            setTimeLeft(null);
        }
    }

    useEffect(() => {
        triggerTimer(targetTimeInSeconds);

        return () => {
            clearInterval(resendTimeInterval);
        };
    }, []);

    return (
        <View style={styles.container}>
            <AppText style={[styles.text, {color: color}]}>No recibiste el correo?</AppText>
            <Pressable
                onPress={() => resendEmail(triggerTimer)}
                disabled={!activeResend}
                style={{ opacity: activeResend ? 1 : 0.65 }}
            >
                <AppText style={[styles.text, {color: resendStatus === "Reenviar" ? colors.secondary : (resendStatus === "Fracasado!" ? colors.danger : colors.primary)}]}>
                    {resendStatus}
                </AppText>
            </Pressable>
            {!activeResend && <AppText style={[styles.text, {color: color}]}>en <AppText style={[styles.timeLeftText, {color: color}]}>{timeLeft || targetTime}</AppText> segundo(s).</AppText>}
        </View>
    );
};

export default ResendTimer;