import React, { useEffect, useRef, useState } from 'react';
import { FlatList, Platform, View } from 'react-native';
import { ActivityIndicator as ReactNativeActivityIndicator } from 'react-native';
import { useIsFocused } from "@react-navigation/native";
import SelectDropdown from 'react-native-select-dropdown';
import FileSaver from 'file-saver';


import operatorsApi from '../../api/operators';
import usersApi from '../../api/users';
import Screen from '../../components/Screen';
import { ListItem, ListItemSeparator } from '../../components/lists';
import { ActivityIndicator, DownArrowIndicator } from '../../components/indicators';
import AppText from '../../components/AppText';
import AppButton from '../../components/AppButton';
import styles from './styles';
import useAuth from '../../auth/useAuth';
import CSVExportModal from '../../components/CSVExportModal';
import { TouchableOpacity } from 'react-native';
import colors from '../../config/colors';
import { prettifyToString } from '../../utility/utils';
import PaymentDetailModal from '../../components/PaymentDetailModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import MessageModal from '../../components/MessageModal';
import Icon from '../../components/Icon';
import { FontAwesome } from '@expo/vector-icons';


function PaymentsScreen({navigation, route}) { 
    const downArrowRef = useRef(null);
    const isFocused = useIsFocused();
    const { user } = useAuth();
    const [selectedCurrency, setSelectedCurrency] = useState("ARS");
    const [error, setError] = useState(false);
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [nextPage, setNextPage] = useState(null);
    const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = useState(true);
    const [endLoading, setEndLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [reloading, setReloading] = useState(false);

    const { isToggleInitiallyEnabled, refresh } = route.params;


    const pageSize = 15;

    // CSV Modal
    const [csvModalVisible, setCsvModalVisible] = useState(false);
    const [csvModalHeaderText, setCsvModalHeaderText] = useState('');
    const [csvModalButtonText, setCsvModalButtonText] = useState('');

    const showCsvModal = (csvModalHeaderText, csvModalButtonText) => {
        setCsvModalHeaderText(csvModalHeaderText);
        setCsvModalButtonText(csvModalButtonText);
        setCsvModalVisible(true);
    };

    // Message modal

    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [messageModalMessageType, setMessageModalMessageType] = useState('');
    const [messageModalHeaderText, setMessageModalHeaderText] = useState('');
    const [messageModalMessage, setMessageModalMessage] = useState('');
    const [messageModalButtonText, setMessageModalButtonText] = useState('');


    const showMessageModal = (type, headerText, message, buttonText) => {
        setMessageModalMessageType(type);
        setMessageModalHeaderText(headerText);
        setMessageModalMessage(message);
        setMessageModalButtonText(buttonText);
        setMessageModalVisible(true);
    };

    const messageModalButtonHandler = async () => {
        setMessageModalVisible(false);
    }

    // Confirmation Modal
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [confirmationModalMessageType, setConfirmationModalMessageType] = useState('');
    const [confirmationModalHeaderText, setConfirmationModalHeaderText] = useState('');
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [confirmationModalButtonText, setConfirmationModalButtonText] = useState('');
  
  
    const showConfirmationModal = (type, headerText, message, buttonText) => {
      setConfirmationModalMessageType(type);
      setConfirmationModalHeaderText(headerText);
      setConfirmationModalMessage(message);
      setConfirmationModalButtonText(buttonText);
      setConfirmationModalVisible(true);
    };

    const confirmationModalButtonHandler = async () => {
        // Call API
        setLoading(true);
        const companyId = user.type == 'master' ? user.id : user.company_id;
        const operatorId = user.type == 'secondary' ? user.id : null;
        const payoutMethod = selectedCurrency == "USDC" ? "crypto" : (selectedCurrency == "ARS" ? "fiat" : "brl");
        let closeLotInput;
        if (Platform.OS == "web"){
            closeLotInput = {
                "payout_method": payoutMethod,
                "export_method": "direct",
            }
        } else {
            closeLotInput = {
                "payout_method": payoutMethod,
            }
        }
        const closeLotResponse = await operatorsApi.closeLot(
            operatorId,
            companyId,
            closeLotInput,
        );
        if (!closeLotResponse.ok) {
            setLoading(false);
            setConfirmationModalVisible(false);
            if (closeLotResponse.status === 400) {
                return showMessageModal('failed', '¡Error!', "No pudimos cerrar el lote. No hay nuevos pagos desde el último cierre.", 'Ok');
            } else {
                return showMessageModal('failed', '¡Error!', "No pudimos cerrar el lote. Inténtalo de nuevo más tarde.", 'Ok');
            }
        }
        setLoading(false);
        setConfirmationModalVisible(false);
        if (Platform.OS == "web"){
            var totalUsdc = closeLotResponse.headers["content-disposition"].split('total_usdc=')[1].split(';')[0];
            var totalFiat = closeLotResponse.headers["content-disposition"].split('total_fiat=')[1].split(';')[0];
            var fileName = closeLotResponse.headers["content-disposition"].split('filename=')[1].split(';')[0];
            var fileData = new Blob([closeLotResponse.data], {type: "application/zip"});
            FileSaver.saveAs(fileData, fileName);
            return showMessageModal('success', '¡Todo está bien!', `Todos los pagos desde el último cierre han sido descargados exitosamente. \n\n Pagos en ARS: ${totalFiat} ARS \n Pagos en USDC: ${totalUsdc} USDC`, 'Ok');
        } else {
            var totalUsdc = closeLotResponse.data.total_usdc;
            var totalFiat = closeLotResponse.data.total_fiat;
            return showMessageModal('success', '¡Todo está bien!', `Todos los pagos desde el último cierre han sido enviados a la cuenta maestra. \n\n Pagos en ARS: ${totalFiat} ARS \n Pagos en USDC: ${totalUsdc} USDC`, 'Ok');
        }
    }
    
    
    // Payment Detail Modal
    const [paymentDetailModalVisible, setPaymentDetailModalVisible] = useState(false);
    const [paymentDetailModalHeaderText, setPaymentDetailModalHeaderText] = useState('');
    const [paymentDetailModalSubheaderText, setPaymentDetailModalSubheaderText] = useState('');
    const [paymentDetailModalTxid, setPaymentDetailModalTxid] = useState('');
    const [paymentDetailModalReferenceId, setPaymentDetailModalReferenceId] = useState('');
    const [paymentDetailModalPayer, setPaymentDetailModalPayer] = useState('');
    const [paymentDetailModalOperator, setPaymentDetailModalOperator] = useState('');
    const [paymentDetailModalStatus, setPaymentDetailModalStatus] = useState('');
    const [paymentDetailModalPayoutMethod, setPaymentDetailModalPayoutMethod] = useState('');
    const [paymentDetailModalValueDestination, setPaymentDetailModalValueDestination] = useState(null);
    const [paymentDetailModalValueDestinationUsdc, setPaymentDetailModalValueDestinationUsdc] = useState(null);
    const [paymentDetailModalPaidAt, setPaymentDetailModalPaidAt] = useState(null);

    const showPaymentDetailModal = (
        paymentDetailModalHeaderText,
        paymentDetailModalSubheaderText,
        paymentDetailModalTxid,
        paymentDetailModalReferenceId,
        paymentDetailModalPayer,
        paymentDetailModalStatus,
        paymentDetailModalOperator,
        paymentDetailModalPayoutMethod,
        paymentDetailModalValueDestination,
        paymentDetailModalValueDestinationUsdc,
        paymentDetailModalPaidAt,
    ) => {
        setPaymentDetailModalVisible(true);
        setPaymentDetailModalHeaderText(paymentDetailModalHeaderText);
        setPaymentDetailModalSubheaderText(paymentDetailModalSubheaderText);
        setPaymentDetailModalTxid(paymentDetailModalTxid);
        setPaymentDetailModalReferenceId(paymentDetailModalReferenceId);
        setPaymentDetailModalPayer(paymentDetailModalPayer);
        setPaymentDetailModalStatus(paymentDetailModalStatus);
        setPaymentDetailModalOperator(paymentDetailModalOperator);
        setPaymentDetailModalPayoutMethod(paymentDetailModalPayoutMethod);
        setPaymentDetailModalValueDestination(paymentDetailModalValueDestination);
        setPaymentDetailModalValueDestinationUsdc(paymentDetailModalValueDestinationUsdc);
        setPaymentDetailModalPaidAt(paymentDetailModalPaidAt);
    };

    const outsidePressHandler = () => {
        setCsvModalVisible(false);
        setPaymentDetailModalVisible(false);
    }

    const loadPayments = async () => {
        const payoutMethod = selectedCurrency == "USDC" ? "crypto" : (selectedCurrency == "ARS" ? "fiat" : "brl");
        const companyId = user.type == 'master' ? user.id : user.company_id;
        const filterByOperatorId = user.type == 'master' ? null : user.id;
        setLoading(true);
        const response = await usersApi.getPayments(companyId, filterByOperatorId, payoutMethod, pageSize, nextPage);
        if (!response.ok) {
            setLoading(false);
            return setError(true);
        }

        setError(false);

        if (response.data.payments.length === 0){ // No more payments...
            setLoading(false);
            setEndLoading(false);
            Platform.OS == "web" && setOnEndReachedCalledDuringMomentum(false);
            return
        }
        setPayments(payments.concat(response.data.payments));
        setNextPage(response.data.next_page[0][0]);
        setLoading(false);
        setEndLoading(false);
    };

    const refreshPayments = async () => {
        const companyId = user.type == 'master' ? user.id : user.company_id;
        const filterByOperatorId = user.type == 'master' ? null : user.id;
        setRefreshing(true);
        const payoutMethod = selectedCurrency == "USDC" ? "crypto" : (selectedCurrency == "ARS" ? "fiat" : "brl");
        const response = await usersApi.getPayments(companyId, filterByOperatorId, payoutMethod, pageSize, null);

        if (!response.ok) {
            setRefreshing(false);
            return setError(true);
        }

        if (response.data.payments.length === 0 && Platform.OS == "web"){ // No more payments...
            setOnEndReachedCalledDuringMomentum(false);
        }

        setError(false);
        setPayments(response.data.payments);
        setRefreshing(false);
        if (response.data.next_page[0]){
            setNextPage(response.data.next_page[0][0]);
        }
    }


    const reloadPayments = async () => {
        const companyId = user.type == 'master' ? user.id : user.company_id;
        const filterByOperatorId = user.type == 'master' ? null : user.id;
        setReloading(true);
        const payoutMethod = selectedCurrency == "USDC" ? "crypto" : (selectedCurrency == "ARS" ? "fiat" : "brl");
        const response = await usersApi.getPayments(companyId, filterByOperatorId, payoutMethod, pageSize, null);

        if (!response.ok) {
            setReloading(false);
            return setError(true);
        }
        setError(false);
        setPayments(response.data.payments);
        setReloading(false);
        if (response.data.next_page[0]){
            setNextPage(response.data.next_page[0][0]);
        }
    }

    const renderFooter = () => {
        if (!endLoading) return null;
        return <ReactNativeActivityIndicator />
    }

    useEffect(() => {
        if(isFocused){ 
            loadPayments();
        }
        if (!isFocused){
            setNextPage(null);
            setPayments([]);
            setOnEndReachedCalledDuringMomentum(true);
        }
    }, [isFocused]);

    useEffect(() => {
        setPaymentDetailModalVisible(false);
        refreshPayments();
    }, [refresh])

    useEffect(() => {
        if (user.type == 'master'){
            navigation.setOptions({
                headerRight: () => (
                  <TouchableOpacity onPress={() => showCsvModal('Seleccione el mes que desea exportar', 'Exportar')}>
                    <AppText style={{fontSize: 14, paddingRight: "8%"}}>Exportar CSV</AppText>
                  </TouchableOpacity>
                ),
            });
        };
        if (user.type == 'secondary'){
            navigation.setOptions({
                headerRight: () => (
                  <TouchableOpacity onPress={() => showConfirmationModal('updateConfirmation', '¿Estás seguro que quieres cerrar el lote?', 'Todos los pagos desde el último cierre se enviarán a la cuenta maestra.', 'Cerrar Lote')}>
                    <AppText style={{fontSize: 14, paddingRight: "8%"}}>Cerrar Lote</AppText>
                  </TouchableOpacity>
                ),
            });
        }
    }, [navigation]);

    const currencyOptions = ["ARS", "USDC"] // "BRL"

    useEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <View style={{flexDirection: "row", alignItems: "center", marginLeft: 25}}>
                <SelectDropdown
                    data={currencyOptions}
                    defaultValue={"ARS"}
                    onSelect={(selectedItem) => {setSelectedCurrency(selectedItem); setOnEndReachedCalledDuringMomentum(true);}}
                    defaultButtonText={<AppText style={{'color': colors.medium}}>ARS</AppText>}
                    buttonTextAfterSelection={(selectedItem) => {
                        return <AppText style={{fontSize: 16}}>{selectedItem}</AppText>
                    }}
                    rowTextForSelection={(item) => {
                        return <AppText style={{fontSize: 16}}>{item}</AppText>
                    }}
                    buttonStyle={styles.dropdown2BtnStyle}
                    buttonTextStyle={styles.dropdown2BtnTxtStyle}
                    renderDropdownIcon={isOpened => {
                    if (Platform.OS == "web"){
                        return <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} iconColor={colors.dark} backgroundColor='transparent' size={46} />;
                    } else {
                        return <FontAwesome name={isOpened ? 'chevron-up' : 'chevron-down'} color={colors.dark} size={14} />
                    }
                    }}
                    dropdownIconPosition={'left'}
                    dropdownStyle={styles.dropdown1DropdownStyle}
                    rowStyle={styles.dropdown1RowStyle}
                    rowTextStyle={styles.dropdown2RowTxtStyle}
                />
                </View>
            ),
        });
        if (!loading){
            reloadPayments();
        }
    }, [selectedCurrency]);


    return (
        <Screen>
            {error && <>
                <AppText>No pudimos recuperar los pagos.</AppText>
                <AppButton title="Volver a procesar" onPress={refreshPayments}/>
            </>}
            {((endLoading || (!loading && !reloading)) && payments && payments.length > 0) && 
            <FlatList
                data={payments}
                keyExtractor={message => message.Payment.txid}
                renderItem={({ item }) =>
                    <ListItem
                        title={(item.Refund ? "" : item.Charge.charge_type == 'cash-out' ? "- " : "+ ") + selectedCurrency + " " + prettifyToString((item.Charge.payout_method == "fiat" ? item.Charge.value_destination : (item.Charge.payout_method == "fiat" ? item.Charge.value_source : (item.Charge.charge_type == 'cash-out' ? item.Charge.value_destination : item.Charge.value_destination_usdc))).toFixed(2))}
                        description={`(ID: ${item.Charge.reference_id}) `.concat((new Date(item.Payment.paid_at)).toLocaleString("es-AR", {timeZone: "America/Argentina/Buenos_Aires"}))}
                        status={item.Charge.charge_type == 'cash-out' ? "SENT_OUT" : (item.Refund ? "REFUNDED" : (item.WithdrawalReceipt && item.WithdrawalReceipt.paid_at ? "PAID_TO_CUSTOMER" : "FIAT_SOURCE"))}
                        onPress={() => showPaymentDetailModal(
                            (item.Refund ? "" : item.Charge.charge_type == 'cash-out' ? "- " : "+ ") + selectedCurrency + " " + prettifyToString((item.Charge.payout_method == "fiat" ? item.Charge.value_destination : (item.Charge.payout_method == "fiat" ? item.Charge.value_source : (item.Charge.charge_type == 'cash-out' ? item.Charge.value_destination : item.Charge.value_destination_usdc))).toFixed(2)),
                            "Monto negociado: BRL " + prettifyToString(((item.Charge.charge_type == 'cash-out' &&  item.Charge.payout_method == "brl") ? item.Charge.value_destination : item.Charge.value_source).toFixed(2)) + (item.Charge.payout_method == 'crypto' ? ("\n Equivalente en ARS: " + prettifyToString(item.Charge.value_destination.toFixed(2))): ""),
                            item.Charge.txid,
                            item.Charge.reference_id,
                            item.Payment.payer_name,
                            item.Charge.charge_type == 'cash-out' ? "SENT_OUT" : (item.Refund ? "REFUNDED" : (item.WithdrawalReceipt && item.WithdrawalReceipt.paid_at ? "PAID_TO_CUSTOMER" : "FIAT_SOURCE")),
                            user.type == 'master' ? item.Operator.name : null,
                            item.Charge.payout_method,
                            item.Charge.value_destination,
                            item.Charge.value_destination_usdc,
                            item.Payment.paid_at,
                        )}
                    />
                }
                ItemSeparatorComponent={ListItemSeparator}
                refreshing={refreshing}
                onRefresh={refreshPayments}
                onEndReached={() =>  {
                        if (Platform.OS == "web"){
                            if(onEndReachedCalledDuringMomentum){
                                setEndLoading(true);
                                loadPayments();
                                setOnEndReachedCalledDuringMomentum(true); //false
                            }
                        } else {
                            if(!onEndReachedCalledDuringMomentum){
                                setEndLoading(true);
                                loadPayments();
                                setOnEndReachedCalledDuringMomentum(true);
                            }
                        }
                    }
                }
                onEndReachedThreshold={0.01}
                ListFooterComponent={renderFooter}
                onMomentumScrollBegin={() => setOnEndReachedCalledDuringMomentum(false)}
            />}
            {(!loading && ! refreshing && !reloading && payments && payments.length === 0) &&
                <View style={styles.noPaymentsContainer}>
                    <AppText style={styles.text}>Todavía no has operado ningún pago en {selectedCurrency}.</AppText>
                    <AppText style={styles.text}>Comienza a operarlos dentro de segundos.</AppText>
                    <DownArrowIndicator innerRef={downArrowRef}/>
                </View>
            }
            <ActivityIndicator visible={(loading && !endLoading) || reloading} source={require('../../assets/animations/circle-loader.json')}/>
            <CSVExportModal
                modalVisible={csvModalVisible}
                outsidePressHandler={outsidePressHandler}
                headerText={csvModalHeaderText}
                buttonText={csvModalButtonText}
                payoutMethod={selectedCurrency == "USDC" ? "crypto" : (selectedCurrency == "ARS" ? "fiat" : "brl")}
            />
            <ConfirmationModal
                modalVisible={confirmationModalVisible}
                buttonHandler={confirmationModalButtonHandler}
                outsidePressHandler={() => setConfirmationModalVisible(false)}
                type={confirmationModalMessageType}
                headerText={confirmationModalHeaderText}
                message={confirmationModalMessage}
                buttonText={confirmationModalButtonText}
                loading={loading}
            />
            <MessageModal
                modalVisible={messageModalVisible}
                buttonHandler={messageModalButtonHandler}
                outsidePressHandler={messageModalButtonHandler}
                type={messageModalMessageType}
                headerText={messageModalHeaderText}
                message={messageModalMessage}
                buttonText={messageModalButtonText}
            />
            <PaymentDetailModal
                modalVisible={paymentDetailModalVisible}
                outsidePressHandler={outsidePressHandler}
                headerText={paymentDetailModalHeaderText}
                subheaderText={paymentDetailModalSubheaderText}
                txid={paymentDetailModalTxid}
                valueDestination={paymentDetailModalValueDestination}
                valueDestinationUsdc={paymentDetailModalValueDestinationUsdc}
                referenceId={paymentDetailModalReferenceId}
                payer={paymentDetailModalPayer}
                status={paymentDetailModalStatus}
                operator={paymentDetailModalOperator}
                payoutMethod={paymentDetailModalPayoutMethod}
                companyId={user.type == 'master' ? user.id : user.company_id}
                loggedOperatorId={user.id}
                paidAt={paymentDetailModalPaidAt}
            />
        </Screen>
    );
}

export default PaymentsScreen;